import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import DashboardHome from "./DashboardHome";
import ManageUser from "./ManageUsers.jsx/ManageUser";
import ManageProjects from "./ManageProjects/ManageProjects";
import { useDispatch } from "react-redux";
import { toggleModal } from "./DashboardSlice";
import TermsAndCondition from "../../components/SimulationWorkSpaceComponents/Extras/TermAndCondition";
import CustomerSupport from "./CustomerSupport";
import axios from "axios";
import { baseUrl } from "../BaseUrl";
import toast from "react-hot-toast";
import { DateTime } from "luxon";
import {
    updateSelfData,
    updateProjects,
    updateUsers,
    reset,
} from "./Global/DashboardSlice";
import CreateProject from "./Newproject/CreateProject";
import AccountDetails from "./AccountDetails/AccountDetails";
import { setCredentials } from "../../services/auth/authSlice";
import ExpiredPage from "./components/ExpiredPage";
import { LoadingSpin } from "./components/Loading";
let timerAccess = null;

const DashboardWidget = () => {
    const [active, setActive] = useState("dashboard");
    const [haveAccess, setHaveAccess] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleModalClose = () => {
        dispatch(toggleModal());
        setActive("dashboard");
    };

    const handleAccountDetails = () => {
        setActive("accountDetails");
    };

    let user = localStorage.getItem("login-type");
    user = user === "admin" ? "tenant" : "user";

    const logout = async () => {
        const loginType = localStorage.getItem("login-type");
        const logoutEndpoint =
            loginType === "admin" ? "tenant/logout" : "user/logout";
        const logoutUrl = `${baseUrl}${logoutEndpoint}`;
        dispatch(setCredentials({ jwt: null, agreement: false }));
        localStorage.removeItem("tokenBQP");
        navigate("/login");
        try {
            const response = await fetch(logoutUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            toast.success("You've successfully logged out");
            if (!response.ok) {
                throw new Error(`Logout failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    useEffect(() => {
        dispatch(reset());

        async function fetchSelf() {
            try {
                const response = await axios.get(`${baseUrl}${user}/login`, {
                    withCredentials: true,
                });
                let data = response.data.length
                    ? response.data[0]
                    : response.data;
                const givenDateTime = DateTime.fromISO(
                    data.expiry_time,
                ).toLocal();
                const currentDateTime = DateTime.utc().toLocal();

                const isExpired = givenDateTime < currentDateTime;
                if (isExpired) {
                    setHaveAccess(false);
                    data = { ...data, haveAccess: false };
                } else {
                    data.haveAccess = formatAccessTime(
                        givenDateTime.diff(currentDateTime),
                    );
                    setHaveAccess(true);
                }

                dispatch(updateSelfData(data));

                if (data.haveAccess) {
                    timerAccess = setInterval(() => {
                        const now = DateTime.utc().toLocal();
                        if (givenDateTime < now && data.haveAccess) {
                            setHaveAccess(false);
                            data = { ...data, haveAccess: false };
                            dispatch(updateSelfData(data));
                            toast.error("Your plan is expired!");
                            clearInterval(timerAccess);
                        }
                    }, 1000 * 60);
                }
            } catch (error) {
                handleFetchError(error);
            }
        }

        fetchSelf();

        return () => clearInterval(timerAccess);
        // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchProjects() {
            try {
                const response = await axios.get(
                    `${baseUrl}${user === "tenant" ? "adminjobid" : "jobid"}`,
                    { withCredentials: true },
                );
                if (response.status === 200) {
                    dispatch(
                        updateProjects(
                            response.data.filter(
                                (obj) => Object.keys(obj).length !== 0,
                            ),
                        ),
                    );
                } else {
                    toast.error("Unexpected response from the server.");
                }
            } catch (error) {
                handleFetchError(error);
            }
        }

        fetchProjects();
        // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    }, []);

    const formatAccessTime = (diff) => {
        const { days, hours, minutes } = diff
            .shiftTo("days", "hours", "minutes")
            .toObject();
        if (days > 0) return `${days} day(s) & ${hours} hour(s)`;
        if (hours > 0) return `${hours} hour(s) & ${minutes} minute(s)`;
        return `${minutes > 0 ? minutes : "a"} minute(s)`;
    };

    const handleFetchError = (error) => {
        if (error.response) {
            const { status, data } = error.response;
            const errorMessage = data.detail || "An unexpected error occurred.";
            switch (status) {
                case 401:
                    toast.error("Unauthenticated. Logging out...");
                    logout();
                    break;
                case 403:
                    toast.error("Permission error. You might not have access.");
                    break;
                case 500:
                    toast.error("Server error. Please try again later.");
                    break;
                default:
                    toast.error(errorMessage);
            }
        } else {
            toast.error("Network error. Please check your connection.");
        }
        console.error("Error:", error);
    };

    useEffect(() => {
        async function fetchUser() {
            try {
                const response = await axios.get(baseUrl + "listuser", {
                    withCredentials: true,
                });
                dispatch(updateUsers(response.data));
            } catch (error) {
                dispatch(updateUsers([])); // Clear users on error
                if (error.response && error.response.status === 400) {
                    const errorMessage = Object.values(
                        error.response.data,
                    ).join(", ");
                    toast.error(errorMessage || "Credential Error");
                } else {
                    toast.error("Connection Error!");
                }
            }
        }

        if (user === "tenant") {
            fetchUser();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Add `user` to dependency array to handle changes

    return (
        <div className='flex'>
            <Sidebar active={(prev) => setActive(prev)} user={user} />
            {haveAccess !== null ? (
                <div className='relative flex flex-col w-full max-h-screen'>
                    <Navbar onAccountDetails={handleAccountDetails} />
                    {
                        {
                            dashboard: <DashboardHome />,
                            manageUser: user === "tenant" && <ManageUser />,
                            manageProjects: <ManageProjects />,
                            newProject: haveAccess ? (
                                <CreateProject />
                            ) : (
                                <ExpiredPage />
                            ),
                            termsAndConditions: (
                                <TermsAndCondition light={false} />
                            ),
                            customerSupport: (
                                <CustomerSupport onClose={handleModalClose} />
                            ),
                            accountDetails: <AccountDetails />,
                        }[active]
                    }
                </div>
            ) : (
                <div className=' flex items-center justify-center w-full'>
                    <LoadingSpin text={<p>Verifying Account</p>} />
                </div>
            )}
        </div>
    );
};

export default DashboardWidget;
