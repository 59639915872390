import PropTypes from "prop-types";

const BackgroundPage = ({ children, imgStyle, logo, backgroundImage }) => {
    const backgroundStyle = {
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
        backgroundColor: backgroundImage ? "transparent" : "#262626ff",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
    };

    return (
        <div className={`h-screen ${imgStyle}`} style={backgroundStyle}>
            {logo && (
                <div className='absolute top-8 left-8' style={{ zIndex: 1 }}>
                    <img src={logo} alt='Logo' className='h-20 w-auto' />
                </div>
            )}
            <div className='flex justify-center items-center h-full w-full'>
                {children}
            </div>
        </div>
    );
};

BackgroundPage.propTypes = {
    children: PropTypes.node.isRequired,
    imgStyle: PropTypes.string,
    logo: PropTypes.string,
    backgroundImage: PropTypes.string,
};

export { BackgroundPage };
