import { useState } from "react";
import MasterSidebar from "./MasterSidebar";
import MasterAdmin from "./MasterAdmin";
import Masterplan from "./MasterPlan";
import MasterNavbar from "./MasterNavbar";
import AccountDetailsMaster from "../../../routes/Dashboard/AccountDetails/AccountDetailsMaster";

const MasterdashBoard = () => {
    const [activeTab, setActiveTab] = useState("createAdmin");

    const handleAccountDetails = () => {
        setActiveTab("accountDetailsMaster");
    };

    return (
        <div className='flex h-full'>
            <div className='flex flex-col h-full border-r border-gray-300'>
                <MasterSidebar
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            <div className='relative flex flex-col w-full max-h-screen'>
                <MasterNavbar onAccountDetails={handleAccountDetails} />
                <div className='flex-grow p-4 h-full overflow-scroll scrollbar-none'>
                    {activeTab === "createAdmin" && <MasterAdmin />}
                    {activeTab === "createPlan" && <Masterplan />}
                    {activeTab === "accountDetailsMaster" && (
                        <AccountDetailsMaster />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MasterdashBoard;
