import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../routes/BaseUrl";

export interface User {
    id: number;
    name: string;
    email: string;
}

export interface UserResponse {
    jwt: string | null;
}

export interface SignUpResponseClient {}
export interface SignUpResponseAdmin {}
export interface SignUpResponseUser {}

export interface LoginRequestClient {
    company_emailid: string;
    password: string;
    otp: number;
}
export interface LoginRequestAdmin {
    tenant_id: number;
    email: string;
    password: string;
    otp: number;
}
export interface LoginRequestUser {
    user_id: number;
    email: string;
    password: string;
    otp: number;
}
export interface SignUpRequestClient {
    company_name: string;
    company_emailid: string;
    password: string;
}

export interface SignUpRequestAdmin {
    team_name: string;
    first_name: string;
    email: string;
    password: string;
}

export interface SignUpRequestUser {
    name: string;
    email: string;
    password: string;
}
export interface ClientRecoveryRequest {
    email: string;
}
export interface AdminRecoveryRequest {
    id: string;
}
export interface RecoveryRequest {
    id: string;
}
export interface ClientConfirmRequest {
    password: string;
    token: string;
}
export interface AdminConfirmRequest {
    password: string;
    token: string;
}
export interface ConfirmRequest {
    password: string;
    token: string;
}
export interface AuthUserResponse {
    email: string;
    mfa_hash: string;
    name: string;
}

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        headers: { "Content-Type": "application/json" },
        credentials: "include",
    }),
    endpoints: (build) => ({
        clientlogin: build.mutation<UserResponse, LoginRequestClient>({
            query: (credentials) => ({
                url: "client/login",
                method: "POST",
                body: credentials,
            }),
        }),
        adminlogin: build.mutation<UserResponse, LoginRequestAdmin>({
            query: (credentials) => ({
                url: "tenant/login",
                method: "POST",
                body: credentials,
            }),
        }),
        login: build.mutation<UserResponse, LoginRequestUser>({
            query: (credentials) => ({
                url: "user/login",
                method: "POST",
                body: credentials,
            }),
        }),
        clientsignup: build.mutation<SignUpResponseUser, SignUpRequestClient>({
            query: (credentials) => ({
                url: "client/signup",
                method: "POST",
                body: credentials,
            }),
        }),
        adminsignup: build.mutation<SignUpResponseUser, SignUpRequestAdmin>({
            query: (credentials) => ({
                url: "tenant/login",
                method: "PUT",
                body: credentials,
            }),
        }),
        usersignup: build.mutation<SignUpResponseUser, SignUpRequestUser>({
            query: (credentials) => ({
                url: "user/login",
                method: "PUT",
                body: credentials,
            }),
        }),
        signup: build.mutation<SignUpResponseUser, SignUpRequestUser>({
            query: (credentials) => ({
                url: "user/signup",
                method: "POST",
                body: credentials,
            }),
        }),
        user: build.query<AuthUserResponse, void>({
            query: () => ({
                url: "user",
                method: "GET",
            }),
        }),

        authenticate: build.query<UserResponse, void>({
            query: () => ({
                url: "authenticate",
                method: "GET",
            }),
        }),
        ClientRecoveryPassword: build.mutation<ClientRecoveryRequest, void>({
            query: (credentials) => ({
                url: "client-reset-password",
                method: "POST",
                body: credentials,
            }),
        }),
        AdminRecoveryPassword: build.mutation<AdminRecoveryRequest, void>({
            query: (credentials) => ({
                url: "tenant-reset-password",
                method: "POST",
                body: credentials,
            }),
        }),
        RecoveryPassword: build.mutation<RecoveryRequest, void>({
            query: (credentials) => ({
                url: "reset-password",
                method: "POST",
                body: credentials,
            }),
        }),
        ClientConfirmPassword: build.mutation<ClientConfirmRequest, void>({
            query: (credentials) => ({
                url: "client-confirm",
                method: "POST",
                body: credentials,
            }),
        }),
        AdminConfirmPassword: build.mutation<AdminConfirmRequest, void>({
            query: (credentials) => ({
                url: "tenant-confirm",
                method: "POST",
                body: credentials,
            }),
        }),
        ConfirmPassword: build.mutation<ConfirmRequest, void>({
            query: (credentials) => ({
                url: "confirm",
                method: "POST",
                body: credentials,
            }),
        }),
        RecoveryMfa: build.mutation<RecoveryRequest, void>({
            query: (credentials) => ({
                url: "mfa-recovery",
                method: "POST",
                body: credentials,
            }),
        }),
        logout: build.mutation<void, void>({
            query: () => ({
                url: "logout",
                method: "POST",
            }),
        }),
    }),
});

export const {
    useClientloginMutation,
    useAdminloginMutation,
    useLoginMutation,
    useClientsignupMutation,
    useAdminsignupMutation,
    useUsersignupMutation,
    useSignupMutation,
    useUserQuery,
    useAuthenticateQuery,
    useLogoutMutation,
    useClientRecoveryPasswordMutation,
    useAdminRecoveryPasswordMutation,
    useRecoveryPasswordMutation,
    useClientConfirmPasswordMutation,
    useAdminConfirmPasswordMutation,
    useConfirmPasswordMutation,
    useRecoveryMfaMutation,
} = authApi;
