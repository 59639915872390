import { useState } from "react";
import { FormikTextField } from "../../FormComponents/FormikTextField";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { HiOutlineMail } from "react-icons/hi";
import { TbIdBadge2 } from "react-icons/tb";
import bqp_logo from "../../../assets/images/bqp_logo.webp";
import { baseUrl } from "../../../routes/BaseUrl";
import axios from "axios";
import { LoadingSpin } from "../../../routes/Dashboard/components/Loading";
import { MdClose, MdPassword } from "react-icons/md";

const MasterSignup = () => {
    const [userPasscode, setUserPasscode] = useState("");
    const [verified, setVerified] = useState(false);
    const [creating, setCreating] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const checkPasscode = (e) => {
        e.preventDefault();
        setIsChecking(true);
        axios
            .post(baseUrl + "master/validate-passcode", {
                passcode: userPasscode,
            })
            .then((response) => {
                toast.success(response.data.detail);
                setVerified(true);
            })
            .catch((error) => {
                toast.error(error.response.data.detail);
            })
            .finally(() => {
                setIsChecking(false);
            });
    };

    return (
        <div className='h-screen w-screen bg-gray-500 flex items-center justify-center'>
            <nav className=' absolute top-2 left-2 opacity-60'>
                <img src={bqp_logo} className=' h-10' alt='bqp_logo' />
            </nav>
            {!verified && (
                <form
                    onSubmit={checkPasscode}
                    className=' relative z-50 flex flex-col gap-4 px-8 pt-2 pb-8 bg-gray-100 rounded-tl-full shadow-md'
                >
                    <div
                        className=' absolute h-full w-full bg-gray-300 bg-opacity-90 left-0 top-0'
                        style={{ zIndex: -1 }}
                    ></div>
                    <div className=' text-center text-lg '>
                        <h3 className=' opacity-80'>
                            <span className=' text-green-800'>Master</span>{" "}
                            Signup
                        </h3>
                        <hr />
                    </div>
                    <div>
                        <p className=' text-sm opacity-80 pb-1'>Passcode</p>
                        <input
                            onChange={(e) => setUserPasscode(e.target.value)}
                            required
                            autoFocus
                            value={userPasscode}
                            className=' w-full p-2 rounded-md text-sm outline-none border-2 border-white focus:border-gray-400'
                            type='text'
                            placeholder='enter passcode to proceed'
                        />
                    </div>
                    <div className=' flex justify-center mt-4'>
                        <input
                            className={`${
                                isChecking
                                    ? " opacity-40 pointer-events-none"
                                    : " opacity-100"
                            } px-4 py-1 rounded-md text-white bg-green-400 cursor-pointer shadow-md`}
                            type='submit'
                        />
                    </div>
                    <div>
                        <p className=' text-xs opacity-90'>
                            have account already?{" "}
                            <Link
                                className=' text-blue-800 '
                                to='/master/login'
                            >
                                Master Login
                            </Link>
                        </p>
                    </div>
                </form>
            )}
            {verified === true && (
                <Formik
                    initialValues={{
                        company_name: "",
                        company_emailid: "",
                        // password: "",
                    }}
                    validationSchema={Yup.object({
                        company_name: Yup.string().required(
                            "Almost there! Your company name is missing.",
                        ),
                        company_emailid: Yup.string()
                            .email("Oops! That email doesn't look right.")
                            .required("Hold on! We need the email address."),
                        password: Yup.string().required(
                            "Whoops! The password field can't be empty.",
                        ),
                    })}
                    onSubmit={async (values) => {
                        // values={...values, password:"willautogenerate"}
                        setCreating(true);
                        try {
                            await axios.post(
                                baseUrl + "client/signup",
                                values,
                                {
                                    withCredentials: true,
                                },
                            );
                            toast.success(
                                `Great news! ${values.company_name}'s account is now active.`,
                            );
                            setVerified(false);
                        } catch (error) {
                            if (error.response.data.company_emailid[0]) {
                                toast.error(
                                    error.response.data.company_emailid[0],
                                );
                            } else {
                                toast.error(
                                    "Apologies! A server error occurred. Please retry after some time.",
                                );
                            }
                        } finally {
                            setCreating(false);
                        }
                    }}
                >
                    <Form
                        autoComplete='off'
                        className=' relative z-50 flex flex-col gap-4 px-8 pt-2 pb-8 bg-gray-100 rounded-tl-full shadow-md'
                    >
                        <div
                            className=' absolute h-full w-full bg-gray-300 bg-opacity-90 left-0 top-0'
                            style={{ zIndex: -1 }}
                        ></div>
                        <div className=' text-center text-lg '>
                            <h3 className=' opacity-80'>
                                <span className=' text-green-800'>Master</span>{" "}
                                Signup
                            </h3>
                            <hr />
                        </div>
                        <div className=' m-0 opacity-90'>
                            <FormikTextField
                                label='Company Name *'
                                name='company_name'
                                type='text'
                                light={true}
                                placeholder='*** PVT. LTD.'
                                icon={TbIdBadge2}
                            />
                            <FormikTextField
                                label='Company Email *'
                                name='company_emailid'
                                type='email'
                                light={true}
                                placeholder='johndoe@companymail.com'
                                icon={HiOutlineMail}
                            />
                            <FormikTextField
                                label='password'
                                name='password'
                                type='password'
                                light={true}
                                placeholder='********'
                                icon={MdPassword}
                            />
                            <div className=' flex mt-4 justify-between gap-4'>
                                <button
                                    type='reset'
                                    onClick={() => setVerified(false)}
                                    className=' bg-gray-200 flex gap-2 items-center px-4 rounded-md text-sm'
                                >
                                    <MdClose className=' text-lg' />
                                    <p>Close</p>
                                </button>
                                <button
                                    className={` ${
                                        creating
                                            ? " opacity-60 pointer-events-none"
                                            : ""
                                    } bg-green-400 w-full text-sm rounded-md text-white cursor-pointer shadow-md flex items-center justify-center`}
                                    type='submit'
                                >
                                    {creating ? (
                                        <LoadingSpin text={"Creating..."} />
                                    ) : (
                                        <p className='py-3'>Create Client</p>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
};

export default MasterSignup;
