import React, { useEffect, useState } from "react";
import { formatDate } from "../../components/DashboardComponents/DashboardUtils/formatDate";
import ProjectReport from "../../components/DashboardComponents/DashboardComponents/ProjectReport";
import AccountReport from "../../components/DashboardComponents/DashboardComponents/AccountReport";
import DailyReport from "../../components/DashboardComponents/DashboardComponents/DailyReport";
import Notes from "../../components/DashboardComponents/DashboardComponents/Notes";
import { RiTimeFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import {
    getLoading,
    getProjects,
    getUsers,
    getSelfData,
} from "./Global/DashboardSlice";
import { LoadingSpin } from "./components/Loading";
import Name from "./components/Name";

const initialProject = [
    { name: "Jan", team: 0 },
    { name: "Feb", team: 0 },
    { name: "Mar", team: 0 },
    { name: "Apr", team: 0 },
    { name: "May", team: 0 },
    { name: "Jun", team: 0 },
    { name: "Jul", team: 0 },
    { name: "Aug", team: 0 },
    { name: "Sep", team: 0 },
    { name: "Oct", team: 0 },
    { name: "Nov", team: 0 },
    { name: "Dec", team: 0 },
];

const DashboardHome = () => {
    const projects = useSelector(getProjects);
    const users = useSelector(getUsers).total;
    const loggedUser = useSelector(getSelfData);
    const loading = useSelector(getLoading);
    const [projectData, setProjectData] = React.useState([initialProject]);
    const userID = loggedUser.tenant_id
        ? loggedUser.tenant_id
        : loggedUser.user_id;

    const getMonth = (timestamp) => {
        return formatDate(timestamp).split(" ")[0];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const transformDataForGraph = (projectData) => {
        const projectCounts = {};
        initialProject.forEach(({ name }) => {
            projectCounts[name] = 0;
        });
        projectData.forEach((project) => {
            const month = getMonth(project.created_at);
            projectCounts[month] += 1;
        });
        return Object.entries(projectCounts).map(([name, team]) => ({
            name,
            team,
        }));
    };

    useEffect(() => {
        const transformedData = transformDataForGraph(projects.total);
        setProjectData(transformedData);
    }, []);

    const [isHeaderOpen, setIsHeaderOpen] = useState(true);
    const handleHeaderClose = () => {
        setIsHeaderOpen(false);
    };
    return (
        <div className='flex flex-col overflow-scroll p-4'>
            <div className=''>
                <Name />
                {/* Insert your chart component here */}

                {/* Project and Daily Reports, Account Report and Notes */}
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 flex-1 overflow-auto ml-2 md:ml-4'>
                    <div className='flex flex-col overflow-auto ml-4'>
                        <div className='my-2 overflow-auto'>
                            {isHeaderOpen && (
                                <div
                                    className='flex flex-col bg-white text-black py-4 px-6 justify-between items-center rounded-lg border border-gray-300'
                                    id='Renewal'
                                >
                                    {loggedUser.haveAccess ? (
                                        <div className='flex items-center gap-4'>
                                            <div className='flex items-center space-x-2'>
                                                <RiTimeFill size={64} />
                                            </div>
                                            {/* Remaining days */}
                                            <div className='flex flex-col px-4 gap-4'>
                                                <p className='text-sm font-bold opacity-80'>
                                                    Your Account Is Expiring in
                                                </p>
                                                <p className='text-lg font-semibold opacity-90'>
                                                    {loggedUser.haveAccess}
                                                </p>
                                                {/* Last renewed date */}
                                                <div className=' flex items-center gap-2 justify-between'>
                                                    <p className='text-sm text-gray-500 mt'>
                                                        Joining Date:{" "}
                                                        {loading.selfData
                                                            ? "..."
                                                            : formatDate(
                                                                  loggedUser.joined_date,
                                                              )}
                                                    </p>
                                                    <p className='text-sm text-gray-500'>
                                                        Next Renewal Date:{" "}
                                                        {loading.selfData
                                                            ? "..."
                                                            : formatDate(
                                                                  loggedUser.expiry_time,
                                                              )}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* Close X icon */}
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className='h-6 w-6 text-gray-500 cursor-pointer'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                stroke='currentColor'
                                                onClick={handleHeaderClose}
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='2'
                                                    d='M6 18L18 6M6 6l12 12'
                                                />
                                            </svg>
                                        </div>
                                    ) : (
                                        <div className=' relative overflow-hidden'>
                                            <p className=' text-red-400 text-md bg-gray-200 px-6 py-2 rounded-md shadow-md border-2 border-red-400'>
                                                Account is Expired
                                            </p>
                                            <p className='absolute top-0 left-0 animate-ping opacity-40 text-red-400 text-md bg-gray-200 px-6 py-2 rounded-md shadow-md border-2 border-red-400'>
                                                Account is Expired
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className='mb-2'>
                            <ProjectReport data={projectData} />
                        </div>
                        <div className='mt-2'>
                            <DailyReport
                                projectsCreated={
                                    loading.projects ? (
                                        <LoadingSpin text={null} />
                                    ) : (
                                        projects.total.length
                                    )
                                }
                                projectsCompleted={
                                    loading.projects ? (
                                        <LoadingSpin text={null} />
                                    ) : (
                                        projects.completed.length
                                    )
                                }
                                projectsInProgress={
                                    loading.projects ? (
                                        <LoadingSpin text={null} />
                                    ) : (
                                        projects.processing.length
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className='flex flex-col overflow-auto ml-4'>
                        <div className='mb-2'>
                            <AccountReport
                                currentProjects={
                                    loading.projects ? (
                                        <LoadingSpin />
                                    ) : (
                                        projects.processing.length
                                    )
                                }
                                usersAdded={
                                    loading.users ? (
                                        <LoadingSpin />
                                    ) : (
                                        users.length
                                    )
                                }
                                projectsCreated={
                                    loading.projects ? (
                                        <LoadingSpin />
                                    ) : (
                                        projects.total.length
                                    )
                                }
                                projectsCompleted={
                                    loading.projects ? (
                                        <LoadingSpin />
                                    ) : (
                                        projects.completed.length
                                    )
                                }
                                projectsFailed={
                                    loading.projects ? (
                                        <LoadingSpin />
                                    ) : (
                                        projects.failed.length
                                    )
                                }
                            />
                        </div>
                        <div className='mt-2'>
                            <Notes userID={userID} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHome;
