import { FiDownload } from "react-icons/fi";
import VtkRendering from "./VtkRendering";

const App = ({ setPreview, resultData }) => {
    const sizeCalc = (str) => {
        const encoder = new TextEncoder();
        const byteArray = encoder.encode(str);
        const sizeInBytes = byteArray.byteLength;
        return Math.floor(sizeInBytes / 1024);
    };
    const data = [
        {
            visible: true,
            id: 0,
            Name: "Result.vtk",
            Size: sizeCalc(resultData) + " KB",
            Type: "Unstructured Grid",
            Cells: "",
            Points: "",
            Min: null,
            Max: null,
            is3D: false,
            Data: resultData,
        },
    ];

    const downloadFile = () => {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(resultData),
        );
        element.setAttribute("download", "result.vtk");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
    // const downloadImage = () => {
    //   console.log(document.getElementById('canvas'))
    //   let canvas=document.getElementsByTagName("canvas")[0]
    //   if (canvas) {
    //     console.log("first")
    //     const dataURL = canvas.toDataURL('image/png');
    //     const link = document.createElement('a');
    //     link.href = dataURL;
    //     link.download = 'screenshot.png';
    //     link.click();
    //   }
    // };

    return (
        <div className='relative h-full w-full rounded-lg'>
            <div className='absolute -top-8 left-0 w-full bg-gray-600 flex z-50 pl-4 items-center border-gray-500 rounded-t-md overflow-hidden'>
                <p className=' flex-grow text-white text-sm'>Result</p>
                <p
                    className='fa fa-close text-white bg-red-600 p-2 px-4 cursor-pointer'
                    onClick={() => setPreview(false)}
                ></p>
            </div>
            <VtkRendering vtkData={data[0]} />
            <div className=' absolute text-sm bottom-1 left-2 flex gap-2 items-center'>
                <div
                    onClick={downloadFile}
                    className='flex gap-2 items-center bg-white p-2 px-4 rounded-lg shadow-lg tracking-wide cursor-pointer'
                >
                    <FiDownload />
                    <p className=' text-xs'>Download</p>
                </div>
                {/* <div className=' bg-white p-2 px-3 rounded-lg shadow-lg cursor-pointer'>
            <BiImage onClick={downloadImage}/>
          </div> */}
            </div>
        </div>
    );
};

export default App;
