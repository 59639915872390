import { FormikTextField } from "../../components/FormComponents/FormikTextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    useAdminRecoveryPasswordMutation,
    useRecoveryPasswordMutation,
} from "../../services/auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setkey } from "../../services/auth/keySlice";
import { useLocalAuth } from "../../hooks/useLocalAuth";
import { HiOutlineMail } from "react-icons/hi";

const RecoverAccountWidget = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setKeyWithExpiry } = useLocalAuth();

    const [userRecoveryPassword, { userIsLoading }] =
        useRecoveryPasswordMutation();
    const [recoveryPassword, { isLoading }] =
        useAdminRecoveryPasswordMutation();
    const isAdmin = localStorage.getItem("selected-tab");

    return (
        <Formik
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email("Invalid email address")
                    .required("Email address is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    let response;
                    if (isAdmin === "admin" || isAdmin === null) {
                        response = await recoveryPassword(values);
                    } else {
                        response = await userRecoveryPassword(values);
                    }

                    const { data, error } = response;
                    if (data) {
                        dispatch(setkey(data));
                        setKeyWithExpiry(data.key);
                        navigate("/login");
                        toast.success(
                            "Email has been sent to you for changing password",
                        );
                    }
                    if (error) {
                        if (error.data?.detail) {
                            toast.error(error.data.detail);
                        } else {
                            toast.error("An unexpected error occurred.");
                        }
                    } else {
                        toast.error("Something went wrong");
                    }
                } catch (error) {
                    toast.error(
                        "An error occurred during password recovery. Please try again.",
                    );
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form>
                <div className='text-center text-white text-2xl font-bold mb-6'>
                    Reset Your{" "}
                    <span className='text-iris-blue-500'>Password</span>
                </div>
                <div className='text-center text-gray-200 text-sm mb-10'>
                    {isLoading || userIsLoading ? (
                        <></>
                    ) : (
                        <>
                            <div>
                                We will send you an email to your registered
                                E-Mail ID with the password reset link.
                            </div>
                            <FormikTextField
                                label='Email Address'
                                name='email'
                                type='email'
                                placeholder='hey@company.com'
                                icon={HiOutlineMail}
                            />
                        </>
                    )}
                </div>
                <div className='flex justify-center mt-12'>
                    <button
                        className='bg-iris-blue-500 text-md font-medium hover:bg-iris-blue-800 text-white justify-center items-center h-12 hover:text-black px-8 rounded-lg'
                        type='submit'
                        disabled={isLoading || userIsLoading}
                    >
                        {isLoading || userIsLoading
                            ? "Sending..."
                            : "Reset Password"}
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export { RecoverAccountWidget };
