import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authToken } from "../../services/auth/authSlice";
import { BackgroundPage } from "../BackgroundPage";
import { LoginWidget } from "../Login/LoginWidget";
import bqp_logo from "../../assets/images/bqphy-light.png";
import CarBackground from "../../assets/car/CarBackground.png";
import { RecoverAccountWidget } from "../RecoverAccountPage/RecoverAccountWidget";
import TermsAndPolicyWidget from "../TermsAndPolicyWidget";
import { ConfirmPasswordWidget } from "../ConfirmPasswordWidget/ConfirmPasswordWidget";

const LoginSignupWidget = ({ type }) => {
    const token = useSelector(authToken);

    if (!token) {
        return <Navigate to='/login' replace />;
    }

    let WidgetComponent;
    let additionalStyles = "px-6 py-10 h-full bg-no-repeat bg-left";

    switch (type) {
        case "login":
            WidgetComponent = <LoginWidget />;
            break;
        case "reset-password":
            WidgetComponent = <RecoverAccountWidget />;
            break;
        case "confirm":
            WidgetComponent = <ConfirmPasswordWidget />;
            break;
        case "termsandpolicy":
            WidgetComponent = <TermsAndPolicyWidget />;
            additionalStyles += " px-24";
            break;
        default:
            return null;
    }

    return (
        <BackgroundPage
            imgStyle={additionalStyles}
            logo={bqp_logo}
            backgroundImage={CarBackground}
        >
            <div className='p-4 rounded-lg shadow-lg bg-black bg-opacity-20 backdrop-filter backdrop-blur-md'>
                {WidgetComponent}
            </div>
        </BackgroundPage>
    );
};

LoginSignupWidget.propTypes = {
    type: PropTypes.oneOf([
        "login",
        "reset-password",
        "confirm",
        "termsandpolicy",
    ]).isRequired,
};

export { LoginSignupWidget };
