import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../routes/BaseUrl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../services/auth/authSlice";
import { useLocalAuth } from "../../../hooks/useLocalAuth";
import { LoadingSpin } from "../../../routes/Dashboard/components/Loading";
import MasterUser from "./MasterUser";
import { FaAngleDown } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";

let sortVar = { name: true, user_id: true, planid: true };

const ManageUser = () => {
    const [visible, setVisible] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { clearLocalStorage } = useLocalAuth();

    const formatDate = (dateString) => {
        const options = { day: "numeric", month: "long", year: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleLogout = async () => {
        dispatch(setCredentials({ jwt: null, agreement: false }));
        clearLocalStorage();
        navigate("/master/login");
        toast.success("You've successfully logged out");

        try {
            const response = await fetch(baseUrl + "client/logout", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`Logout failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(baseUrl + "listtenant", {
                withCredentials: true,
            });

            if (response.status === 200) {
                const sortedData = response.data.sort(
                    (a, b) => a.planid - b.planid,
                );
                setData(sortedData);
                setVisible(true);
            } else {
                throw new Error("Unexpected response status");
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    const detail =
                        error.response.data.detail || "Unauthorized access";
                    toast.error(detail);
                    handleLogout();
                } else {
                    toast.error("Unable to fetch admin(s) data");
                }
            } else {
                toast.error("Network error or server is down");
            }
        }
    };

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAdd]);

    const sortOn = (key) => {
        let on;
        if (key === "planid") {
            data.sort((a, b) =>
                sortVar[key] ? b[key] - a[key] : a[key] - b[key],
            );
        } else {
            data.sort((a, b) => {
                const valueA = a[key];
                const valueB = b[key];
                const nameA = valueA ? valueA.toString().toUpperCase() : "";
                const nameB = valueB ? valueB.toString().toUpperCase() : "";

                on = sortVar[key];
                if (nameA < nameB) {
                    return on ? -1 : 1;
                }
                if (nameA > nameB) {
                    return on ? 1 : -1;
                }
                return 0;
            });
        }
        sortVar[key] = !sortVar[key];
        setData([...data]);
    };

    const handleAddUser = () => {
        setShowAdd(!showAdd);
    };

    return (
        <div className='h-full overflow-y-scroll scrollbar-thin'>
            {visible ? (
                <div className='flex flex-col h-full'>
                    <h1 className='text-lg font-medium text-gray-800 mb-1'>
                        Hey Admin!
                    </h1>
                    {!data.length ? (
                        <div className='flex flex-col items-center justify-center mt-4 mx-4 gap-5'>
                            <h1>No Admin has been added to this account</h1>
                            <div className='flex justify-center'>
                                <div
                                    onClick={handleAddUser}
                                    className='flex cursor-pointer item text-white items-center text-2xl px-4 py-2 rounded-md whitespace-nowrap justify-center w-min gap-2 bg-black'
                                >
                                    <IoMdAddCircleOutline />
                                    <p className='text-lg'>Add Admin</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className=' border border-gray rounded-xl mt-4 mx-4'>
                            <div className='w-full mb-4'>
                                <div className='flex justify-end'>
                                    <div
                                        onClick={handleAddUser}
                                        className='flex item cursor-pointer items-center text-2xl px-4 py-2 rounded-md whitespace-nowrap justify-center w-min gap-2 bg-black text-white hover:text-gray-200'
                                    >
                                        <IoMdAddCircleOutline />
                                        <p className='text-lg'>Create Admin</p>
                                    </div>
                                </div>
                            </div>
                            <table
                                border='1'
                                className='border-collapse w-full text-sm table-fixed '
                            >
                                <thead className='bg-white sticky top-0 shadow-sm'>
                                    <tr>
                                        <th className=' pl-4 p-2 text-left border-b border-slate-100 text-slate-500'>
                                            <p>Name</p>
                                        </th>
                                        <th className='text-left border-b border-slate-100 text-slate-500 whitespace-nowrap'>
                                            <p>Email Id</p>
                                        </th>
                                        <th className=' text-right border-b border-slate-100 text-slate-500'>
                                            <p>Expiry Date</p>
                                        </th>
                                        <th
                                            onClick={() => sortOn("planid")}
                                            className=' pr-4 border-b border-slate-100 text-slate-500'
                                        >
                                            <div className='flex gap-2 justify-end items-center whitespace-nowrap'>
                                                <p>Plan ID</p>
                                                <FaAngleDown />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <div className='overflow-y-auto h-full pb-4 '>
                                <table
                                    border='1'
                                    className='border-collapse w-full text-sm table-fixed'
                                >
                                    <tbody className='overflow-y-auto'>
                                        {data
                                            .slice()
                                            .reverse()
                                            .map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td className=' pl-4 p-2 py-4 border-b border-slate-100 text-slate-500 whitespace-nowrap overflow-hidden'>
                                                            <p className=' overflow-scroll scrollbar-none'>
                                                                {
                                                                    item.first_name
                                                                }
                                                            </p>
                                                        </td>
                                                        <td className='border-b border-slate-100 text-slate-500 whitespace-nowrap overflow-hidden'>
                                                            <p className=' overflow-scroll scrollbar-none'>
                                                                {item.email}
                                                            </p>
                                                        </td>
                                                        <td className='text-right border-b border-slate-100 text-slate-500'>
                                                            {formatDate(
                                                                item.expiry_time,
                                                            )}
                                                        </td>
                                                        <td className=' pr-4 text-right border-b border-slate-100 text-slate-500'>
                                                            {item.planid}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {showAdd && (
                        <>
                            <div
                                className='absolute top-0 left-0 h-full w-full bg-white'
                                style={{
                                    background: "#0003",
                                    backdropFilter: "blur(2px)",
                                }}
                            ></div>
                            <div className='absolute left-1/2 top-1/4 transform -translate-x-1/2 flex items-start justify-center'>
                                <div className='bg-white shadow-lg px-2 py-4 pl-7 pr-7 pt-7 pb-6 rounded'>
                                    <h1 className='opacity-100 text-center font-bold font-large pb-4'>
                                        Add Admin
                                    </h1>
                                    <hr />
                                    <MasterUser
                                        onClose={(e) => setShowAdd(e)}
                                        onUserAdded={fetchData}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div>
                    <LoadingSpin
                        text={"Please wait while loading existing Admin(s)"}
                    />
                </div>
            )}
        </div>
    );
};

export default ManageUser;
