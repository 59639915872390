import { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaDownload } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
    getProjects,
    getSelfData,
} from "../../../routes/Dashboard/Global/DashboardSlice";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DailyReport = ({
    projectsCreated,
    projectsCompleted,
    projectsInProgress,
}) => {
    const [downloading, setDownloading] = useState(false);
    const projects = useSelector(getProjects);
    const loggedUser = useSelector(getSelfData);

    const downloadReport = () => {
        setDownloading(true);

        try {
            const doc = new jsPDF();

            doc.setFontSize(18);
            doc.text("BosonQ Psi's Project Report", 14, 22);
            doc.setFontSize(12);
            doc.text(`Hi ${loggedUser.first_name},`, 14, 36);
            doc.text(`Number of Projects: ${projects.total.length}`, 14, 42);

            const tableColumn = [
                "ID",
                "Project Name",
                "Simulation Type",
                "Created At",
                "Updated At",
                "Status",
            ];
            const tableRows = projects.total.map((project) => [
                project.id,
                project.projectName,
                project.simulationType,
                new Date(project.created_at).toLocaleString(),
                new Date(project.updated_at).toLocaleString(),
                project.status || "N/A",
            ]);

            doc.autoTable({
                startY: 50,
                head: [tableColumn],
                body: tableRows,
            });

            doc.save("BQPHY_DailyProjectReport.pdf");

            toast.success("Report is ready for download!");
            setDownloading(false);
        } catch (error) {
            setDownloading(false);
            toast.error(`Failed to generate report: ${error.message}`);
        }
    };

    const redirectManageProject = () => {
        document.getElementById("manageProjects").click();
    };

    return (
        <div className='border border-gray-300 bg-white-800 text-black-100 p-4 rounded-md shadow-lg'>
            <Toaster />
            <h3 className='text-md font-semibold mb-4'>Daily Report</h3>
            <p className='mb-2'>
                Keep improving the Quality Of Your Projects -
                <span
                    className='flex items-center cursor-pointer'
                    onClick={downloadReport}
                >
                    <span className='mr-2 underline'>
                        {downloading
                            ? "Generating Report..."
                            : "Download Your Report"}
                    </span>
                    <FaDownload size='1em' />
                </span>
            </p>
            <div className='flex items-center justify-center'>
                <div style={{ width: "100px", height: "100px" }}>
                    <CircularProgressbar
                        value={projectsCompleted}
                        text={`${
                            typeof projectsCompleted === "object"
                                ? "0"
                                : projectsCompleted
                        }%`}
                        styles={{
                            path: {
                                stroke: "#00ff00",
                            },
                            text: {
                                fill: "#000000",
                            },
                        }}
                    />
                </div>
                <div className='ml-8 space-y-2'>
                    <div className='flex items-center'>
                        <div className='w-3 h-3 rounded-full bg-black mr-2'></div>
                        <span>{projectsCreated} Projects Created</span>
                    </div>
                    <div className='flex items-center'>
                        <div className='w-3 h-3 rounded-full bg-black mr-2'></div>
                        <span>{projectsCompleted} Projects Completed</span>
                    </div>
                    <div className='flex items-center'>
                        <div className='w-3 h-3 rounded-full bg-black mr-2'></div>
                        <span>
                            {projectsInProgress} Projects are in Progress
                        </span>
                    </div>
                    <button
                        onClick={redirectManageProject}
                        className='bg-black text-white py-1 px-2 rounded-md mt-2 hover:bg-gray-600 transition-colors shadow-lg'
                    >
                        View Projects
                    </button>
                </div>
            </div>
            {projects.length > 0 && (
                <table className='mt-4 w-full border-collapse border border-gray-300'>
                    <thead>
                        <tr>
                            <th className='border border-gray-300 px-2 py-1'>
                                ID
                            </th>
                            <th className='border border-gray-300 px-2 py-1'>
                                Project Name
                            </th>
                            <th className='border border-gray-300 px-2 py-1'>
                                Simulation Type
                            </th>
                            <th className='border border-gray-300 px-2 py-1'>
                                Created At
                            </th>
                            <th className='border border-gray-300 px-2 py-1'>
                                Updated At
                            </th>
                            <th className='border border-gray-300 px-2 py-1'>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.map((project) => (
                            <tr key={project.id}>
                                <td className='border border-gray-300 px-2 py-1'>
                                    {project.id}
                                </td>
                                <td className='border border-gray-300 px-2 py-1'>
                                    {project.projectName}
                                </td>
                                <td className='border border-gray-300 px-2 py-1'>
                                    {project.simulationType}
                                </td>
                                <td className='border border-gray-300 px-2 py-1'>
                                    {new Date(
                                        project.created_at,
                                    ).toLocaleString()}
                                </td>
                                <td className='border border-gray-300 px-2 py-1'>
                                    {new Date(
                                        project.updated_at,
                                    ).toLocaleString()}
                                </td>
                                <td className='border border-gray-300 px-2 py-1'>
                                    {project.status || "N/A"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default DailyReport;
