import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../services/auth/authSlice";
import { useLocalAuth } from "../../hooks/useLocalAuth";
import useFetchId from "../../hooks/useFetchId";
import { baseUrl } from "../../routes/BaseUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const SessionManagement = () => {
    const { clearLocalStorage } = useLocalAuth();
    const [redirect, setRedirect] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useFetchId();
    const isLogoutInitiatedRef = useRef(false);
    const selectedTab = localStorage.getItem("login-type");
    const [logoutExecuted, setLogoutExecuted] = useState(false);

    useEffect(() => {
        const startSession = () => {
            const storedStartTime = localStorage.getItem("start_timer");
            if (storedStartTime && id) {
                const startTime = new Date(storedStartTime);
                const currentTime = new Date();

                const elapsedTime = currentTime - startTime;
                const totalSessionMs = 8 * 60 * 60 * 1000;

                let timeoutTotal;

                if (totalSessionMs > 0) {
                    timeoutTotal = setTimeout(() => {
                        if (!isLogoutInitiatedRef.current && !logoutExecuted) {
                            handleLogout();
                        }
                    }, totalSessionMs);
                }

                const handleLogout = () => {
                    if (isLogoutInitiatedRef.current || logoutExecuted) {
                        return;
                    }

                    isLogoutInitiatedRef.current = true;
                    setLogoutExecuted(true);

                    const activeDurationInSeconds = Math.floor(
                        elapsedTime / 1000,
                    );

                    let apiUrl;
                    if (selectedTab === "master") {
                        apiUrl = `${baseUrl}client/active_time/${id}`;
                    } else if (selectedTab === "tenant") {
                        apiUrl = `${baseUrl}tenant/active_time/${id}`;
                    } else {
                        apiUrl = `${baseUrl}active_time/${id}`;
                    }

                    fetch(apiUrl, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            active_duration: activeDurationInSeconds,
                        }),
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(
                                    "Failed to update active duration",
                                );
                            }
                            return response.json();
                        })
                        .then(() => {
                            console.log("Active duration updated successfully");
                        })
                        .catch((error) => {
                            console.error(
                                "Error updating active duration:",
                                error,
                            );
                        })
                        .finally(() => {
                            navigate("/logout");
                            dispatch(
                                setCredentials({ jwt: null, agreement: false }),
                            );
                            clearLocalStorage();
                            setRedirect(true);
                            setTimeout(() => {
                                navigate("/login");
                                toast.success("You've successfully logged out");
                            }, 2000);
                            toast.error(
                                "Your session has expired. Logging out for security reasons.",
                            );
                        });
                };
                return () => {
                    clearTimeout(timeoutTotal);
                };
            }
        };

        startSession();
    }, [
        dispatch,
        navigate,
        clearLocalStorage,
        id,
        selectedTab,
        logoutExecuted,
        setRedirect,
    ]);

    useEffect(() => {
        if (redirect) {
            setTimeout(() => {
                navigate("/login");
                toast.success("You've successfully logged out");
            }, 2000);
        }
    }, [redirect, navigate]);

    return null;
};

export default SessionManagement;
