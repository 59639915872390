import { BackgroundPage } from "../BackgroundPage";
import { SignUpWidget } from "../SignUp/SignUpWidget";
import { useSelector } from "react-redux";
import bqp_logo from "../../assets/images/bqphy-light.png";
import { authToken } from "../../services/auth/authSlice";
import { Navigate } from "react-router-dom";
import { LoadingSpin } from "../Dashboard/components/Loading";
const Signup = () => {
    const token = useSelector(authToken);
    if (token.agreement === true) {
        return <Navigate to='/dashboard' />;
    }
    if (token.agreement === undefined) {
        return <Navigate to='/login' />;
    }
    return (
        <>
            {token.agreement === false ? (
                <div>
                    <BackgroundPage
                        imgStyle={"px-6 py-10 h-full bg-no-repeat bg-left"}
                        logo={bqp_logo}
                        backgroundImage={""}
                    >
                        <div
                            style={{
                                backgroundColor: "#202020",
                                borderRadius: "25px",
                                zIndex: 10,
                            }}
                            className=' p-4 transform scale-75 md:scale-100'
                        >
                            <SignUpWidget />
                        </div>
                    </BackgroundPage>
                </div>
            ) : (
                <LoadingSpin />
            )}
        </>
    );
};

export default Signup;
