import PropTypes from "prop-types";

const TermsAndCondition = ({ light }) => {
    const headingStyle = light
        ? "text-lg font-semibold"
        : "text-md font-semibold text-gray-800";
    return (
        <div
            className={
                light
                    ? "text-gray-200 m-4 max-h-64 overflow-y-auto"
                    : "text-gray-600 m-4 h-3/4 overflow-y-auto pl-2"
            }
        >
            <h2 className={`mb-2 ${!light ? "text-3xl" : "text-xl"}`}>
                Beta Terms of Service
            </h2>
            {!light && <hr className='border-gray-200 my-4' />}
            <p>
                These Terms of Service (this "Agreement") is a binding contract
                between you ("Customer," "you," or "your") and BosonQ Psi Corp.
                (“BQP”, “we”, “us”, or “our”). This Agreement governs your
                access to and use of the Services. BQP and Customer may be
                referred to herein collectively as the "Parties" or individually
                as a "Party."
            </p>
            <br />
            <p>
                THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE BOX NEXT TO “I
                AGREE” AND INITIATE USE OF THE SERVICES OR BY ACCESSING OR USING
                THE SERVICES (the "Effective Date"). BY CLICKING THE BOX NEXT TO
                “I AGREE” AND INITIATING USE OF THE SERVICES OR BY OTHERWISE
                ACCESSING OR USING THE SERVICES YOU
                <ol className=' pl-5 p-3' style={{ listStyle: "upper-alpha" }}>
                    <li className=' py-0.5'>
                        ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS
                        AGREEMENT;{" "}
                    </li>
                    <li className=' py-0.5'>
                        REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER,
                        AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND, IF
                        ENTERING INTO THIS AGREEMENT FOR AN ORGANIZATION, THAT
                        YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ORGANIZATION;
                        AND{" "}
                    </li>
                    <li className=' py-0.5'>
                        ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY
                        BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS,
                        DO NOT CLICK THE BOX NEXT TO “I AGREE” OR ATTEMPT TO
                        ACCESS OR USE THE SERVICES. IF YOU DO NOT ACCEPT THESE
                        TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES.
                    </li>
                </ol>
            </p>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    1. <span className='underline'>Definitions.</span>
                </h3>
                <ul>
                    <li>
                        "Aggregated Statistics" means data and information
                        related to Customer's use of the Services that is used
                        by BQP in an aggregate and anonymized manner, including
                        to compile statistical and performance information
                        related to the provision and operation of the Services.
                    </li>
                    <br />
                    <li>
                        "Authorized User" means Customer's employees,
                        consultants, contractors, and agents (i) who are
                        authorized by Customer to access and use the Services
                        under the rights granted to Customer pursuant to this
                        Agreement and (ii) for whom access to the Services has
                        been purchased hereunder.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        (a) "BQP IP" means the Services, the Documentation, and
                        any and all intellectual property provided to Customer
                        or any Authorized User in connection with the foregoing.
                        For the avoidance of doubt, BQP IP includes Aggregated
                        Statistics and any information, data, or other content
                        derived from BQP's monitoring of Customer's access to or
                        use of the Services, but does not include Customer Data.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        (b) "Customer Data" means, other than Aggregated
                        Statistics, information, data, and other content, in any
                        form or medium, that is submitted, posted, or otherwise
                        transmitted by or on behalf of Customer or an Authorized
                        User through the Services.
                    </li>
                    <br />
                    {/* Noncompliant: ambiguous spacing */}
                    <li className=' pl-5'>
                        (c)
                        <span className='font-bold'>"Documentation"</span> means
                        BQP's user manuals, handbooks, and guides relating to
                        the Services provided by BQP to Customer either
                        electronically or in hard copy form.{" "}
                    </li>
                    <br />
                    <li>
                        "Services" means the grouping of Services detailed on
                        BQP’s website available at beta.bosonqpsi.com or as
                        otherwise specified from time to time by BQP.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    2. <span className='underline'> Access and Use.</span>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        <span className='underline'>Provision of Access.</span>{" "}
                        Subject to and conditioned on Customer's compliance with
                        all other terms and conditions of this Agreement, BQP
                        hereby grants Customer a non-exclusive,
                        non-transferable, limited right to access and use the
                        Services during the Term, solely for use by Authorized
                        Users in accordance with the terms and conditions
                        herein. Such use is limited to Customer's internal use.
                        BQP shall provide to Customer the necessary passwords
                        and network links or connections to allow Customer to
                        access the Services.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        {/* Noncompliant: ambiguous spacing */}
                        <span className='underline'>
                            {/* Noncompliant: ambiguous spacing */}
                            Documentation License.{" "}
                        </span>
                        Subject to the terms and conditions contained in this
                        Agreement, BQP hereby grants to Customer a
                        non-exclusive, non-sublicensable, non-transferable
                        license to use the Documentation during the Term solely
                        for Customer's internal business purposes in connection
                        with its use of the Services.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Use Restrictions. </span>
                        Customer shall not use the Services for any purposes
                        beyond the scope of the access granted in this
                        Agreement. Customer shall not at any time, directly or
                        indirectly, and shall not permit any Authorized Users
                        to:
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-roman" }}
                        >
                            <li className='py-0.5'>
                                copy, modify, or create derivative works of the
                                Services or Documentation, in whole or in part;{" "}
                            </li>
                            <li className='py-0.5'>
                                rent, lease, lend, sell, license, sublicense,
                                assign, distribute, publish, transfer, or
                                otherwise make available the Services or
                                Documentation;{" "}
                            </li>
                            <li className='py-0.5'>
                                reverse engineer, disassemble, decompile,
                                decode, adapt, or otherwise attempt to derive or
                                gain access to any software component of the
                                Services, in whole or in part;{" "}
                            </li>
                            <li className='py-0.5'>
                                remove any proprietary notices from the Services
                                or Documentation; or{" "}
                            </li>
                            <li className='py-0.5'>
                                {" "}
                                use the Services or Documentation in any manner
                                or for any purpose that infringes,
                                misappropriates, or otherwise violates any
                                intellectual property right or other right of
                                any person, or that violates any applicable law.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Changes.</span> BQP reserves
                        the right, in its sole discretion, to make any changes
                        to the Services and Documentation that it deems
                        necessary or useful.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>
                            Reservation of Rights.
                        </span>{" "}
                        BQP reserves all rights not expressly granted to
                        Customer in this Agreement. Except for the limited
                        rights and licenses expressly granted under this
                        Agreement, nothing in this Agreement grants, by
                        implication, waiver, estoppel, or otherwise, to Customer
                        or any third party any intellectual property rights or
                        other right, title, or interest in or to the BQP IP.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Suspension.</span>{" "}
                        Notwithstanding anything to the contrary in this
                        Agreement, BQP may temporarily suspend Customer's and
                        any Authorized User's access to any portion or all of
                        the Services if:
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-roman" }}
                        >
                            <li>
                                BQP reasonably determines that
                                <ol
                                    className=' pl-5 p-3'
                                    style={{ listStyle: "upper-alpha" }}
                                >
                                    <li className=' py-0.5'>
                                        there is a threat or attack on any of
                                        the BQP IP;{" "}
                                    </li>
                                    <li className=' py-0.5'>
                                        Customer's or any Authorized User's use
                                        of the BQP IP disrupts or poses a
                                        security risk to the BQP IP or to any
                                        other customer or vendor of BQP;
                                    </li>
                                    <li className=' py-0.5'>
                                        Customer, or any Authorized User, is
                                        using the BQP IP for fraudulent illegal
                                        activities;{" "}
                                    </li>
                                    <li className=' py-0.5'>
                                        subject to applicable law, Customer has
                                        ceased to continue its business in the
                                        ordinary course, made an assignment for
                                        the benefit of creditors or similar
                                        disposition of its assets, or become the
                                        subject of any bankruptcy,
                                        reorganization, liquidation,
                                        dissolution, or similar proceeding; or
                                    </li>
                                    <li className=' py-0.5'>
                                        BQP's provision of the Services to
                                        Customer or any Authorized User is
                                        prohibited by applicable law;{" "}
                                    </li>
                                </ol>
                            </li>
                            <li className=' py-0.5'>
                                any vendor of BQP has suspended or terminated
                                BQP's access to or use of any third-party
                                services or products required to enable Customer
                                to access the Services; or
                            </li>
                            <li className=' py-0.5'>
                                in accordance with Section 5(a)(iii) (any such
                                suspension described in subclause (i), (ii), or
                                (iii), a “Service Suspension”). BQP shall use
                                commercially reasonable efforts to provide
                                written notice of any Service Suspension to
                                Customer and to provide updates regarding
                                resumption of access to the Services following
                                any Service Suspension. BQP shall use
                                commercially reasonable efforts to resume
                                providing access to the Services as soon as
                                reasonably possible after the event giving rise
                                to the Service Suspension is cured. BQP will
                                have no liability for any damage, liabilities,
                                losses (including any loss of data or profits),
                                or any other consequences that Customer or any
                                Authorized User may incur as a result of a
                                Service Suspension.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>
                            Aggregated Statistics.
                        </span>{" "}
                        Notwithstanding anything to the contrary in this
                        Agreement, BQP may monitor Customer's use of the
                        Services and collect and compile Aggregated Statistics.
                        As between BQP and Customer, all right, title, and
                        interest in Aggregated Statistics, and all intellectual
                        property rights therein, belong to and are retained
                        solely by BQP. Customer acknowledges that BQP may
                        compile Aggregated Statistics based on Customer Data
                        input into the Services. Customer agrees that BQP may
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-roman" }}
                        >
                            <li className=' py-0.5'>
                                make Aggregated Statistics publicly available in
                                compliance with applicable law, and
                            </li>
                            <li className=' py-0.5'>
                                use Aggregated Statistics to the extent and in
                                the manner permitted under applicable law;
                                provided that such Aggregated Statistics do not
                                identify Customer or Customer's Confidential
                                Information.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>
                            Customer Responsibilities.
                        </span>{" "}
                        Acceptable Use Policy. The Services may not be used for
                        unlawful, fraudulent, offensive, or obscene activity, as
                        further described and set forth in Provider's acceptable
                        use policy ("AUP"), as may be amended from time to time,
                        which is incorporated herein by reference. Customer will
                        comply with all terms and conditions of this Agreement,
                        all applicable laws, rules, and regulations, and all
                        guidelines, standards, and requirements that may be
                        posted from time to time, including the AUP.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Account Use.</span> Customer
                        is responsible and liable for all uses of the Services
                        and Documentation resulting from access provided by
                        Customer, directly or indirectly, whether such access or
                        use is permitted by or in violation of this Agreement.
                        Without limiting the generality of the foregoing,
                        Customer is responsible for all acts and omissions of
                        Authorized Users, and any act or omission by an
                        Authorized User that would constitute a breach of this
                        Agreement if taken by Customer will be deemed a breach
                        of this Agreement by Customer. Customer shall use
                        reasonable efforts to make all Authorized Users aware of
                        this Agreement's provisions as applicable to such
                        Authorized User's use of the Services and shall cause
                        Authorized Users to comply with such provisions.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Customer Data.</span>{" "}
                        Customer will ensure that Customer Data and any
                        Authorized User's use of Customer Data will not violate
                        any policy or terms referenced in or incorporated into
                        this Agreement or any applicable law. Customer is solely
                        responsible for the development, content, operation,
                        maintenance, and use of Customer Data.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>
                            Passwords and Access Credentials.{" "}
                        </span>
                        Customer is responsible for keeping Customer’s or any
                        Authorized User’s passwords and access credentials
                        associated with the Services confidential. Customer will
                        not sell or transfer them to any other person or entity.
                        Customer will promptly notify BQP about any unauthorized
                        access to Customer’s or any Authorized User’s passwords
                        or access credentials.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Third-Party Products.</span>{" "}
                        BQP may from time to time make products, content,
                        services, information, websites, or other materials that
                        are owned by third parties a part of or accessible
                        through the Services (collectively “Third-Party
                        Products”). For purposes of this Agreement, such
                        Third-Party Products are subject to their own terms and
                        conditions. If Customer does not agree to abide by the
                        applicable terms for any such Third-Party Products, then
                        Customer should not use or install such Third-Party
                        Products and/or the Services.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    3. <span className='underline'>Taxes</span>{" "}
                </h3>
                <ul>
                    <li className=' pl-5'>
                        Customer is responsible for any and all sales, use, and
                        excise taxes, and any other similar taxes, duties, and
                        charges of any kind imposed by any federal, state, or
                        local governmental or regulatory authority in relation
                        to this Agreement, other than any taxes imposed on BQP's
                        income.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    4.{" "}
                    <span className='underline'>Confidential Information.</span>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        From time to time during the Term, either Party may
                        disclose or make available to the other Party
                        information about its business affairs, products,
                        confidential intellectual property, trade secrets,
                        third-party confidential information, and other
                        sensitive or proprietary information, whether orally or
                        in written, electronic, or other form or media/in
                        written or electronic form or media, that is marked,
                        designated, or otherwise identified as "confidential"
                        (collectively, "Confidential Information"). Confidential
                        Information does not include information that, at the
                        time of disclosure is:
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-latin" }}
                        >
                            <li className=' py-0.5'>in the public domain;</li>
                            <li className=' py-0.5'>
                                known to the receiving Party at the time of
                                disclosure;{" "}
                            </li>
                            <li className=' py-0.5'>
                                rightfully obtained by the receiving Party on a
                                non-confidential basis from a third party; or{" "}
                            </li>
                            <li className=' py-0.5'>
                                independently developed by the receiving Party.
                                The receiving Party shall not disclose the
                                disclosing Party's Confidential Information to
                                any person or entity, except to the receiving
                                Party's employees who have a need to know the
                                Confidential Information for the receiving Party
                                to exercise its rights or perform its
                                obligations hereunder. Notwithstanding the
                                foregoing, each Party may disclose Confidential
                                Information to the limited extent required
                                <ol
                                    className=' pl-5 p-3'
                                    style={{ listStyle: "lower-roman" }}
                                >
                                    <li className=' py-0.5'>
                                        in order to comply with the order of a
                                        court or other governmental body, or as
                                        otherwise necessary to comply with
                                        applicable law, provided that the Party
                                        making the disclosure pursuant to the
                                        order shall first have given written
                                        notice to the other Party and made a
                                        reasonable effort to obtain a protective
                                        order; or{" "}
                                    </li>
                                    <li className=' py-0.5'>
                                        to establish a Party's rights under this
                                        Agreement, including to make required
                                        court filings. On the expiration or
                                        termination of the Agreement, the
                                        receiving Party shall promptly return to
                                        the disclosing Party all copies, whether
                                        in written, electronic, or other form or
                                        media, of the disclosing Party's
                                        Confidential Information, or destroy all
                                        such copies and certify in writing to
                                        the disclosing Party that such
                                        Confidential Information has been
                                        destroyed. Each Party's obligations of
                                        non-disclosure with regard to
                                        Confidential Information are effective
                                        as of the Effective Date and will expire
                                        five years from the date first disclosed
                                        to the receiving Party; provided,
                                        however, with respect to any
                                        Confidential Information that
                                        constitutes a trade secret (as
                                        determined under applicable law), such
                                        obligations of non-disclosure will
                                        survive the termination or expiration of
                                        this Agreement for as long as such
                                        Confidential Information remains subject
                                        to trade secret protection under
                                        applicable law.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li className=' pl-5'>
                        Privacy Policy. BQP complies with its privacy policy, in
                        providing the Services. The Privacy Policy is subject to
                        change as described therein. By accessing, using, and
                        providing information to or through the Services,
                        Customer acknowledges that Customer has reviewed and
                        accepted BQP’s Privacy Policy, and Customer consents to
                        all actions taken by BQP with respect to customer’s
                        information in compliance with the then-current version
                        of BQP’s Privacy Policy with respect to Customer and all
                        Authorized Users.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    5.{" "}
                    <span className='underline'>
                        Intellectual Property Ownership; Usage Data; Feedback.{" "}
                    </span>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        <span className='underline'>BQP IP. </span>
                        Customer acknowledges that, as between Customer and BQP,
                        BQP owns all right, title, and interest, including all
                        intellectual property rights, in and to the BQP IP and,
                        with respect to Third-Party Products, the applicable
                        third-party providers own all right, title, and
                        interest, including all intellectual property rights, in
                        and to the Third-Party Products.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        {" "}
                        <span className='underline'>Customer Data.</span> BQP
                        acknowledges that, as between BQP and Customer, Customer
                        owns all right, title, and interest, including all
                        intellectual property rights, in and to the Customer
                        Data. Customer hereby grants to BQP a non-exclusive,
                        royalty-free, worldwide license to reproduce,
                        distribute, and otherwise use and display the Customer
                        Data and perform all acts with respect to the Customer
                        Data as may be necessary for BQP to provide the Services
                        to Customer, and a non-exclusive, perpetual,
                        irrevocable, royalty-free, worldwide license to
                        reproduce, distribute, modify, and otherwise use and
                        display Customer Data incorporated within the Aggregated
                        Statistics.
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>Usage Data.</span> BQP may
                        collect, maintain, process, and use, or Customer or an
                        Authorized User may provide to BQP, diagnostic,
                        technical, usage, and related information, including
                        information about Customer’s (including any Authorized
                        Users’) computers, mobile devices, systems, and software
                        (collectively, “Usage Data”). Customer agrees that all
                        Usage Data is owned solely and exclusively by BQP, and,
                        to the extent any ownership rights in or to the Usage
                        Data vest in Customer or in any Authorized User(s),
                        Customer hereby assigns to BQP, on behalf of Customer
                        and any such Authorized User(s), all rights, title, and
                        interest in and to the same. Accordingly, BQP may use
                        the Usage Data or any portion thereof for any lawful
                        purpose, including, without limitation:
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-latin" }}
                        >
                            <li className=' py-0.5'>
                                to provide and maintain the Services;{" "}
                            </li>
                            <li className=' py-0.5'>
                                to develop and improve the Services;{" "}
                            </li>
                            <li className=' py-0.5'>
                                to monitor Customer’s (including any Authorized
                                Users’) usage of the Services;
                            </li>
                            <li className=' py-0.5'>
                                for research and analytics and for BQP’s other
                                business purposes; and{" "}
                            </li>
                            <li className=' py-0.5'>
                                to share analytics and other derived Usage Data
                                with third parties, solely in deidentified or
                                aggregated form. The Service may contain
                                technological measures designed to prevent
                                unauthorized or illegal use of the Service.
                                Customer acknowledges and agrees that BQP may
                                use these and other lawful measures to verify
                                customer’s compliance with the terms of these
                                Terms and to enforce BQP’s rights, including all
                                rights related to BQP IP, in and to the
                                Services.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'> Feedback. </span>If
                        Customer or any of its employees or contractors sends or
                        transmits any communications or materials to BQP by
                        mail, email, telephone, or otherwise, suggesting or
                        recommending changes to the BQP IP, including without
                        limitation, new features or functionality relating
                        thereto, or any comments, questions, suggestions, or the
                        like ("Feedback"), BQP is free to use such Feedback
                        irrespective of any other obligation or limitation
                        between the Parties governing such Feedback. For
                        avoidance of doubt, such feedback is excluded from the
                        Confidential Information, whether or not marked as such.
                        Customer hereby assigns to BQP on Customer's behalf, and
                        on behalf of its employees, contractors and/or agents,
                        all right, title, and interest in, and BQP is free to
                        use, without any attribution or compensation to any
                        party, any ideas, know-how, concepts, techniques, or
                        other intellectual property rights contained in the
                        Feedback, for any purpose whatsoever, although BQP is
                        not required to use any Feedback.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <span className='underline'>
                            {" "}
                            Limited Warranty; Warranty Disclaimer.{" "}
                        </span>
                    </li>
                    <br />
                    <li className=' pl-8'>
                        <u>Customer Warranty.</u> Customer warrant that Customer
                        owns all right, title, and interest, including all
                        intellectual property rights, in and to Customer Data
                        and that both the Customer Data and customer’s use of
                        the Services are in compliance with the AUP.
                    </li>
                    <br />
                    <li>
                        THE SERVICES AND DOCUMENTATION ARE PROVIDED "AS IS" AND
                        “AS AVAILABLE” AND BQP HEREBY DISCLAIMS ALL WARRANTIES,
                        WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. BQP
                        SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING
                        FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. BQP
                        MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR
                        DOCUMENTATION, OR ANY PRODUCTS OR RESULTS OF THE USE
                        THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S OR
                        ENTITY’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
                        ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH
                        CUSTOMER’S OR ANY OTHER PERSON’S OR ENTITY’S SOFTWARE,
                        SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE,
                        COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE, OR THAT
                        ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. BQP
                        STRICTLY DISCLAIMS ALL WARRANTIES WITH RESPECT TO ANY
                        THIRD-PARTY PRODUCTS.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    6. <u>Indemnification</u>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        <u>Customer Indemnification. </u>Customer shall
                        indemnify, hold harmless, and, at BQP's option, defend
                        BQP from and against any Losses resulting from any
                        Third-Party Claim that the Customer Data, or any use of
                        the Customer Data in accordance with this Agreement,
                        infringes or misappropriates such third party's
                        intellectual property rights and any Third-Party Claims
                        based on Customer's or any Authorized User's
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-roman" }}
                        >
                            <li>negligence or willful misconduct; </li>
                            <li>
                                use of the Services in a manner not authorized
                                by this Agreement;{" "}
                            </li>
                            <li>
                                use of the Services in combination with data,
                                software, hardware, equipment, or technology not
                                provided by BQP or authorized by BQP in writing;
                                or{" "}
                            </li>
                            <li>
                                modifications to the Services not made by BQP,
                                provided that Customer may not settle any
                                Third-Party Claim against BQP unless BQP
                                consents to such settlement, and further
                                provided that BQP will have the right, at its
                                option, to defend itself against any such
                                Third-Party Claim or to participate in the
                                defense thereof by counsel of its own choice.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <u>Sole Remedy.</u> BQP SHALL HAVE NO LIABILITY OR
                        OBLIGATION WHATSOEVER TO CUSTOMER FOR ANY ACTUAL,
                        THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES
                        INFRINGE, MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY
                        INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    7. <u>Limitation of Liability</u>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        IN NO EVENT WILL BQP BE LIABLE UNDER OR IN CONNECTION
                        WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY,
                        INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
                        NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY:
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-latin" }}
                        >
                            <li className='py-0.5'>
                                CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
                                SPECIAL, ENHANCED, OR PUNITIVE DAMAGES;{" "}
                            </li>
                            <li className='py-0.5'>
                                INCREASED COSTS, DIMINUTION IN VALUE OR LOST
                                BUSINESS, PRODUCTION, REVENUES, OR PROFITS;
                            </li>
                            <li className='py-0.5'>
                                LOSS OF GOODWILL OR REPUTATION;
                            </li>
                            <li className='py-0.5'>
                                USE, INABILITY TO USE, LOSS, INTERRUPTION,
                                DELAY, OR RECOVERY OF ANY DATA, OR BREACH OF
                                DATA OR SYSTEM SECURITY; OR
                            </li>
                            <li className='py-0.5'>
                                COST OF REPLACEMENT GOODS OR SERVICES, IN EACH
                                CASE REGARDLESS OF WHETHER BQP WAS ADVISED OF
                                THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR
                                SUCH LOSSES OR DAMAGES WERE OTHERWISE
                                FORESEEABLE. IN NO EVENT WILL BQP'S AGGREGATE
                                LIABILITY ARISING OUT OF OR RELATED TO THIS
                                AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY,
                                INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
                                NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE
                                EXCEED ALL AMOUNTS PAID TO BQP UNDER THIS
                                AGREEMENT IN THE TWELVE-MONTH PERIOD PRECEDING
                                THE EVENT GIVING RISE TO THE CLAIM. THIS
                                AGREEMENT DOES NOT ENTITLE CUSTOMER TO ANY
                                SUPPORT FOR THE SERVICES. The exclusions and
                                limitations in this Section do not apply to the
                                parties' obligations under Section 9.
                            </li>
                        </ol>
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    8.<u>Term and Termination. </u>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        <u>Term.</u> The term of this Agreement begins on the
                        Effective Date and continues on a monthly basis until
                        terminated. Services that are specified to automatically
                        renew each month unless earlier terminated pursuant to
                        this Agreement's express provisions or either party
                        gives the other party written notice of non-renewal at
                        least thirty (30) days prior to the expiration of the
                        then-current services period.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <u>Termination.</u> In addition to any other express
                        termination right set forth in this Agreement:{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        Either party may terminate this Agreement for any
                        reason, effective immediately upon notice to the other
                        party.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        Either party may terminate this Agreement, effective on
                        written notice to the other party, if the other party
                        materially breaches this Agreement, and such breach: (A)
                        is incapable of cure; or (B) being capable of cure,
                        remains uncured for five (5) days after the
                        non-breaching party provides the breaching party with
                        written notice of such breach.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        Either party may terminate this Agreement, effective
                        immediately upon written notice to the other party, if
                        the other party: (A) becomes insolvent or is generally
                        unable to pay, or fails to pay, its debts as they become
                        due; (B) files, or has filed against it, a petition for
                        voluntary or involuntary bankruptcy or otherwise becomes
                        subject, voluntarily or involuntarily, to any proceeding
                        under any domestic or foreign bankruptcy or insolvency
                        law; (C) makes or seeks to make a general assignment for
                        the benefit of its creditors; or (D) applies for or has
                        appointed a receiver, trustee, custodian, or similar
                        agent appointed by order of any court of competent
                        jurisdiction to take charge of or sell any material
                        portion of its property or business.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <u>Effect of Expiration or Termination.</u> Upon
                        expiration or earlier termination of this Agreement,
                        Customer shall immediately discontinue use of the BQP IP
                        and, without limiting Customer's obligations under
                        Section 5, Customer shall delete, destroy, or return all
                        copies of the BQP IP and certify in writing to the BQP
                        that the BQP IP has been deleted or destroyed.{" "}
                    </li>
                    <br />
                    <li className=' pl-5'>
                        <u> Survival.</u> This Section 11(d) and Sections 1, 4,
                        5, 6, 8, 9, 10, and 12 survive any termination or
                        expiration of this Agreement. No other provisions of
                        this Agreement survive the expiration or earlier
                        termination of this Agreement.{" "}
                    </li>
                </ul>
            </div>
            <div className='mt-4'>
                <h3 className={`${headingStyle} mb-2`}>
                    9. <u>Miscellaneous. </u>
                </h3>
                <ul>
                    <li className=' pl-5'>
                        {" "}
                        <u>Entire Agreement. </u>This Agreement, together with
                        any other documents incorporated herein by reference and
                        all related Exhibits, constitutes the sole and entire
                        agreement of the Parties with respect to the subject
                        matter of this Agreement and supersedes all prior and
                        contemporaneous understandings, agreements, and
                        representations and warranties, both written and oral,
                        with respect to such subject matter. In the event of any
                        inconsistency between the statements made in the body of
                        this Agreement, the related Exhibits, and any other
                        documents incorporated herein by reference, the
                        following order of precedence governs:
                        <ol
                            className=' pl-5 p-3'
                            style={{ listStyle: "lower-roman" }}
                        >
                            <li className='py-0.5'>
                                first, this Agreement, excluding its Exhibits;
                            </li>
                            <li className='py-0.5'>
                                second, the Exhibits to this Agreement as of the
                                Effective Date; and
                            </li>
                            <li className='py-0.5'>
                                third, any other documents incorporated herein
                                by reference.
                            </li>
                        </ol>
                    </li>
                    <li className=' pl-5 py-1'>
                        <u>Notices.</u> Any notices to BQP must be sent to BQP’s
                        corporate headquarters address and must be delivered
                        either in person, by certified or registered mail,
                        return receipt requested and postage prepaid, or by
                        recognized overnight courier service, and are deemed
                        given upon receipt by us. BQP’s corporate headquarters
                        address is:{" "}
                    </li>
                    <li className=' pl-5'>
                        <br />
                        <p>BosonQ Psi Corp.</p>
                        <p>12 Fountain Plaza Suite 600</p>
                        <p>Buffalo, NY 14202 </p>
                        <p>United States of America</p>
                        <br />
                        <p>
                            Notwithstanding the foregoing, Customer hereby
                            consents to receiving electronic communications from
                            BQP. These electronic communications may include
                            notices about applicable fees and charges,
                            transactional information, and other information
                            concerning or related to the Services. Customer
                            agrees that any notices, agreements, disclosures, or
                            other communications that BQP sends to Customer
                            electronically will satisfy any legal communication
                            requirements, including that such communications be
                            in writing.
                        </p>
                         <br />
                        <p>
                            <u>Force Majeure. </u> In no event shall either
                            Party be liable to the other Party, or be deemed to
                            have breached this Agreement, for any failure or
                            delay in performing its obligations under this
                            Agreement (except for any obligations to make
                            payments), if and to the extent such failure or
                            delay is caused by any circumstances beyond such
                            Party's] reasonable control, including but not
                            limited to acts of God, flood, fire, earthquake,
                            explosion, war, terrorism, invasion, riot or other
                            civil unrest, strikes, labor stoppages or slowdowns
                            or other industrial disturbances, or passage of law
                            or any action taken by a governmental or public
                            authority, including imposing an embargo.{" "}
                        </p>
                        <br />
                        <p>
                            <u>Modification. </u>Customer acknowledges and
                            agrees that BQP has the right, in BQP’s sole
                            discretion, to modify this Agreement from time to
                            time, and that modified terms become effective on
                            posting. Customer will be notified of modifications
                            through email communication from us. Customer is
                            responsible for reviewing and becoming familiar with
                            any such modifications. Customer’s continued use of
                            the Services after the effective date of the
                            modifications will be deemed acceptance of the
                            modified terms. BQP will provide at least thirty
                            (30) days' advance notice of changes to any Services
                            that BQP reasonably anticipates may result in a
                            material reduction in quality or services.{" "}
                        </p>
                        <br />
                        <p>
                            <u>No Waiver.</u> No waiver by any Party of any of
                            the provisions hereof will be effective unless
                            explicitly set forth in writing and signed by the
                            Party so waiving. Except as otherwise set forth in
                            this Agreement,
                            <ol
                                className=' pl-5 p-3'
                                style={{ listStyle: "lower-roman" }}
                            >
                                <li className=' py-0.5'>
                                    no failure to exercise, or delay in
                                    exercising, any rights, remedy, power, or
                                    privilege arising from this Agreement will
                                    operate or be construed as a waiver thereof,
                                    and{" "}
                                </li>
                                <li className=' py-0.5'>
                                    no single or partial exercise of any right,
                                    remedy, power, or privilege hereunder will
                                    preclude any other or further exercise
                                    thereof or the exercise of any other right,
                                    remedy, power, or privilege.
                                </li>
                            </ol>
                        </p>
                        <br />
                        <p>
                            <u>Severability. </u> If any provision of this
                            Agreement is invalid, illegal, or unenforceable in
                            any jurisdiction, such invalidity, illegality, or
                            unenforceability will not affect any other term or
                            provision of this Agreement or invalidate or render
                            unenforceable such term or provision in any other
                            jurisdiction. Upon such determination that any term
                            or other provision is invalid, illegal, or
                            unenforceable, the Parties shall negotiate in good
                            faith to modify this Agreement so as to effect their
                            original intent as closely as possible in a mutually
                            acceptable manner in order that the transactions
                            contemplated hereby be consummated as originally
                            contemplated to the greatest extent possible.{" "}
                        </p>
                        <br />
                        <p>
                            <u>Governing Law; Submission to Jurisdiction. </u>{" "}
                            This agreement is governed by and construed in
                            accordance with the internal laws of the State of
                            New York without giving effect to any choice or
                            conflict of law provision or rule that would require
                            or permit the application of the laws of any
                            jurisdiction other than those of the State of New
                            York. Any legal suit, action, or proceeding arising
                            out of or related to this agreement or the rights
                            granted hereunder will be instituted exclusively in
                            the federal courts of the United States or the
                            courts of the State of New York in each case located
                            in the city of Buffalo and County of Erie, and each
                            party irrevocably submits to the exclusive
                            jurisdiction of such courts in any such suit,
                            action, or proceeding.
                        </p>
                        <br />
                        <p>
                            <u>Assignment. </u>This Agreement is personal to
                            Customer and may not be assigned or transferred for
                            any reason whatsoever without BQP’s prior written
                            consent and any action or conduct in violation of
                            the foregoing will be void and without effect. BQP
                            expressly reserves the right to assign this
                            Agreement and to delegate any of its obligations
                            hereunder. No assignment or delegation will relieve
                            the assigning or delegating Party of any of its
                            obligations hereunder. This Agreement is binding
                            upon and inures to the benefit of the Parties and
                            their respective permitted successors and assigns.
                        </p>
                        <br />
                        <p>
                            <u>Export Regulation.</u> The Services utilize
                            software and technology that may be subject to US
                            export control laws, including the US Export
                            Administration Act and its associated regulations.
                            Customer shall not, directly or indirectly, export,
                            re-export, or release the Services or the software
                            or technology included in the Services to or make
                            the Services or the software or technology included
                            in the Services accessible from, any jurisdiction or
                            country to which export, re-export, or release is
                            prohibited by law, regulation, or rule. Customer
                            shall comply with all applicable federal laws,
                            regulations, and rules, and complete all required
                            undertakings (including obtaining any necessary
                            export license or other governmental approval),
                            prior to exporting, re-exporting, releasing, or
                            otherwise making the Services or the software or
                            technology included in the Services available
                            outside the US.{" "}
                        </p>
                        <br />
                        <p>
                            <u>US Government Rights.</u> Each of the
                            Documentation and the software components that
                            constitute the Services is a "commercial product" as
                            that term is defined at 48 C.F.R. § 2.101,
                            consisting of "commercial computer software" and
                            "commercial computer software documentation" as such
                            terms are used in 48 C.F.R. § 12.212. Accordingly,
                            if Customer is an agency of the US Government or any
                            contractor therefor, Customer only receives those
                            rights with respect to the Services and
                            Documentation as are granted to all other end users,
                            in accordance with
                            <ol
                                className=' pl-5 p-3'
                                style={{ listStyle: "lower-alpha" }}
                            >
                                <li className='py-0.5'>
                                    48 C.F.R. § 227.7201 through 48 C.F.R. §
                                    227.7204, with respect to the Department of
                                    Defense and their contractors, or{" "}
                                </li>
                                <li className='py-0.5'>
                                    48 C.F.R. § 12.212, with respect to all
                                    other US Government users and their
                                    contractors.
                                </li>
                            </ol>
                        </p>
                        <br />
                        <p>
                            <u>Equitable Relief.</u> Each Party acknowledges and
                            agrees that a breach or threatened breach by such
                            Party of any of its obligations under Section 5 or,
                            in the case of Customer, Section 2(c), would cause
                            the other Party irreparable harm for which monetary
                            damages would not be an adequate remedy and agrees
                            that, in the event of such breach or threatened
                            breach, the other Party will be entitled to
                            equitable relief, including a restraining order, an
                            injunction, specific performance, and any other
                            relief that may be available from any court, without
                            any requirement to post a bond or other security, or
                            to prove actual damages or that monetary damages are
                            not an adequate remedy. Such remedies are not
                            exclusive and are in addition to all other remedies
                            that may be available at law, in equity, or
                            otherwise.{" "}
                        </p>
                    </li>
                </ul>
            </div>
            <p className='mt-8 font-medium text-iris-blue-500 underline'>
                <a href='https://www.bosonqpsi.com'> BosonQ Psi</a>
            </p>
        </div>
    );
};

TermsAndCondition.propTypes = {
    light: PropTypes.bool.isRequired,
};

export default TermsAndCondition;
