import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

const ProjectReport = ({ data }) => {
    const chartData = Array.isArray(data) ? data : [];

    let months = [];
    let maxTeamValue = 0;

    chartData.forEach((entry) => {
        months.push(entry.name);
        if (entry.team > maxTeamValue) {
            maxTeamValue = entry.team;
        }
    });

    return (
        <div className='border border-gray-300 bg-white-800 text-black-100 p-4 rounded-md shadow-lg'>
            <h3 className='text-md font-semibold mb-4'>Project Report</h3>
            <ResponsiveContainer width='100%' height={300}>
                <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                        dataKey='name'
                        ticks={months.length ? months : ["No Data"]}
                    />
                    <YAxis domain={[0, maxTeamValue + 5]} />
                    <Tooltip />
                    <Line type='monotone' dataKey='team' stroke='#8884d8' />
                    <Line type='monotone' dataKey='team1' stroke='#82ca9d' />
                    <Line type='monotone' dataKey='team2' stroke='#ffc658' />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProjectReport;
