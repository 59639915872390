import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { baseUrl } from "../BaseUrl";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { MdSpaceDashboard, MdAdd, MdClose } from "react-icons/md";
import {
    RiUserSettingsFill,
    RiFolderSettingsFill,
    RiCustomerServiceFill,
    RiFilePaper2Fill,
    RiLogoutCircleRLine,
} from "react-icons/ri";
import { setCredentials } from "../../services/auth/authSlice";
import bqp_logo from "../.././assets/images/bosonqpsi-logo.webp";
import { useLocalAuth } from "../../hooks/useLocalAuth";

const Sidebar = ({ active, user }) => {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { clearLocalStorage } = useLocalAuth();

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleLogout = () => {
        setShowConfirmation(true);
    };

    const confirmLogout = async () => {
        const loginType = localStorage.getItem("login-type");
        const logoutEndpoint =
            loginType === "admin" ? "tenant/logout" : "user/logout";
        const logoutUrl = `${baseUrl}${logoutEndpoint}`;
        dispatch(setCredentials({ jwt: null, agreement: false }));
        clearLocalStorage();
        navigate("/login");
        try {
            const response = await fetch(logoutUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            toast.success("You've successfully logged out");
            if (!response.ok) {
                throw new Error(`Logout failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    const cancelLogout = () => {
        setShowConfirmation(false);
    };
    const [current, setCurrent] = useState("dashboard");
    const makeSelection = (menu) => {
        setCurrent(menu);
        active(menu);
    };
    const handleWindowSizeChange = (width) => {
        if (width < 1150 && sidebarVisible) {
            setSidebarVisible(false);
        } else if (width >= 1150 && !sidebarVisible) {
            setSidebarVisible(true);
        }
    };
    useEffect(() => {
        handleWindowSizeChange(document.documentElement.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    window.addEventListener("resize", (e) =>
        handleWindowSizeChange(e.target.innerWidth),
    );
    return (
        <div className='bg-gray-900 w-min ' style={{ height: "100vh" }}>
            <div className='p-2 overflow-y-auto text-center flex flex-col space-y-2 h-full'>
                <div className='flex items-center gap-4 text-gray-100 text-xl'>
                    <div className='p-2.5 mt-1 flex items-center'>
                        <img
                            src={bqp_logo}
                            alt='Logo'
                            className='w-8 rounded-full'
                        />
                        {sidebarVisible && (
                            <h1 className='font-bold text-gray-200 text-sm ml-3 whitespace-nowrap'>
                                BosonQ Psi
                            </h1>
                        )}
                    </div>
                    {sidebarVisible && (
                        <div className='my-2 h-[1px]'>
                            <button
                                className='text-white text-4xl top-5 left-4 cursor-pointer'
                                onClick={toggleSidebar}
                            >
                                <FaCircleArrowLeft className='px-2 bg-gray-900 rounded-md' />
                            </button>
                        </div>
                    )}
                </div>

                {!sidebarVisible && (
                    <button
                        onClick={toggleSidebar}
                        className={`p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                    >
                        <FaCircleArrowLeft className='transform rotate-180 text-xl bg-gray-900 rounded-md' />
                    </button>
                )}
                <button
                    id='newProject'
                    onClick={() => makeSelection("newProject")}
                    className={`${
                        current === "newProject" && "bg-blue-600"
                    } p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                >
                    <MdAdd />
                    {sidebarVisible && (
                        <span className='text-xs ml-4 text-gray-200 font-bold whitespace-nowrap'>
                            New Project
                        </span>
                    )}
                </button>
                <button
                    id='dashboard'
                    onClick={() => makeSelection("dashboard")}
                    className={`${
                        current === "dashboard" && "bg-blue-600"
                    } p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                >
                    <MdSpaceDashboard />
                    {sidebarVisible && (
                        <span className='text-xs ml-4 text-gray-200 font-bold'>
                            Dashboard
                        </span>
                    )}
                </button>
                {user === "tenant" && (
                    <button
                        id='manageUser'
                        onClick={() => makeSelection("manageUser")}
                        className={`${
                            current === "manageUser" && "bg-blue-600"
                        } p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                    >
                        <RiUserSettingsFill />
                        {sidebarVisible && (
                            <span className='text-xs ml-4 text-gray-200 font-bold whitespace-nowrap'>
                                Manage User
                            </span>
                        )}
                    </button>
                )}
                <button
                    id='manageProjects'
                    onClick={() => makeSelection("manageProjects")}
                    className={`${
                        current === "manageProjects" && "bg-blue-600"
                    } p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                >
                    <RiFolderSettingsFill />
                    {sidebarVisible && (
                        <span className='text-xs ml-4 text-gray-200 font-bold whitespace-nowrap'>
                            Manage Projects
                        </span>
                    )}
                </button>
                <button
                    onClick={() => makeSelection("customerSupport")}
                    className={`${
                        current === "customerSupport" && "bg-blue-600"
                    } p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                >
                    <RiCustomerServiceFill />
                    {sidebarVisible && (
                        <span className='text-xs ml-4 text-gray-200 font-bold whitespace-nowrap'>
                            Customer Support
                        </span>
                    )}
                </button>
                <button
                    onClick={() => makeSelection("termsAndConditions")}
                    className={`${
                        current === "termsAndConditions" && "bg-blue-600"
                    } p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                >
                    <RiFilePaper2Fill />
                    {sidebarVisible && (
                        <span className='text-xs ml-4 text-gray-200 font-bold whitespace-nowrap'>
                            Terms and Conditions
                        </span>
                    )}
                </button>
                <button
                    onClick={handleLogout}
                    className={`p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white`}
                >
                    <RiLogoutCircleRLine />
                    {sidebarVisible && (
                        <span className='text-xs ml-4 text-gray-200 font-bold'>
                            Logout
                        </span>
                    )}
                </button>

                <div className='h-full flex justify-center items-end text-xs'>
                    <p className=' text-white opacity-50'>
                        {sidebarVisible ? "BQPhy : Version 1.0" : "V1.0"}
                    </p>
                </div>
            </div>

            {showConfirmation && (
                <div className='fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50'>
                    <div className='bg-white p-8 w-96 shadow-lg rounded-md'>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-xl font-bold'>
                                Logout Confirmation
                            </h2>
                            <button
                                onClick={cancelLogout}
                                className='text-gray-500 hover:text-gray-900'
                            >
                                <MdClose />
                            </button>
                        </div>
                        <p className='my-4'>Are you sure you want to logout?</p>
                        <div className='flex justify-end'>
                            <button
                                onClick={confirmLogout}
                                className='bg-iris-blue-500 text-white px-6 py-2 rounded-md hover:text-black hover:font-bold mr-4'
                            >
                                Yes
                            </button>
                            <button
                                onClick={cancelLogout}
                                className='border border-gray-300 bg-gray-200 px-6 py-2 rounded-md hover:bg-red-800 hover:text-white'
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

Sidebar.propTypes = {
    active: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
};

export default Sidebar;
