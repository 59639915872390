import { useState } from "react";
import { AiOutlineRedo } from "react-icons/ai";
import { GoProjectRoadmap } from "react-icons/go";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";

import { FaAngleDown } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import MiniCard from "../components/MiniCard";
import More from "./More";
import { getProjects } from "../Global/DashboardSlice";
import { useSelector } from "react-redux";
import { MdErrorOutline, MdNotStarted, MdPreview } from "react-icons/md";
import App from "../../../renderer/VTK/App";
import { baseUrl } from "../../BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";

const Status = ({ status }) => {
    let content;

    switch (status) {
        case "None":
        case "":
        case null:
        case undefined:
            content = (
                <div className='flex justify-start'>
                    <div
                        className='flex items-center justify-start gap-2 bg-gray-400 p-1 px-2 rounded-md'
                        title='🕐 Not Started'
                    >
                        <MdNotStarted className='text-sm text-white' />
                        <p className='text-white hidden md:block'>
                            Not Started
                        </p>
                    </div>
                </div>
            );
            break;
        case "finished":
            content = (
                <p className='whitespace-nowrap'>
                    ✅ <span className='hidden md:inline'>Completed</span>
                </p>
            );
            break;
        case "running":
            content = <p>🟢 Running</p>;
            break;
        case "failed":
            content = <p>🔴 Failed</p>;
            break;
        case "queue":
            content = <p>🕝 In-Queue</p>;
            break;
        default:
            content = <p>Status Unknown</p>;
            break;
    }

    return content;
};

const NewProject = () => {
    return (
        <button
            className=' flex justify-center overflow-hidden cursor-pointer'
            onClick={() => document.querySelector("#newProject").click()}
        >
            <div className='flex item items-center text-2xl px-4 py-2 rounded-md whitespace-nowrap justify-center w-min  gap-2 bg-black text-white'>
                <IoMdAddCircleOutline />
                <p className=' text-sm hidden lg:block'>New Project</p>
            </div>
        </button>
    );
};

const ManageProjects = () => {
    const projects = useSelector(getProjects);
    const [preview, setPreview] = useState(false);
    const [resultData, setResultData] = useState("");
  
    const fetchResultFile = async (e, projectId) => {
        e.target.style.opacity = "0.5";
        e.target.style.pointerEvents = "none";
        const user =
            localStorage.getItem("login-type") === "admin" ? "admin" : "";
        try {
            const generateDownloadUrlRequest = await axios.get(
                baseUrl + `${user}dashboardDownloadfile/${projectId}`,
                {
                    withCredentials: true,
                },
            );
            const headers = {
                "User-Agent": "beta-v1",
            };
            let data = generateDownloadUrlRequest.data.download_url;
            const checkExistenceOfFile = await axios.get(data, { headers });
            if (checkExistenceOfFile.status === 200) {
                setResultData(checkExistenceOfFile.data);
                setPreview(true);
            } else {
                toast.error("Result is not ready for visualization");
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Credential Error");
            } else if (error.response.status === 404) {
                toast.error("Result is not ready for visualization");
            } else {
                toast.error("Something went wrong");
            }
        } finally {
            e.target.style.opacity = "1";
            e.target.style.pointerEvents = "auto";
        }
    };
    // running -> Job is running
    // finished -> Job finished successfully
    // failed -> Job failed to complete
    // queue -> Job in queue

    const Result = (status, projectId) => {
        if (
            status === "None" ||
            status === "" ||
            status === null ||
            status === undefined
        )
            return "Not Available";
        if (status === "running") return "In Progress";
        if (status === "failed")
            return <p className=' text-red-400'>Not Available</p>;

        if (status === "finished")
            return (
                // if (status === "None" || status === "" || status === null || status === undefined) return (
                <>
                    <div className='flex justify-start'>
                        <div
                            className='flex items-center justify-start gap-2 bg-green-400 p-1 px-4 rounded-md shadow-md'
                            onClick={(e) => fetchResultFile(e, projectId)}
                            title='Preview the simulation'
                        >
                            <MdPreview className=' text-sm text-white pointer-events-none' />
                            <p className=' text-white hidden md:block pointer-events-none'>
                                Result
                            </p>
                        </div>
                    </div>
                </>
            );
        if (status === "queue")
            return <p className='text-yellow-400'>Not Available</p>;
    };

    return (
        <div className='flex flex-col overflow-hidden p-4'>
            {preview && (
                <div
                    className=' absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-200 h-4/5 w-5/6'
                    style={{
                        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                        zIndex: 999,
                    }}
                >
                    <App setPreview={setPreview} resultData={resultData} />
                </div>
            )}
            <div className='flex flex-wrap justify-between items-center'>
                {/* <Name /> */}
                <p></p>
                <div className='flex-wrap gap-4 items-center hidden sm:flex'>
                    <MiniCard
                        icon={<AiOutlineRedo className='h-full w-full' />}
                        title={
                            <p className=' text-sm'>
                                <span className='hidden lg:inline'>
                                    Currently{" "}
                                    {/* Noncompliant: ambiguous spacing */}
                                </span>
                                {/* Noncompliant: ambiguous spacing */}
                                Processing
                            </p>
                        }
                        value={<p>{projects.processing.length}</p>}
                        bg=' bg-green-100'
                    />
                    <MiniCard
                        icon={<GoProjectRoadmap className='h-full w-full' />}
                        title={
                            <p className=' text-sm'>
                                Projects{" "}
                                <span className='hidden lg:inline'>
                                    Created{" "}
                                </span>
                            </p>
                        }
                        value={<p>{projects.total.length}</p>}
                        bg=' bg-blue-200'
                    />
                    <MiniCard
                        icon={
                            <IoCheckmarkDoneCircleOutline className='h-full w-full' />
                        }
                        title={
                            <p className=' text-sm'>
                                Completed{" "}
                                <span className='hidden lg:inline'>
                                    Projects{" "}
                                </span>
                            </p>
                        }
                        value={<p>{projects.completed.length}</p>}
                        bg=' bg-red-100'
                    />
                    <MiniCard
                        icon={<MdErrorOutline className='h-full w-full' />}
                        title={
                            <p className=' text-sm'>
                                Failed{" "}
                                <span className='hidden lg:inline'>
                                    Projects{" "}
                                </span>
                            </p>
                        }
                        value={<p>{projects.failed.length}</p>}
                        bg=' bg-red-300'
                    />
                </div>
            </div>
            {!projects.total.length ? (
                <div className='flex flex-col items-center justify-center mt-10 gap-5'>
                    <h1>No project has been created into this account</h1>
                    <NewProject />
                </div>
            ) : (
                <div className='border border-gray rounded-xl mt-5 mx-4 h-full overflow-y-auto'>
                    <table className='border-collapse w-full text-sm table-fixed'>
                        <thead className=' bg-gray-200 sticky top-0 shadow-sm z-30'>
                            <tr>
                                <th className='border-b-2 font-medium p-4 md:pl-8 text-slate-400 text-left '>
                                    <div className='flex item items-center gap-2'>
                                        <p>Project Name </p>
                                        <FaAngleDown />
                                    </div>
                                </th>
                                {/* <th className="border-b-2 font-medium p-4 text-slate-400 text-left">
                        <div className='flex item items-center gap-2'>
                            <p>User ID</p>
                            <FaAngleDown />
                        </div>
                    </th> */}
                                <th className='border-b-2 font-medium p-4 text-slate-400 text-left'>
                                    <div className='flex item items-center gap-2'>
                                        <p>Status</p>
                                        <FaAngleDown />
                                    </div>
                                </th>
                                <th className='border-b-2 font-medium p-4 text-slate-400 text-left hidden sm:block'>
                                    <div className='flex item items-center gap-2'>
                                        <p>Projects Type</p>
                                        <FaAngleDown />
                                    </div>
                                </th>
                                <th className='border-b-2 font-medium p-4 text-slate-400 text-left'>
                                    <div className='flex item items-center gap-2'>
                                        <p>Result</p>
                                        <FaAngleDown />
                                    </div>
                                </th>
                                <th className='border-b-2'>
                                    <NewProject />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {projects.total.map((item) => (
                                <tr
                                    key={item.id}
                                    className={`project${item.id}`}
                                >
                                    <td className='border-b border-slate-100  p-4 md:pl-8 text-slate-500 overflow-scroll scrollbar-none'>
                                        {item.projectName}
                                    </td>
                                    <td className='border-b border-slate-100  p-4 md:pr-8 text-slate-500 overflow-scroll scrollbar-none'>
                                        <Status status={item.status} />
                                    </td>
                                    <td className='border-b border-slate-100 p-6 md:pr-8 text-slate-500 overflow-scroll scrollbar-none hidden sm:block'>
                                        {item.simulationType}
                                    </td>
                                    <td className='relative border-b border-slate-100  p-4 md:pr-8 text-slate-500 cursor-pointer overflow-scroll scrollbar-none'>
                                        {Result(item.status, item.id)}
                                    </td>

                                    <td className='border-b pr-2 border-slate-100 text-slate- overflow-scroll scrollbar-none'>
                                        <More item={item} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ManageProjects;
