import { useField } from "formik";

const FormikDoubleTextField = ({
    label1,
    label2,
    icon1: IconComponent1,
    icon2: IconComponent2,
    light,
    ...props
}) => {
    const [field1, meta1, helpers1] = useField(props.name1);
    const [field2, meta2, helpers2] = useField(props.name2);

    const value1 = field1.value != null ? field1.value : "";
    const value2 = field2.value != null ? field2.value : "";

    const labelColor = light ? "text-black" : "text-gray-200";
    const borderColor = light ? "border-gray-300" : "border-transparent";
    const focusBorderColor = light ? "border-gray-500" : "border-gray-600";
    const placeholderColor = light
        ? "placeholder-gray-500"
        : "placeholder-gray-400";

    const disabledClass = props.disabled1 ? "cursor-not-allowed" : "";

    return (
        <div className='relative mb-7 flex flex-col justify-between w-full md:flex-row'>
            <div>
                <label
                    htmlFor={props.id1 || props.name1}
                    className={`block text-sm mb-2 ${labelColor}`}
                >
                    {label1}
                </label>
                <div className='relative'>
                    {IconComponent1 && (
                        <IconComponent1 className='absolute left-3 top-2 w-6 h-6 text-black' />
                    )}
                    <input
                        type='text'
                        id={props.id1 || props.name1}
                        className={`bg-white bg-opacity-70 border ${borderColor} text-black text-sm rounded-lg focus:outline-none focus:${focusBorderColor} block pl-16 pr-8 py-2.5 ${placeholderColor} dark:bg-transparent dark:border-gray-600 dark:${placeholderColor} dark:focus:outline-none dark:focus:border-transparent ${disabledClass}`}
                        {...field1}
                        value={props.value1 ? props.value1 : value1}
                        onChange={(e) => helpers1.setValue(e.target.value)}
                        placeholder={props.placeholder1}
                        style={{
                            width: "100%",
                            fontSize: "normal",
                            boxShadow: "none",
                        }}
                        disabled={props.disabled}
                    />
                    {meta1.touched && meta1.error && (
                        <div className='absolute right-0 text-red-500 text-xs italic pt-1'>
                            {meta1.error}
                        </div>
                    )}
                </div>
            </div>
            <div className={`${props.hidden2 ? "hidden" : ""}`}>
                <label
                    htmlFor={props.id2 || props.name2}
                    className={`block text-sm mb-2 ${labelColor}`}
                >
                    {label2}
                </label>
                <div className='relative'>
                    {IconComponent2 && (
                        <IconComponent2 className='absolute left-3 top-2 w-6 h-6 text-black' />
                    )}
                    <input
                        type='text'
                        id={props.id2 || props.name2}
                        className={`bg-white bg-opacity-70 border ${borderColor} text-black text-sm rounded-lg focus:outline-none focus:${focusBorderColor} block pl-16 pr-8 py-2.5 ${placeholderColor} dark:bg-transparent dark:border-gray-600 dark:${placeholderColor} dark:focus:outline-none dark:focus:border-transparent ${disabledClass}`}
                        {...field2}
                        value={props.value2 ? props.value2 : value2}
                        onChange={(e) => helpers2.setValue(e.target.value)}
                        placeholder={props.placeholder2}
                        style={{
                            width: "100%",
                            fontSize: "normal",
                            boxShadow: "none",
                        }}
                        disabled={props.disabled2}
                    />
                    {meta2.touched && meta2.error && (
                        <div className='absolute right-0 text-red-500 text-xs italic pt-1'>
                            {meta2.error}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export { FormikDoubleTextField };
