import {
    optimizationConfig,
    updateOptimizationConfig,
} from "../../../../features/simulationWorksapce/SolverInputSlice";
import PropTypes from "prop-types";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BiMinus, BiPlus } from "react-icons/bi";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { baseUrl, caeUrl } from "../../../../routes/BaseUrl";
import {
    getProjects,
    updateProjects,
} from "../../../../routes/Dashboard/Global/DashboardSlice";
import AdvanceOpt from "../../Extras/AdvanceOpt";
import { Converter } from "../../Extras/jsonConverter";
import Cookies from "js-cookie";

const jwt = JSON.parse(localStorage.getItem("tokenBQP"));
const tokenExpiry = jwt?.exp;
const currentTime = Math.floor(Date.now() / 1000);

if (tokenExpiry && currentTime > tokenExpiry) {
    toast.error("Session expired, please log in again.");
}

const ObjectiveFn = ({ values, showFor, boxCount }) => {
    const { setFieldValue } = useFormikContext();

    const getActive = (fn) => {
        if (fn === "compliance") {
            return "Volume Fraction";
        }
        if (fn === "volume") {
            return "Displacement";
        }
        if (fn === "mutualStrainEnergy") {
            return "Volume Fraction";
        }
    };

    const [active, setActive] = useState(getActive(showFor));

    useEffect(() => {
        const currentCType = getActive(showFor);
        setActive(currentCType);
        setFieldValue(`constraintType.${boxCount}`, currentCType);
    }, [showFor, boxCount, setFieldValue]);

    useEffect(() => {
        return () => {
            setFieldValue(`compliance.${boxCount}`, undefined);
            setFieldValue(`volume.${boxCount}`, undefined);
            setFieldValue(`displacement.${boxCount}`, undefined);
        };
    }, [active, boxCount, setFieldValue]);

    const handleSelectChange = (value) => {
        setFieldValue(`constraintType.${boxCount}`, value);
        setActive(value);
    };

    return (
        <div className='text-black rounded-md p-1 flex justify-between gap-2 bg-blue-100'>
            <div className='flex flex-col gap-2'>
                <label htmlFor='constraintType' className=' text-sm'>
                    Constraint Type
                </label>
                <Field
                    as='select'
                    name={`constraintType.${boxCount}`}
                    className='w-48 px-2 py-1 rounded-md border border-gray-500 text-sm'
                    value={active}
                    id='constraintType'
                    onChange={(e) => handleSelectChange(e.target.value)}
                >
                    {
                        {
                            compliance: (
                                <>
                                    <option value='Volume Fraction'>
                                        Volume Fraction
                                    </option>
                                    <option value='Displacement'>
                                        Displacement
                                    </option>
                                </>
                            ),
                            volume: (
                                <>
                                    <option value='Displacement'>
                                        Displacement
                                    </option>
                                    <option value='compliance'>
                                        Compliance
                                    </option>
                                </>
                            ),
                            mutualStrainEnergy: (
                                <option value='Volume Fraction'>
                                    Volume Fraction
                                </option>
                            ),
                        }[showFor]
                    }
                </Field>
            </div>

            {
                {
                    compliance: (
                        <div className='flex flex-col justify-between items-end gap-2'>
                            <label
                                htmlFor={`constraintCompliance.${boxCount}`}
                                className=' text-sm'
                            >
                                Constraint Value
                            </label>
                            <div className=' flex items-center gap-2'>
                                <div className='text-xs text-red-400'>
                                    <ErrorMessage
                                        className=''
                                        name={`compliance.${boxCount}`}
                                        component='div'
                                    />
                                </div>
                                <Field
                                    className='w-40 px-2 py-1 rounded-md border border-gray-500 text-sm'
                                    type='text'
                                    value={values.compliance[boxCount] || ""}
                                    id={`constraintCompliance.${boxCount}`}
                                    name={`compliance.${boxCount}`}
                                    required
                                />
                            </div>
                        </div>
                    ),
                    "Volume Fraction": (
                        <div className=' flex flex-col items-end gap-2'>
                            <label
                                htmlFor={`constraintVolume.${boxCount}`}
                                className=' text-sm'
                            >
                                Constraint Value
                            </label>
                            <div className=' flex items-center gap-2'>
                                <div className='text-xs text-red-400'>
                                    <ErrorMessage
                                        className=''
                                        name={`volume.${boxCount}`}
                                        component='div'
                                    />
                                </div>
                                <Field
                                    className='w-40 px-2 py-1 rounded-md border border-gray-500 text-sm'
                                    type='text'
                                    value={values.volume[boxCount] || ""}
                                    id={`constraintVolume.${boxCount}`}
                                    name={`volume.${boxCount}`}
                                    step='0.1'
                                    required
                                />
                            </div>
                        </div>
                    ),
                    Displacement: (
                        <div className=' flex flex-col items-end gap-2'>
                            <label
                                htmlFor={`constraintDisplacement.${boxCount}`}
                                className=' text-sm'
                            >
                                Constraint Value
                            </label>
                            <div className=' flex items-center gap-2'>
                                <div className='text-xs text-red-400'>
                                    <ErrorMessage
                                        className=''
                                        name={`displacement.${boxCount}`}
                                        component='div'
                                    />
                                </div>
                                <Field
                                    className='w-40 px-2 py-1 rounded-md border border-gray-500 text-sm'
                                    type='text'
                                    value={values.displacement[boxCount] || ""}
                                    id={`constraintDisplacement.${boxCount}`}
                                    name={`displacement.${boxCount}`}
                                    required
                                />
                            </div>
                        </div>
                    ),
                }[active]
            }
        </div>
    );
};

const OptimizationConfigs = ({ project, initData }) => {
    const dispatch = useDispatch();
    // console.log(initData)
    const totalProjects = useSelector(getProjects).total;
    const [status, setStatus] = useState("Run Simulation");
    const optimizationConfigState = useSelector(optimizationConfig);

    useEffect(() => {
        dispatch(updateProjects(totalProjects));
    }, [totalProjects, dispatch]);

    const dispatchValues = (val) => {
        const {
            objectiveFunction,
            constraints,
            constraintType,
            volume,
            k_in,
            k_out,
            generations,
            filterRadius,
            displacement,
            compliance,
        } = val;

        dispatch(
            updateOptimizationConfig({
                objectiveFunction,
                constraints,
                constraintType,
                volume,
                k_in,
                k_out,
                generations,
                filterRadius,
                displacement,
                compliance,
            }),
        );
    };
    let isAdmin = localStorage.getItem("selected-tab");
    const handleClose = async () => {
        document.getElementById("dashboard").click();
        dispatch(updateProjects(totalProjects.slice(0, -1)));
        if (project.id) {
            try {
                await axios.delete(
                    baseUrl +
                        `${
                            isAdmin === "admin" || isAdmin === null
                                ? "admincrud/"
                                : "crud/"
                        }${project.id}/`,
                    {
                        withCredentials: true,
                    },
                );
            } catch (error) {
                console.log("Project not deleted after canceled");
            }
        } else {
        }
    };

    let user = localStorage.getItem("login-type");
    user = user === "admin" ? "tenant" : "user";

    async function fetchProjects() {
        try {
            const response = await axios.get(
                baseUrl + `${user === "tenant" ? "adminjobid" : "jobid"}`,
                {
                    withCredentials: true,
                },
            );

            if (response.status === 200) {
                dispatch(
                    updateProjects(
                        response.data.filter(
                            (obj) => Object.keys(obj).length !== 0,
                        ),
                    ),
                );
                document.querySelector("#manageProjects").click();
            } else {
                toast.error("Unexpected response from the server.");
            }
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 401) {
                    toast.error(
                        data.detail ||
                            "Authentication error. Please log in again.",
                    );
                } else if (status === 403) {
                    toast.error(
                        data.detail ||
                            "Permission error. You might not have access.",
                    );
                } else if (status === 500) {
                    toast.error(
                        data.detail || "Server error. Please try again later.",
                    );
                } else {
                    toast.error("An unexpected error occurred.");
                }
            } else {
                toast.error("Network error. Please check your connection.");
            }
            console.error(
                "Unable to load user projects! (Server error)",
                error,
            );
        }
    }

    const resetValues = (val) => {
        let objectiveFunction = val.objectiveFunction && "compliance",
            constraints = val.constraints || 1,
            constraintType = val.constraintType || [],
            volume = val.volume || [0.5],
            k_in = val.k_in || 0.01,
            k_out = val.k_out || 0.01,
            generations = val.generations || 20,
            filterRadius = val.filterRadius || 1.5,
            displacement = val.displacement || [],
            compliance = val.compliance || [];

        dispatch(
            updateOptimizationConfig({
                objectiveFunction,
                constraints,
                constraintType,
                volume,
                k_in,
                k_out,
                generations,
                filterRadius,
                displacement,
                compliance,
            }),
        );
    };
    const validationSchema = Yup.object().shape({
        volume: Yup.array().of(
            Yup.number()
                .typeError("be ranging between 0.1 to 0.95")
                .min(0.1, "The entered volume fraction is too low")
                .max(
                    0.95,
                    "The entered volume fraction is almost equal to solid",
                ),
        ),
        displacement: Yup.array().of(
            Yup.number().typeError("be ranging between -Inf to +Inf"),
        ),
        compliance: Yup.array().of(
            Yup.number().typeError("be ranging between -Inf to +Inf"),
        ),
        generations: Yup.number()
            .typeError("be ranging between 1 to 200")
            .min(1, "Generations must be ranging between 1 to 200")
            .max(200, "Reached maximum Generation"),
        filterRadius: Yup.number()
            .typeError("Filter Radius be ranging between 0 to +Inf")
            .min(0, "Negative value not allowed"),
        k_in: Yup.number()
            .typeError("be ranging between 0 to +Inf")
            .min(0, "The entered value is too low"),
        k_out: Yup.number()
            .typeError("be ranging between 0 to +Inf")
            .min(0, "The entered value is too low"),
    });
    return (
        <>
            {/* {console.log("State", optimizationConfigState)} */}
            <Formik
                initialValues={{
                    objectiveFunction:
                        optimizationConfigState.objectiveFunction,
                    constraints: optimizationConfigState.constraints,
                    constraintType: optimizationConfigState.constraintType,
                    // volumeFraction
                    volume: optimizationConfigState.volume,
                    // for displacement
                    k_in: initData.kin,
                    k_out: initData.kout,
                    generations: optimizationConfigState.generations,
                    filterRadius: optimizationConfigState.filterRadius,
                    displacement: optimizationConfigState.displacement,
                    // for compliance
                    compliance: optimizationConfigState.compliance,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    dispatchValues(values);
                    setStatus("Uploading Config.");

                    let user = localStorage.getItem("login-type");
                    let endPoint =
                        user === "admin"
                            ? "adminproblemstatement"
                            : "problemstatement";
                    let processedData = Converter(values, project);
                    try {
                        await axios.post(baseUrl + endPoint, processedData, {
                            withCredentials: true,
                        });
                        setStatus("Simulation Started.");
                        try {
                            let jwt = localStorage.getItem("tokenBQP");
                            jwt = jwt
                                ? JSON.parse(jwt)["jwt"]
                                : Cookies.get("bqp-check");

                            if (jwt) {
                                const response = await fetch(
                                    `${caeUrl}run-job`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Accept: "application/json",
                                            Authorization: jwt,
                                            "API-Origin": `${baseUrl}`,
                                        },
                                        body: JSON.stringify({
                                            projectid: project.id,
                                            accounttype:
                                                localStorage.getItem(
                                                    "login-type",
                                                ),
                                        }),
                                    },
                                );
                                const data = await response.json();
                                if (response.ok) {
                                    if (response.status === 200) {
                                        toast.success(
                                            "Simulation Started Successfully",
                                        );
                                    } else if (response.status === 429) {
                                        toast.error(
                                            "Queue is full! Try again later.",
                                        );
                                    }
                                    fetchProjects();
                                } else {
                                    if (response.status === 400) {
                                        toast.error(
                                            `Server error: ${data.detail}`,
                                        );
                                    } else if (response.status === 401) {
                                        toast.error(
                                            `Authentication error: ${data.detail}`,
                                        );
                                    } else if (response.status === 403) {
                                        toast.error(
                                            `Permission error: ${data.detail}`,
                                        );
                                    } else if (response.status === 500) {
                                        toast.error(
                                            `Server error: ${data.detail}`,
                                        );
                                    } else {
                                        toast.error(
                                            "Unexpected error occurred",
                                        );
                                    }
                                }
                            } else {
                                toast.error("Authorization token is missing.");
                                return;
                            }
                        } catch (error) {
                            toast.error(
                                "Server is busy, please try again later...",
                            );
                            console.error("Error:", error);
                        }
                    } catch (error) {
                        if (error.response && error.response.status === 400) {
                            toast.error(
                                "Server is not available. Please try again later.",
                            );
                            console.log(error.response.data);
                        } else if (
                            error.response &&
                            error.response.status === 429
                        ) {
                            fetchProjects();
                            toast.success("Simulation is in queue.");
                        } else {
                            toast.error("Server error");
                        }
                    } finally {
                        setStatus("Run Simulation");
                    }
                }}
            >
                {({ values, resetForm, setFieldValue }) => (
                    <Form className='flex flex-col gap-2 justify-between h-full rounded-lg'>
                        <p className=' text-lg font-bold pb-1'>
                            Topology Optimization
                        </p>
                        <hr />
                        {/* {console.log(values)} */}
                        {/* <h1 className=" text-center text-xl pb-4 border-b-2 border-white whitespace-nowrap">Topology Optimization</h1> */}
                        <div className='flex items-center justify-between w-full'>
                            <label
                                htmlFor='objectiveFunction'
                                className='font-medium whitespace-nowrap text-sm'
                            >
                                Objective Function
                            </label>
                            <Field
                                as='select'
                                name='objectiveFunction'
                                id='objectiveFunction'
                                onChange={(e) => {
                                    const value = e.target.value;
                                    resetForm();
                                    resetValues({ objectiveFunction: value });
                                    setFieldValue("objectiveFunction", value);
                                }}
                                className=' w-52 px-2 py-1 rounded-md border text-black border-gray-500 text-sm'
                            >
                                <option value='compliance'>Compliance</option>
                                <option value='volume'>Volume</option>
                                <option value='mutualStrainEnergy'>
                                    Mutual Strain Energy
                                </option>
                            </Field>
                            <div className=' hidden flex-col items-start justify-between '>
                                <label htmlFor='constraints'>Constraints</label>
                                <Field
                                    className='w-20 px-2 text-black py-1 rounded-md border border-gray-500'
                                    type='number'
                                    id='constraints'
                                    name='constraints'
                                />
                            </div>
                        </div>
                        <div className='rounded-md overflow-y-scroll h-full scrollbar-none'>
                            <div className='flex flex-col gap-2 '>
                                {Array.apply(null, {
                                    length: values.constraints,
                                }).map((e, i) => (
                                    <ObjectiveFn
                                        key={i}
                                        values={values}
                                        showFor={values.objectiveFunction}
                                        boxCount={i}
                                    />
                                ))}
                            </div>
                            <div className=' w-full flex justify-center items-center gap-5 mt-2'>
                                {
                                    // values.constraints !==5 &&
                                    <BiPlus
                                        className='hidden text-4xl p-2 rounded-lg cursor-pointer shadow-inner bg-green-400'
                                        onClick={() =>
                                            setFieldValue(
                                                "constraints",
                                                values.constraints + 1,
                                            )
                                        }
                                    />
                                }
                                {values.constraints > 1 && (
                                    <BiMinus
                                        className='hidden text-4xl p-2 rounded-lg cursor-pointer shadow-inner bg-gray-400'
                                        onClick={() =>
                                            setFieldValue(
                                                "constraints",
                                                values.constraints - 1,
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className='flex justify-between'>
                            <div className='flex flex-col text-sm gap-2'>
                                <label htmlFor='filterRadius'>
                                    Filter Radius
                                </label>
                                <Field
                                    className='w-40 px-2 py-1 rounded-md border border-gray-500'
                                    type='text'
                                    id='filterRadius'
                                    name='filterRadius'
                                    required
                                />
                            </div>
                            <div className='text-xs text-red-400 flex flex-col items-center justify-end'>
                                <ErrorMessage
                                    className=''
                                    name='generations'
                                    component='div'
                                />
                                <ErrorMessage
                                    className=''
                                    name='filterRadius'
                                    component='div'
                                />
                            </div>
                            <div className='flex flex-col text-sm gap-2'>
                                <label htmlFor='generations'>
                                    Number of Generations
                                </label>
                                <Field
                                    className='w-40 px-2 py-1 rounded-md border border-gray-500'
                                    type='text'
                                    id='generations'
                                    name='generations'
                                    required
                                />
                            </div>
                        </div>
                        <hr />
                        <AdvanceOpt showFor={values.objectiveFunction} />
                        <div className=' w-full flex flex-row-reverse gap-5 justify-center'>
                            <button
                                type='submit'
                                className={` ${
                                    status !== "Run Simulation"
                                        ? "pointer-events-none cursor-wait px-2 opacity-60"
                                        : ""
                                } w-48 flex justify-center cursor-pointer rounded-md transition focus-within:hover:bg-iris-blue-600 bg-iris-blue-500`}
                            >
                                <div className='flex gap-3 p-2 items-center text-white'>
                                    {status === "Run Simulation" && (
                                        <FaCloudUploadAlt className=' text-sm' />
                                    )}
                                    <p className=' whitespace-nowrap'>
                                        {status}
                                    </p>
                                </div>
                            </button>
                            {status !== "Simulation Started." ? (
                                <button
                                    onClick={handleClose}
                                    type='reset'
                                    className='px-16 py-2 text-sm bg-gray-200 text-gray-700 w-40 rounded-md hover:bg-gray-300'
                                >
                                    Close
                                </button>
                            ) : (
                                <button className=' pointer-events-none px-16 py-2 text-sm bg-gray-100 text-gray-700 w-40 rounded-md'>
                                    Redirecting...
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

ObjectiveFn.propTypes = {
    values: PropTypes.object.isRequired,
    showFor: PropTypes.string.isRequired,
    boxCount: PropTypes.number.isRequired,
};

export default OptimizationConfigs;
