import PropTypes from "prop-types";
import { FiUser, FiBell } from "react-icons/fi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../services/auth/authSlice";
import { useLocalAuth } from "../../hooks/useLocalAuth";
import { toast } from "react-hot-toast";
import { MdClose } from "react-icons/md";
import { baseUrl } from "../BaseUrl";
import NotificationPopup from "./NotificationPopup";
import axios from "axios";
import { updateProjects } from "./Global/DashboardSlice";

function Navbar({ onAccountDetails }) {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();
    let user = localStorage.getItem("login-type");
    user = user === "admin" ? "admin" : "user";
    const navigate = useNavigate();
    const { clearLocalStorage } = useLocalAuth();

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
        if (!dropdownVisible) {
            setNotificationVisible(false);
        }
    };

    const toggleNotification = () => {
        setNotificationVisible(!notificationVisible);
        if (!notificationVisible) {
            setDropdownVisible(false);
        }
    };

    const handleLogout = () => {
        setDropdownVisible(false);
        setShowLogoutConfirmation(true);
    };

    const confirmLogout = async () => {
        const loginType = localStorage.getItem("login-type");
        const logoutEndpoint =
            loginType === "admin" ? "tenant/logout" : "user/logout";
        const logoutUrl = `${baseUrl}${logoutEndpoint}`;

        dispatch(setCredentials({ jwt: null, agreement: false }));
        clearLocalStorage();
        navigate("/login");
        try {
            const response = await fetch(logoutUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error(`Logout failed: ${response.statusText}`);
            }
            toast.success("You've successfully logged out");
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    const cancelLogout = () => {
        setShowLogoutConfirmation(false);
    };

    useEffect(() => {
        let dropdownTimeoutId;
        if (dropdownVisible) {
            dropdownTimeoutId = setTimeout(() => {
                setDropdownVisible(false);
            }, 3000);
        }
        return () => {
            clearTimeout(dropdownTimeoutId);
        };
    }, [dropdownVisible]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const url =
                    baseUrl + (user === "admin" ? "adminjobid" : "jobid");
                const response = await axios.get(url, {
                    withCredentials: true,
                });

                if (response.status === 200) {
                    const data = response.data;
                    const unreadNotifications = data.filter(
                        (obj) =>
                            !obj.is_read &&
                            (obj.status === "finished" ||
                                obj.status === "failed"),
                    );
                    setNotifications(unreadNotifications);
                    const nonEmptyProjects = data.filter(
                        (obj) => Object.keys(obj).length !== 0,
                    );
                    dispatch(updateProjects(nonEmptyProjects));
                } else {
                    toast.error("Unexpected response from the server.");
                }
            } catch (error) {
                handleError(error);
            }
        };

        const handleError = (error) => {
            if (error.response) {
                const { status, data } = error.response;
                switch (status) {
                    case 401:
                        toast.error(
                            data.detail ||
                                "Authentication error. Please log in again.",
                        );
                        break;
                    case 403:
                        toast.error(
                            data.detail ||
                                "Permission error. You might not have access.",
                        );
                        break;
                    case 500:
                        toast.error(
                            data.detail ||
                                "Server error. Please try again later.",
                        );
                        break;
                    default:
                        toast.error("An unexpected error occurred.");
                }
            } else {
                toast.error("Network error. Please check your connection.");
            }
            console.error("Problem in refreshing the project...", error);
        };

        fetchProjects();
        const intervalId = setInterval(fetchProjects, 1000 * 60 * 15);

        return () => clearInterval(intervalId);
    }, [user, dispatch, setNotifications]);

    const handleAccountClick = () => {
        setDropdownVisible(false);
        onAccountDetails();
    };
    return (
        <div className='bg-white shadow-md'>
            <div className='flex justify-between items-center h-14 mx-auto px-4'>
                <div></div>
                <div className='hidden lg:block w-96 relative'>
                    <h1 className='text-xl font-medium m-6'>
                        WELCOME TO BQPhy!
                    </h1>
                </div>
                <div className='flex items-center space-x-6'>
                    <div className='relative'>
                        <FiBell
                            className='h-6 w-6 hover:text-gray-500 cursor-pointer'
                            onClick={toggleNotification}
                        />
                        <p className='absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-indigo-600 text-white font-semibold px-2 py-0.5 text-xs rounded-full'>
                            {notifications.length}
                        </p>
                        <NotificationPopup
                            visible={notificationVisible}
                            onClose={toggleNotification}
                            notifications={notifications}
                            setNotifications={setNotifications}
                        />
                    </div>
                    <div className='relative'>
                        <FiUser
                            className='h-7 w-7 rounded-full cursor-pointer hover:text-gray-500'
                            onClick={toggleDropdown}
                        />
                        {dropdownVisible && (
                            <div className='absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50'>
                                <button
                                    className='block px-4 py-2 text-sm rounded-md text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer w-full'
                                    onClick={handleAccountClick}
                                >
                                    Account Details
                                </button>
                                <button
                                    onClick={handleLogout}
                                    className='block px-4 text-sm rounded-md py-2 text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer w-full'
                                >
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showLogoutConfirmation && (
                <div className='fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50'>
                    <div className='bg-white p-8 w-96 shadow-lg rounded-md'>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-xl font-bold'>
                                Logout Confirmation
                            </h2>
                            <button
                                onClick={cancelLogout}
                                className='text-gray-500 hover:text-gray-900'
                            >
                                <MdClose />
                            </button>
                        </div>
                        <p className='my-4'>Are you sure you want to logout?</p>
                        <div className='flex justify-end'>
                            <button
                                onClick={confirmLogout}
                                className='bg-iris-blue-500 text-white px-6 py-2 rounded-md hover:text-black hover:font-bold mr-4'
                            >
                                Yes
                            </button>
                            <button
                                onClick={cancelLogout}
                                className='border border-gray-300 bg-gray-200 px-6 py-2 rounded-md hover:bg-red-800 hover:text-white'
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

Navbar.propTypes = {
    onAccountDetails: PropTypes.func.isRequired,
};

export default Navbar;
