
import { TenantSignUp } from "./TenantSignup";

const SignUpWidget = () => {
    return (
        <>
            <div className='flex items-center justify-center'>
                <TenantSignUp />
            </div>
        </>
    );
};

export { SignUpWidget };
