const PrivacyPolicy = () => {
    return (
        <div className='text-gray-200 m-4 max-h-64 overflow-y-auto'>
            <h2 className='text-lg font-semibold mb-2'>Privacy Policy</h2>
            <p>
                This Privacy Policy outlines how [Your Company Name] ("we,"
                "our," or "us") collects, uses, maintains, and discloses
                information collected during your participation in our product
                demonstration ("Demo"). This policy applies to the Demo and all
                related activities.
            </p>
            <h3 className='text-lg font-semibold mt-4 mb-2'>
                Information We Collect:
            </h3>
            <p>
                <strong>Personal Information:</strong> During the Demo, we may
                collect personal information, such as your name, contact
                details, and company information.
            </p>
            <p>
                <strong>Usage Data:</strong> We may collect information on how
                you interact with the Demo, including but not limited to, pages
                viewed, features used, and time spent.
            </p>
            <p>
                <strong>Feedback and Communication:</strong> Any feedback or
                communication you provide during or after the Demo may be
                collected and stored.
            </p>
            <h3 className='text-lg font-semibold mt-4 mb-2'>
                How We Use Collected Information:
            </h3>
            <p>
                <strong>Demo Improvement:</strong> Collected information may be
                used to analyze and improve the functionality and performance of
                the Demo.
            </p>
            <p>
                <strong>Communication:</strong> We may use the provided contact
                information to communicate with you regarding the Demo and
                related services.
            </p>
            <p>
                <strong>Feedback Utilization:</strong> Feedback and suggestions
                may be used for the enhancement of our products and services.
            </p>
            <h3 className='text-lg font-semibold mt-4 mb-2'>Data Security:</h3>
            <p>
                <strong>Confidentiality:</strong> We treat all information
                collected during the Demo as confidential and take appropriate
                measures to protect it.
            </p>
            <p>
                <strong>Third-Party Access:</strong> We do not sell, trade, or
                rent your personal information to third parties.
            </p>
            <h3 className='text-lg font-semibold mt-4 mb-2'>Your Choices:</h3>
            <p>
                <strong>Opt-out:</strong> You may choose not to participate in
                the Demo if you do not agree with the terms outlined in the Demo
                Privacy Policy.
            </p>
            <p>
                <strong>Access and Correction:</strong> You may request access
                to your personal information, and, if necessary, corrections can
                be made.
            </p>
            <h3 className='text-lg font-semibold mt-4 mb-2'>
                Changes to this Privacy Policy:
            </h3>
            <p>
                <strong>Updates:</strong> We reserve the right to update this
                Privacy Policy at any time. We encourage you to check this page
                periodically for any changes.
            </p>
            <h3 className='text-lg font-semibold mt-4 mb-2'>Contact Us:</h3>
            <p>
                If you have any questions about this Privacy Policy, please
                contact us at [Your Contact Information].
            </p>
            <p className='mt-4'>
                By participating in the Demo, you signify your acceptance of
                this Privacy Policy. If you do not agree with the terms outlined
                here, please refrain from participating in the Demo.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
