import InfoBtn from "./InfoBtn";
import { BiSortDown } from "react-icons/bi";
import { ErrorMessage, Field } from "formik";

const AdvanceOpt = ({ showFor }) => {
    // const [advance, setAdvance] = useState(false)
    return (
        <>
            {showFor === "mutualStrainEnergy" && (
                <div>
                    <div className='flex gap-4 items-center'>
                        <div className=' py-2 px-4 bg-gray-100 rounded-md flex items-center gap-2'>
                            <span>
                                <BiSortDown />
                            </span>
                            <p className=' text-sm'>Advanced Settings</p>
                        </div>
                        <InfoBtn />
                    </div>
                    <div className=' flex flex-col gap-3 mt-4 mb-2 ml-4'>
                        <div className='flex justify-between '>
                            <label htmlFor='k_in'>Input Spring Constant</label>
                            <div className='flex items-center gap-4'>
                                <div className='text-sm text-red-400'>
                                    <ErrorMessage
                                        className=''
                                        name='k_in'
                                        component='div'
                                    />
                                </div>
                                <Field
                                    className=' w-20 px-2 text-black py-1 rounded-md border border-gray-500 '
                                    type='text'
                                    id='k_in'
                                    name='k_in'
                                    min='0.00'
                                />
                            </div>
                        </div>

                        <div className='flex justify-between '>
                            <label htmlFor='k_out'>
                                Output Spring Constant
                            </label>
                            <div className='flex items-center gap-4'>
                                <div className='text-sm text-red-400'>
                                    <ErrorMessage
                                        className=''
                                        name='k_out'
                                        component='div'
                                    />
                                </div>
                                <Field
                                    className=' w-20 px-2 text-black py-1 rounded-md border border-gray-500 '
                                    type='text'
                                    id='k_out'
                                    name='k_out'
                                    min='0.00'
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            )}
        </>
    );
};

export default AdvanceOpt;
