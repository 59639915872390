const checkNull = (val) => {
    if (isNaN(val)) {
        return 0;
    }
    return val;
};

const constraintType = (val) => {
    if (val === "Volume Fraction") {
        return "Volume";
    }
    if (val === "Displacement") {
        return "Displacement";
    }
    if (val === "compliance") {
        return "Compliance";
    }
};

const objectiveFunction = (val) => {
    if (val === "compliance") {
        return "Compliance";
    }
    if (val === "volume") {
        return "Volume";
    }
    if (val === "mutualStrainEnergy") {
        return "Displacement";
    }
};

export const Converter = (rawData, projectData) => {
    let format = {
        problemstatement: [
            {
                name: projectData.projectName,
                ObjectiveFunction: objectiveFunction(rawData.objectiveFunction),
                ConstraintType: constraintType(rawData.constraintType[0]),
            },
        ],
        constraintConfig: [
            {
                volumeFraction: checkNull(parseFloat(rawData.volume[0])),
                maxMeanCompliance: checkNull(parseFloat(rawData.compliance[0])),
                maxDisplacement: checkNull(parseFloat(rawData.displacement[0])),
            },
        ],
        QIDOConfig: [
            {
                MaxGenerations: parseInt(rawData.generations),
            },
        ],
        filterConfig: [
            {
                filterRadius: parseFloat(rawData.filterRadius),
            },
        ],
        advancedSettings: [
            {
                kin: rawData.k_in,
                kout: rawData.k_in,
            },
        ],
    };
    return format;
};
