import { FiUser, FiBell } from "react-icons/fi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../services/auth/authSlice";
import { useLocalAuth } from "../../../hooks/useLocalAuth";
import { toast } from "react-hot-toast";
import { MdClose } from "react-icons/md";
import { baseUrl } from "../../../routes/BaseUrl";
import MasterNotificationToggle from "./MasterNotificationToggle";

const notifications = [
    // {
    //   id: 1,
    //   title: "Notification 1",
    //   description: "This is the description for notification 1.",
    // },
    // {
    //   id: 2,
    //   title: "Notification 2",
    //   description: "This is the description for notification 2.",
    // },
];

function MasterNavbar({ onAccountDetails }) {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
        if (notificationVisible) {
            setNotificationVisible(false);
        }
    };

    const toggleNotification = () => {
        setNotificationVisible(!notificationVisible);
        if (dropdownVisible) {
            setDropdownVisible(false);
        }
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clearLocalStorage } = useLocalAuth();

    const handleLogout = () => {
        setShowLogoutConfirmation(true);
    };

    const confirmLogout = async () => {
        dispatch(setCredentials({ jwt: null, agreement: false }));
        clearLocalStorage();
        navigate("/master/login");
        toast.success("You've successfully logged out");
        setShowLogoutConfirmation(false);
        try {
            const response = await fetch(baseUrl + "client/logout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`Logout failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    const cancelLogout = () => {
        setShowLogoutConfirmation(false);
    };

    useEffect(() => {
        let dropdownTimeoutId;
        if (dropdownVisible) {
            dropdownTimeoutId = setTimeout(() => {
                setDropdownVisible(false);
            }, 3000);
        }
        return () => {
            clearTimeout(dropdownTimeoutId);
        };
    }, [dropdownVisible]);

    return (
        <div className='bg-white shadow-md'>
            <div className='flex justify-between items-center h-20 mx-auto px-4'>
                <div></div>
                <div className='hidden lg:block w-96 relative'>
                    {/* <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <FiSearch className='text-gray-400' />
          </div>
          <input
            type='text'
            placeholder='Type to search'
            className='w-full border border-gray-300 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm rounded-lg pl-10 py-3'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          /> */}
                    <h1 className='text-2xl font-medium m-6'>
                        WELCOME TO BQPhy!
                    </h1>
                </div>
                <div className='flex items-center space-x-6'>
                    <div className='relative'>
                        <FiBell
                            className='h-6 w-6 hover:text-gray-500 cursor-pointer'
                            onClick={toggleNotification}
                        />
                        <p className='absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-indigo-600 text-white font-semibold px-2 py-0.5 text-xs rounded-full'>
                            {notifications.length}
                        </p>
                        {notificationVisible && (
                            <MasterNotificationToggle
                                visible={notificationVisible}
                                onClose={toggleNotification}
                                notifications={notifications}
                            />
                        )}
                    </div>
                    <div className='relative'>
                        <FiUser
                            className='h-8 w-8 rounded-full cursor-pointer hover:text-gray-500'
                            onClick={toggleDropdown}
                        />
                        {dropdownVisible && (
                            <div className='absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1'>
                                <div
                                    className='block px-4 py-2 text-sm text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer'
                                    onClick={onAccountDetails}
                                >
                                    Account Details
                                </div>
                                <div
                                    onClick={handleLogout}
                                    className='block px-4 py-2 text-sm text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer'
                                >
                                    Logout
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showLogoutConfirmation && (
                <div className='fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50'>
                    <div className='bg-white p-8 w-96 shadow-lg rounded-md'>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-xl font-bold'>
                                Logout Confirmation
                            </h2>
                            <button
                                onClick={cancelLogout}
                                className='text-gray-500 hover:text-gray-900'
                            >
                                <MdClose />
                            </button>
                        </div>
                        <p className='my-4'>Are you sure you want to logout?</p>
                        <div className='flex justify-end'>
                            <button
                                onClick={confirmLogout}
                                className='bg-iris-blue-500 text-white px-6 py-2 rounded-md hover:text-black hover:font-bold mr-4'
                            >
                                Yes
                            </button>
                            <button
                                onClick={cancelLogout}
                                className='border border-gray-300 bg-gray-200 px-6 py-2 rounded-md hover:bg-red-800 hover:text-white'
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MasterNavbar;
