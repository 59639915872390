import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AuthState = { jwt: string | null; agreement: boolean | undefined };

export const AuthSlice = createSlice({
    name: "auth",
    initialState: { jwt: null, agreement: undefined } as AuthState,
    reducers: {
        setCredentials: (
            state: any,
            {
                payload: { jwt, agreement },
            }: PayloadAction<{ jwt: string | null; agreement: boolean }>,
        ) => {
            state.jwt = jwt;
            if (agreement !== undefined) {
                state.agreement = agreement;
            }
        },
    },
});

export const { setCredentials } = AuthSlice.actions;
export const authToken = (state: any) => state.auth;

export default AuthSlice.reducer;
