import { useState, useEffect } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import PropTypes from "prop-types";

const Notes = ({ userID }) => {
    const [allNotes, setAllNotes] = useState([]);
    const [userNotes, setUserNotes] = useState([]);
    const [noteText, setNoteText] = useState("");
    const [showNotification, setShowNotification] = useState(true); // Initially true to show the notification
    const [notificationClosed, setNotificationClosed] = useState(false); // State to track if notification is closed

    const localStorageKey = "allNotes";

    useEffect(() => {
        const storedNotes = localStorage.getItem(localStorageKey);
        if (storedNotes) {
            setAllNotes(JSON.parse(storedNotes));
        }
    }, []);

    useEffect(() => {
        if (userID) {
            const notesForUser =
                allNotes.find((userNotes) => userNotes.userID === userID)
                    ?.notes || [];
            setUserNotes(notesForUser);
        }
    }, [userID, allNotes]);

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(allNotes));
    }, [allNotes]);

    const addNote = () => {
        if (!noteText.trim()) return; // Don't add empty notes
        const newNote = {
            id: userNotes.length,
            text: noteText,
        };
        const updatedUserNotes = [...userNotes, newNote];
        setUserNotes(updatedUserNotes);
        updateAllNotes(userID, updatedUserNotes);
        setNoteText("");
        if (!notificationClosed) setShowNotification(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            addNote();
        }
    };

    const handleNoteChange = (event, id) => {
        const updatedUserNotes = userNotes.map((note) => {
            if (note.id === id) {
                return { ...note, text: event.target.value };
            }
            return note;
        });
        setUserNotes(updatedUserNotes);
        updateAllNotes(userID, updatedUserNotes);
    };

    const deleteNote = (id) => {
        const updatedUserNotes = userNotes.filter((note) => note.id !== id);
        setUserNotes(updatedUserNotes);
        updateAllNotes(userID, updatedUserNotes);
    };

    const updateAllNotes = (userID, updatedUserNotes) => {
        const otherUsersNotes = allNotes.filter(
            (userNotes) => userNotes.userID !== userID,
        );
        setAllNotes([...otherUsersNotes, { userID, notes: updatedUserNotes }]);
    };

    const closeNotification = () => {
        setShowNotification(false);
        setNotificationClosed(true);
    };

    const handleAddEmptyNote = () => {
        const newNote = {
            id: userNotes.length,
            text: "",
        };
        const updatedUserNotes = [...userNotes, newNote];
        setUserNotes(updatedUserNotes);
        updateAllNotes(userID, updatedUserNotes);
    };

    return (
        <div className='border border-gray-300 bg-white p-4 rounded-md shadow-lg relative'>
            <h3 className='text-md font-semibold mb-4'>Notes</h3>
            {showNotification && (
                <div
                    className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4'
                    role='alert'
                >
                    <strong className='font-bold'>Note saved locally! </strong>
                    <span className='block sm:inline'>
                        Your notes are saved locally and are not accessible on
                        other browsers or systems.
                    </span>
                    <button
                        aria-label='Close notification'
                        className='absolute top-0 right-0'
                        onClick={closeNotification}
                    >
                        <svg
                            className='fill-current h-6 w-6 text-green-500'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                        >
                            <title>Close</title>
                            <path d='M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z' />
                        </svg>
                    </button>
                </div>
            )}
            <div className='mt-2 absolute top-0 right-0'>
                <button
                    onClick={handleAddEmptyNote}
                    className='text-black-900 bg-white-700 shadow-lg rounded-full p-2 hover:bg-gray-600 transition-colors'
                >
                    <AiOutlinePlus />
                </button>
            </div>
            <ul>
                {userNotes.map((note) => (
                    <li key={note.id} className='mb-2 flex items-center'>
                        <input
                            type='text'
                            value={note.text}
                            onChange={(e) => handleNoteChange(e, note.id)}
                            className='bg-yellow-100 text-gray-800 rounded-md py-1 px-2 w-full border-none focus:outline-none'
                            placeholder='Add your notes here...'
                        />
                        <button
                            onClick={() => deleteNote(note.id)}
                            className='text-red-600 ml-2 focus:outline-none'
                        >
                            <AiOutlineDelete />
                        </button>
                    </li>
                ))}
            </ul>
            <div className='flex items-center mt-2'>
                <input
                    type='text'
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    onKeyDown={handleKeyDown} // Handle Enter key press
                    className='bg-yellow-100 text-gray-800 rounded-md py-1 px-2 w-full border-none focus:outline-none'
                    placeholder='Add new note...'
                />
            </div>
        </div>
    );
};

Notes.propTypes = {
    userID: PropTypes.number.isRequired,
};

export default Notes;
