import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    who: "",
    loading: { projects: true, selfData: true, users: true },
    projects: [],
    selfData: [],
    users: [],
};
const DashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState,
    reducers: {
        updateWho: (state, action) => {
            state.who = action.payload;
        },
        updateProjects: (state, action) => {
            state.projects = action.payload;
            state.loading.projects = false;
        },
        updateSelfData: (state, action) => {
            state.selfData = action.payload;
            state.loading.selfData = false;
        },
        updateUsers: (state, action) => {
            state.users = action.payload;
            state.loading.users = false;
        },
        removeProject: (state, action) => {
            let itemId = action.payload.id;
            let values = current(state.projects).filter(
                (val) => val.id !== itemId,
            );
            state.projects = values;
        },
        removeUser: (state, action) => {
            let itemId = action.payload.id;
            let values = current(state.users).filter(
                (val) => val.id !== itemId,
            );
            state.users = values;
        },
        reset: () => initialState,
    },
});

export const {
    updateWho,
    updateProjects,
    updateLoading,
    updateSelfData,
    updateUsers,
    reset,
} = DashboardSlice.actions;

export const { removeProject, removeUser } = DashboardSlice.actions;

export const getWho = (state) => state.dashboardSlice.who;
export const getLoading = (state) => state.dashboardSlice.loading;

export const getProjects = (state) => {
    let total = state.dashboardSlice.projects;
    let completed = state.dashboardSlice.projects.filter(
        (item) => item.status === "finished",
    );
    let failed = state.dashboardSlice.projects.filter(
        (item) => item.status === "failed",
    );
    let processing = state.dashboardSlice.projects.filter(
        (item) => item.status === "running",
    );
    return { total, completed, failed, processing };
};

export const getSelfData = (state) => state.dashboardSlice.selfData;

export const getUsers = (state) => {
    let total = state.dashboardSlice.users || [];
    let active = state.dashboardSlice.users.filter(
        (item) =>
            item && (item.is_active === true || item.is_active === "True"),
    );
    return { total, active };
};

export default DashboardSlice.reducer;
