import React from 'react'
import { LoadingSpin } from './Loading'
import { useSelector } from 'react-redux'
import { getLoading, getSelfData } from '../Global/DashboardSlice'

const Name = () => {
  const loggedUser = useSelector(getSelfData)
  const loading = useSelector(getLoading)
  // console.log(loggedUser)
  return (
    <div className='text-xl font-bold mb-4 ml-4 flex'>
        {loading.selfData?<LoadingSpin text={null}/>:`Hi, ${loggedUser.first_name}`}
    </div>
  )
}

export default Name