import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { FormikDoubleTextField } from "../../components/FormComponents/FormikDoubleTextField";
import { TbIdBadge2 } from "react-icons/tb";
import axios from "axios";
import { baseUrl } from "../BaseUrl";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { getSelfData } from "./Global/DashboardSlice";

const CustomerSupport = ({ onClose }) => {
    const [file] = useState(false);
    const isAdmin = localStorage.getItem("login-type") === "admin";

    const userIdData = useSelector(getSelfData);
    const userId = isAdmin ? userIdData.tenant_id : userIdData.user_id;

    const userName = userIdData.first_name;

    const initialUserId = userId ? String(userId) : "";
    const isUserIdDisabled = !!initialUserId;

    const userIdLabel = isAdmin ? "Admin ID" : "User ID";
    const userIdname = isAdmin ? "admin_id" : "user_id";

    const validationSchema = Yup.object({
        query: Yup.string().min(20, "Entry too brief").required("Required"),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        values = { ...values, userid: userId, username: userName, file: file };
        try {
            let finalData = new FormData();
            finalData.append("userid", values.userid);
            finalData.append("query", values.query);
            finalData.append("companyname", userIdData.organization_name);
            finalData.append("username", values.username);
            // finalData.append("file", values.file)

            await axios.post(baseUrl + "customersupport/", finalData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success(
                "Your request has been accepted. We will prioritize resolving it.",
            );
        } catch (error) {
            toast.error(error.toString());
            setSubmitting(false);
        }
    };

    return (
        <div className='flex flex-col overflow-hidden p-4'>
            <Formik
                initialValues={{
                    userId: initialUserId,
                    username: userName,
                    companyname: "",
                    query: "",
                    file: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FormikDoubleTextField
                            label1={userIdLabel}
                            label2='Company Name'
                            name1={userIdname}
                            name2='companyname'
                            placeholder1='John'
                            placeholder2='Doe'
                            icon1={TbIdBadge2}
                            icon2={TbIdBadge2}
                            light={true}
                            value1={userId}
                            value2={userIdData.organization_name}
                            disabled={isUserIdDisabled}
                            disabled2={true}
                        />
                        <div className=' relative'>
                            <Field
                                name='query'
                                placeholder='Enter your query here....!'
                                component='textarea'
                                className=' w-full bg-gray-100 bg-opacity-80 p-4 text-sm border border-gray-500 rounded-md outline-none'
                            ></Field>
                            <ErrorMessage
                                className=' text-sm absolute left-0 -bottom-4 text-red-400'
                                name='query'
                                component='div'
                            />
                        </div>
                        {/* <div className='mb-2'>
              <label className='  block text-sm text-black'>
                Upload Files
              </label>
            </div>
            <div className='flex items-center justify-center w-full'>
              <label
                htmlFor='dropzone-file'
                className='  flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
              >
                <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                  <svg
                    className='w-8 h-8 mb-4 text-gray-500 dark:text-gray-400'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 16'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                    />
                  </svg>
                  <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                    <span className='font-semibold'>
                      {file ? file.name : "Click to upload"}
                    </span>
                  </p>
                  <p className='text-xs text-gray-500 dark:text-gray-400'>
                    {file
                      ? `${Math.ceil(file.size / 1024)} KB`
                      : "SVG, PNG, JPG or GIF (MAX. 800x400px)"}
                  </p>
                </div>
                <input
                  id='dropzone-file'
                  name='file'
                  type='file'
                  className='hidden'
                  onChange={handleFileChange}
                />
              </label>
            </div> */}
                        <div className='flex justify-center space-x-24 mt-6'>
                            <button
                                className='hover:bg-iris-blue-700 text-white bg-iris-blue-500 font-bold py-2 px-12 rounded text-sm hover:opacity-80'
                                type='submit'
                            >
                                {isSubmitting ? "Submitting" : "Submit"}
                            </button>
                            <button
                                className='hover:bg-gray-500 text-white bg-gray-300 font-bold py-2 px-12 rounded text-sm hover:text-white'
                                type='button'
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

CustomerSupport.prototype = {
    onClose: PropTypes.func.isRequired,
};

export default CustomerSupport;
