import React from "react";

type Props = {
    name: string;
    className?: string;
};

export function MainText({ className, name }: Props) {
    return (
        <label
            className={`relative font-bold text-2xl relative grid place-items-center ${className}`}
            htmlFor={name}
        >
            {name}
        </label>
    );
}
