import { Field } from "formik";
import { AiOutlineCheck } from "react-icons/ai";

type Props = {
    caption: "Shape" | "Topology";
    desc: string;
    name: string;
    value: string;
    selected?: boolean;
    icon: React.ReactNode;
};

export function SimulationSelectionCard({
    caption,
    desc = "",
    name,
    value,
    selected = false,
    icon,
}: Props) {
    return (
        <>
            <label
                htmlFor={caption}
                className={`${
                    selected
                        ? "ring-2 ring-gray-500 ring-offset-1 bg-gray-100"
                        : ""
                } transition-all cursor-pointer flex flex-col items-center justify-center rounded-md shadow-md hover:shadow-lg`}
                // style={{ width: "90px", height: "80px" }}
            >
                <div
                    className={`h-10 w-20 overflow-hidden mb-2 flex items-center justify-center`}
                >
                    <div
                        className={`text-4xl my-2 ${
                            selected ? "text-iris-blue-500" : ""
                        }`}
                    >
                        {icon}
                    </div>
                </div>
                <Field
                    id={caption}
                    type='radio'
                    name={name}
                    value={value}
                    className='hidden'
                />
                <div
                    className={`flex flex-col items-center ${
                        selected ? "text-iris-blue-500" : ""
                    }`}
                >
                    <p className='text-xs'>{caption}</p>
                    <p className='text-xs h-2 overflow-scroll scrollbar-none opacity-50'>
                        {desc}
                    </p>
                </div>
                {selected && (
                    <span className='absolute bottom-0 right-0 mb-1 mr-1 hidden'>
                        <AiOutlineCheck className='text-black' />
                    </span>
                )}
            </label>
        </>
    );
}
