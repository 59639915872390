import React, { memo, useState } from "react";
import { LoadingSpin } from "../components/Loading";
import { MdClose } from "react-icons/md";
import { getUsers } from "../Global/DashboardSlice";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";

const AssignProjects = memo(function AssignProjects({
    data,
    setShowAssign,
    handleDelete,
}) {
    const [creating, setCreating] = useState(true);
    const users = useSelector(getUsers).total;
    // console.log(users)
    const [selected, setSelected] = useState(data);
    const handleAssignUser = async (e) => {
        e.preventDefault();
        setCreating("progress");
        let data_to_send = {
            user_id: selected.id,
            email: selected.email,
        };
        try {
            console.log(data.id);
            await axios.patch(baseUrl + `crud/${data.id}/`, data_to_send, {
                withCredentials: true,
            });
            toast.success(
                "Project Successfully Assigned to " + data_to_send.email,
            );
            handleDelete();
        } catch (error) {
            if (error.response.data.detail) {
                toast.error(error.response.data.detail);
            } else {
                toast.error("Something went wrong! Please again later!");
            }
        } finally {
            setCreating(false);
            setShowAssign(false);
        }
    };
    const handleField = (e) => {
        setCreating(false);
        let id = e.target.value;
        setSelected(users.filter((user) => user.id == id)[0]);
    };

    return (
            <div
                className='flex items-center justify-center absolute top-0 left-0 h-full w-full'
                style={{
                    backgroundColor: "#3338",
                    backdropFilter: "blur(2px)",
                }}
            >
                <form
                    onSubmit={handleAssignUser}
                    className=' flex flex-col p-4 bg-white shadow-lg rounded-md w-4/5 md:w-96'
                >
                    <p className='text-md'>Assign Projects different User</p>
                    <hr />
                    <select
                        onChange={handleField}
                        defaultValue={data.id}
                        className='py-2 px-2 my-4 bg-gray-200 rounded-md w-full'
                    >
                        {users.map((user) => {
                            return (
                                    <option
                                        key={user.id}
                                        name='user_id'
                                        value={user.id}
                                        disabled={user.id === data.id}
                                        className=' p-4'
                                    >
                                        {user.first_name}
                                    </option>
                            );
                        })}
                    </select>
                    <div className=' flex gap-2 flex-col bg-gray-100 p-2'>
                        <div className='flex justify-between gap-2'>
                            <p className=' whitespace-nowrap'>Mail : </p>
                            <input
                                className=' w-full text-right'
                                type='text'
                                disabled
                                value={selected.email}
                            />
                        </div>
                        <div className='flex justify-between gap-2'>
                            <p className=' whitespace-nowrap'>User Id : </p>
                            <input
                                className=' w-full text-right'
                                type='text'
                                disabled
                                value={selected.user_id}
                            />
                        </div>
                    </div>
                    <div className=' flex mt-4 justify-between gap-4'>
                        <button
                            type='reset'
                            onClick={() => setShowAssign(false)}
                            className=' bg-gray-200 flex gap-2 items-center px-4 rounded-md text-sm'
                        >
                            <MdClose className=' text-lg' />
                            <p>Close</p>
                        </button>
                        <button
                            className={` ${
                                creating
                                    ? " opacity-60 pointer-events-none"
                                    : ""
                            } bg-green-400 w-full text-sm rounded-md text-white cursor-pointer shadow-md flex items-center justify-center`}
                            type='submit'
                        >
                            {creating == "progress" ? (
                                <LoadingSpin text={"Creating..."} />
                            ) : (
                                <p className='py-3 px-4'>Assign and Delete</p>
                            )}
                        </button>
                    </div>
                </form>
            </div>
    );
});

export default AssignProjects;
