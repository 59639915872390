import axios from "axios";
import { useEffect } from "react";
import { MdDone, MdError } from "react-icons/md";
import { baseUrl } from "../BaseUrl";

function NotificationPopup({
    visible,
    onClose,
    notifications,
    setNotifications,
}) {
    let user = localStorage.getItem("login-type");
    user = user === "admin" ? "admin" : "user";
    useEffect(() => {
        let timeoutId;
        const handleMouseEnter = () => {
            clearTimeout(timeoutId);
        };
        const handleMouseLeave = () => {
            timeoutId = setTimeout(() => {
                onClose();
            }, 5000);
        };

        const popup = document.getElementById("notification-popup");
        if (popup) {
            popup.addEventListener("mouseenter", handleMouseEnter);
            popup.addEventListener("mouseleave", handleMouseLeave);
        }

        return () => {
            if (popup) {
                popup.removeEventListener("mouseenter", handleMouseEnter);
                popup.removeEventListener("mouseleave", handleMouseLeave);
            }
            clearTimeout(timeoutId);
        };
    }, [visible, onClose]);

    const removeNotification = (notification) => {
        async function fetchProjects() {
            try {
                await axios.put(
                    baseUrl +
                        `${user === "admin" ? "admincrud" : "crud"}/${
                            notification.id
                        }/`,
                    {
                        "": "",
                    },
                    {
                        withCredentials: true,
                    },
                );
                // toast.error(response.data.response);
                // setNotifications(notification)
            } catch (error) {
                console.log("Problem in refreshing the project...");
            }
        }
        fetchProjects();
        onClose();
        setNotifications(
            notifications.filter((obj) => obj.id !== notification.id),
        );
    };
    return (
        <div
            id='notification-popup'
            className={`absolute right-0 mt-2 bg-gray-100 rounded-lg shadow-lg py-1 z-50 ${
                visible ? "block" : "hidden"
            }`}
        >
            <div className='max-h-64 overflow-y-auto '>
                {notifications.map((notification) => (
                    <div
                        key={notification.id}
                        onClick={() => removeNotification(notification)}
                        className=' my-2'
                    >
                        {notification.status === "failed" && (
                            <div className='flex items-center p-2 rounded-md whitespace-nowrap text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer'>
                                <MdError className='mr-4 ml-2 text-red-400' />
                                <b>{notification.projectName}&nbsp;</b>is
                                <span className=' text-red-400'>
                                    &nbsp;Failed
                                </span>
                                .
                            </div>
                        )}
                        {notification.status === "finished" && (
                            <div className='flex items-center p-2 rounded-md whitespace-nowrap text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer'>
                                <MdDone className='mr-4 ml-2 text-green-400' />
                                <b>{notification.projectName}&nbsp;</b>is
                                <span className=' text-green-400'>
                                    &nbsp;completed
                                </span>
                                .
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NotificationPopup;
