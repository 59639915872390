import { TbAccessibleOff } from "react-icons/tb";

const ExpiredPage = () => {
    const closeProjectHandle = () => {
        document.getElementById("dashboard").click();
    };
    return (
        <div className='flex flex-col overflow-hidden h-full'>
            <div className=' flex-row flex justify-center content-center items-center h-full'>
                <div className=' flex flex-col gap-10 justify-center items-center'>
                    <div className=' relative'>
                        <TbAccessibleOff className=' text-9xl opacity-75' />
                        <TbAccessibleOff className=' absolute left-0 top-0 text-9xl opacity-20 animate-ping' />
                    </div>
                    <p className=' text-md'>
                        Your license has reached its expiration date.
                    </p>
                    <button
                        onClick={closeProjectHandle}
                        className=' text-sm bg-gray-200 px-6 py-2 rounded-md shadow-md'
                    >
                        Dashboard
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExpiredPage;
