import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../components/DashboardComponents/DashboardUtils/formatDate";
import { baseUrl } from "../../BaseUrl";
import toast from "react-hot-toast";
import axios from "axios";
import { removeProject } from "../Global/DashboardSlice";
import { IoClose } from "react-icons/io5";
import { MdReadMore } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";

const More = ({ item }) => {
    const [expand, setExpand] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [cord, setCord] = useState({});
    const dispatch = useDispatch();
    let isAdmin = localStorage.getItem("selected-tab");
    let filteredItem = {
        "Project Type": item["simulationType"],
        "Created On": formatDate(item["created_at"]),
        "Updated On": formatDate(item["updated_at"]),
    };
    const handleClick = (e) => {
        setExpand(!expand);
        setCord({
            x: e.clientX,
            y:
                window.innerHeight - e.clientY < 100
                    ? e.clientY - 80
                    : e.clientY,
        });
    };
    const handleClose = (e) => {
        setExpand(false);
    };
    const handleDelete = async (e) => {
        const itemColumnDOM = document.querySelector(`.project${item.id}`);
        itemColumnDOM.style.opacity = 0.5;
        let a = false;
        let fadeTimeInterval = setInterval(() => {
            itemColumnDOM.style.opacity = a ? 0.5 : 0.1;
            a = !a;
        }, 500);
        setExpand(false);
        try {
            const Request = await axios.delete(
                baseUrl +
                    `${
                        isAdmin === "admin" || isAdmin === null
                            ? "admincrud/"
                            : "crud/"
                    }${item.id}/`,
                {
                    withCredentials: true,
                },
            );
            if (Request.status === 200) {
                dispatch(removeProject(item));
            } else {
                toast.error("deletion is still in progress");
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Credential Error");
            } else if (error.response.status === 404) {
                toast.error("delete is still in progress");
            } else {
                toast.error("Cannot delete ");
            }
        } finally {
            itemColumnDOM.style.opacity = 1;
            clearInterval(fadeTimeInterval);
        }
    };
    const moreDetailsHandle = () => {
        setShowMore(true);
        setExpand(false);
    };

    return (
        <div>
            <div className='flex justify-end'>
                {expand ? (
                    <IoClose
                        onClick={handleClose}
                        className=' cursor-pointer text-2xl p-1'
                    />
                ) : (
                    <BsThreeDotsVertical
                        onClick={handleClick}
                        className=' cursor-pointer text-2xl p-1'
                    />
                )}
            </div>
            {expand && (
                <div
                    style={{ top: `${cord.y + 10}px`, right: `${60}px` }}
                    className='absolute p-2 rounded-lg select-none shadow-md justify-start flex flex-col gap-2 whitespace-nowrap bg-white border border-gray-200 z-10'
                >
                    {/* <p className=' hover:bg-gray-100 p-3  cursor-pointer'>Assign Project to a New User</p> */}
                    <div className='flex items-center gap-2 px-2 py-4 rounded-md hover:bg-gray-100 cursor-pointer'>
                        <MdReadMore className=' text-xl' />
                        <p className='' onClick={moreDetailsHandle}>
                            More Details
                        </p>
                    </div>
                    <div
                        className=' hidden items-center gap-2 p-2 py-4 text-red-500 rounded-md hover:bg-gray-100 cursor-pointer'
                        onClick={handleDelete}
                    >
                        <RiDeleteBin5Line className=' text-xl' />
                        <p>Delete Project</p>
                    </div>
                </div>
            )}
            {showMore && (
                <div className='absolute bottom-4 right-8  rounded-md shadow-md bg-gray-100 p-2 border-2 border-gray-200 z-10'>
                    <div className='flex items-center justify-between border-b-2 border-gray-400 pb-2'>
                        <h1 className=' text-center text-lg uppercase'>
                            {item["projectName"]}
                        </h1>
                        <CgClose
                            onClick={() => setShowMore(false)}
                            className=' text-xl cursor-pointer'
                        />
                    </div>
                    {Object.keys(filteredItem).map((key, index) => (
                        <div className='flex justify-between py-1'>
                            <p className='pr-4'>{key} : </p>
                            <p>{filteredItem[key]}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default More;
