import "./styles/output.css";
import { LoginSignupWidget } from "./routes/LoginSignupPage/LoginSignupWidget";
import { Routes, Route, useLocation } from "react-router-dom";
import DashboardWidget from "./routes/Dashboard/DashboardWidget";
import { useDispatch } from "react-redux";
import { setCredentials } from "./services/auth/authSlice";
import { setkey } from "./services/auth/keySlice";
import { useLocalAuth } from "./hooks/useLocalAuth";
import { Toaster } from "react-hot-toast";
import Logout from "./routes/Logout/Logout";
import Errors from "./errors/Errors";
import Signup from "./routes/LoginSignupPage/Signup";
import Signupuser from "./routes/LoginSignupPage/Signupuser";
import { MasterLogin } from "./components/DashboardComponents/MasterMode/MasterLogin";
import MasterdashBoard from "./components/DashboardComponents/MasterMode/MasterDashBoard";
import SessionManagement from "./components/CommonComponents/SessionManagement";
import MasterSignup from "./components/DashboardComponents/MasterMode/MasterSignup";

function App() {
    const dispatch = useDispatch();
    const { getAuthTokenWithExpiry, getKeyWithExpiry } = useLocalAuth();

    const token = getAuthTokenWithExpiry("tokenBQP");
    if (token) {
        dispatch(setCredentials(token));
    }

    const key = getKeyWithExpiry("KeyBQP");
    if (key) {
        dispatch(setkey(key));
    }

    const location = useLocation();
    const { pathname } = location;
    const shouldRenderConfirm = pathname.includes("confirm");

    return (
        <>
            <SessionManagement />
            <Routes>
                <Route path='/' element={<LoginSignupWidget type='login' />} />
                <Route path='/dashboard' element={<DashboardWidget />} />
                <Route path='/master/dashboard' element={<MasterdashBoard />} />
                <Route
                    path='/login'
                    element={<LoginSignupWidget type='login' />}
                />
                <Route path='/master/login' element={<MasterLogin />} />
                <Route path='/master/signup' element={<MasterSignup />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/signupuser' element={<Signupuser />} />
                <Route
                    path='/termsandpolicy'
                    element={<LoginSignupWidget type='termsandpolicy' />}
                />
                <Route
                    path='/reset-password'
                    element={<LoginSignupWidget type='reset-password' />}
                />
                {shouldRenderConfirm && (
                    <Route
                        path={pathname}
                        element={<LoginSignupWidget type='confirm' />}
                    />
                )}

                <Route path='/logout' element={<Logout />} />
                <Route path='*' element={<Errors />} />
            </Routes>
            <Toaster />
        </>
    );
}

export default App;
