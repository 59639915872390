import { useState, useEffect } from "react";
import { GodmenuItems } from "./MasterMenuItems";
import { IoLogOut } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../services/auth/authSlice";
import toast from "react-hot-toast";
import { MdClose } from "react-icons/md";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { MdAdd, MdSpaceDashboard } from "react-icons/md";
import bqp_logo from "../../../assets/images/bosonqpsi-logo.webp";
import { baseUrl } from "../../../routes/BaseUrl";
import { useLocalAuth } from "../../../hooks/useLocalAuth";
import { useNavigate } from "react-router-dom";

const MasterSidebar = ({ activeTab, setActiveTab }) => {
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { clearLocalStorage } = useLocalAuth();

    const handleLogout = () => {
        setShowLogoutConfirmation(true);
    };

    const confirmLogout = async () => {
        dispatch(setCredentials({ jwt: null, agreement: false }));
        clearLocalStorage();
        navigate("/master/login");
        toast.success("You've successfully logged out");
        setShowLogoutConfirmation(false);
        try {
            const response = await fetch(baseUrl + "client/logout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`Logout failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    const cancelLogout = () => {
        setShowLogoutConfirmation(false);
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };
    const reportWindowSize = (width) => {
        if (width < 1150) {
            if (sidebarVisible) setSidebarVisible(false);
        } else {
            if (!sidebarVisible) setSidebarVisible(true);
        }
    };
    useEffect(() => {
        const handleResize = () => reportWindowSize(window.innerWidth);
        reportWindowSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    window.addEventListener("resize", (e) =>
        reportWindowSize(e.target.innerWidth),
    );

    return (
        <div
            className='bg-gray-900 w-min text-white'
            style={{ height: "100vh" }}
        >
            <div className='overflow-y-auto text-center flex flex-col h-full'>
                <div className='flex items-center gap-6 text-gray-100 text-xl'>
                    <div className='p-4 mt-1 flex items-center mb-4'>
                        <img
                            src={bqp_logo}
                            alt='Logo'
                            className='w-8 rounded-full'
                        />
                        {sidebarVisible && (
                            <h1 className='font-bold text-gray-200 text-[15px] ml-3 whitespace-nowrap'>
                                BosonQ Psi
                            </h1>
                        )}
                    </div>
                    {sidebarVisible && (
                        <div className='my-2 h-[1px]'>
                            <span
                                className='text-white text-4xl top-5 left-4 cursor-pointer'
                                onClick={toggleSidebar}
                            >
                                <FaCircleArrowLeft className='px-2 bg-gray-900 rounded-md' />
                            </span>
                        </div>
                    )}
                </div>

                {!sidebarVisible && (
                    <div
                        onClick={toggleSidebar}
                        className='p-2.5 m-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white'
                    >
                        <FaCircleArrowLeft className='transform rotate-180 text-xl bg-gray-900 rounded-full' />
                    </div>
                )}

                <nav>
                    <ul className='flex flex-col gap-2 space-y-2'>
                        {GodmenuItems.map((item) => (
                            <li key={item.path} className='cursor-pointer'>
                                <div
                                    onClick={() => setActiveTab(item.path)}
                                    className={`whitespace-nowrap flex ${
                                        sidebarVisible ? "" : "justify-center"
                                    } items-center space-x-2 transition-colors p-2 rounded-md mx-2 ${
                                        activeTab === item.path
                                            ? "bg-blue-600 text-white"
                                            : "hover:text-white hover:bg-blue-600"
                                    }`}
                                    id={item.path}
                                >
                                    {item.path === "createAdmin" ? (
                                        <MdAdd className=' text-xl' />
                                    ) : (
                                        <MdSpaceDashboard className=' text-xl' />
                                    )}
                                    {sidebarVisible && <span>{item.name}</span>}
                                </div>
                            </li>
                        ))}
                        <li
                            onClick={handleLogout}
                            className={`flex ${
                                sidebarVisible ? "" : "justify-center"
                            } items-center gap-2 hover:text-white hover:bg-blue-600 transition-colors p-2 rounded-md cursor-pointer m-2`}
                        >
                            <IoLogOut className='text-xl' />
                            {sidebarVisible && <span>Logout</span>}
                        </li>
                    </ul>
                </nav>

                {showLogoutConfirmation && (
                    <div className='fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 text-gray-900'>
                        <div className='bg-white p-6 w-96 shadow-lg rounded-md'>
                            <div className='flex justify-between items-center'>
                                <h2 className='text-xl font-bold mb-2'>
                                    Logout Confirmation
                                </h2>
                                <button
                                    onClick={cancelLogout}
                                    className='text-gray-500 hover:text-gray-900'
                                >
                                    <MdClose />
                                </button>
                            </div>
                            <p className='my-4 items-center justify-center relative right-12'>
                                Are you sure you want to logout?
                            </p>
                            <div className='flex justify-end'>
                                <button
                                    onClick={confirmLogout}
                                    className='bg-iris-blue-500 text-white px-6 py-2 rounded-md hover:text-black hover:font-bold mr-4'
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={cancelLogout}
                                    className='border border-gray-300 bg-gray-200 px-6 py-2 rounded-md hover:bg-red-800 hover:text-white'
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MasterSidebar;
