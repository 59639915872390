import { useState } from "react";
import TermsAndCondition from "../components/SimulationWorkSpaceComponents/Extras/TermAndCondition";
import PrivacyPolicy from "../components/SimulationWorkSpaceComponents/Extras/PrivacyPolicy";

const TermsAndPolicyWidget = () => {
    const [selectedPolicy, setSelectedPolicy] = useState(
        parseInt(localStorage.getItem("selected-policy")) || 0,
    );
    return (
        <>
            <div className='text-white flex items-center gap-20 justify-center relative text-lg bottom-8'>
                <button
                    onClick={() => setSelectedPolicy(0)}
                    className={`cursor-pointer pb-4 border-b-4 ${
                        selectedPolicy === 0
                            ? "border-blue-500"
                            : "border-transparent"
                    } bg-transparent border-none text-lg`}
                >
                    Terms and Conditions
                </button>
                <button
                    onClick={() => setSelectedPolicy(1)}
                    className={`cursor-pointer pb-4 border-b-4 ${
                        selectedPolicy === 1
                            ? "border-blue-500"
                            : "border-transparent"
                    } bg-transparent border-none text-lg`}
                >
                    Privacy Policy
                </button>
            </div>
            {selectedPolicy === 0 ? (
                <TermsAndCondition light={true} />
            ) : (
                <PrivacyPolicy />
            )}
        </>
    );
};

export default TermsAndPolicyWidget;
