import axios from "axios";
import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { baseUrl } from "../../BaseUrl";
import toast from "react-hot-toast";
import { removeUser } from "../Global/DashboardSlice";
import { useDispatch } from "react-redux";
import AssignProjects from "./AssignProjects";
import { MdDeleteOutline } from "react-icons/md";

const MoreUser = ({ user }) => {
    const [expand, setExpand] = useState(false);
    const [cord, setCord] = useState({});
    const dispatch = useDispatch();
    const [showAssign, setShowAssign] = useState(false);
    const handleClick = (e) => {
        setExpand(!expand);
        setCord({
            x: e.clientX,
            y:
                window.innerHeight - e.clientY < 100
                    ? e.clientY - 80
                    : e.clientY,
        });
    };
    const handleDelete = async (e) => {
        const itemColumnDOM = document.querySelector(`.user${user.user_id}`);
        itemColumnDOM.style.opacity = 0.5;
        let a = false;
        let fadeTimeInterval = setInterval(() => {
            itemColumnDOM.style.opacity = a ? 0.5 : 0.1;
            a = !a;
        }, 500);
        setExpand(false);
        try {
            const Request = await axios.delete(
                baseUrl + `cruduser/${user.id}`,
                {
                    withCredentials: true,
                },
            );
            if (Request.status === 200) {
                dispatch(removeUser(user));
                toast.success("User deleted successfully");
            }
        } catch (error) {
            if (error.response.data.detail === "User contains project") {
                setShowAssign(true);
                toast.error(
                    "Before deleting, assign the user's project to other",
                );
            } else {
                toast.error(error.response.data.detail);
            }
        } finally {
            itemColumnDOM.style.opacity = 1;
            clearInterval(fadeTimeInterval);
        }
    };
    return (
        <>
            <div className='flex justify-end'>
                {expand ? (
                    <IoClose
                        onClick={handleClick}
                        className='bg-red-200 cursor-pointer text-2xl p-1'
                    />
                ) : (
                    <BsThreeDotsVertical
                        onClick={handleClick}
                        className=' cursor-pointer text-2xl p-1'
                    />
                )}
            </div>
            {expand && (
                <div
                    style={{ top: `${cord.y + 10}px`, right: `${60}px` }}
                    className='absolute rounded-md select-none text-center shadow-md flex flex-col whitespace-nowrap bg-white'
                >
                    <p className=' hidden hover:bg-gray-100 p-3  cursor-pointer'>
                        Give Permission to a Project
                    </p>
                    <p className=' hidden hover:bg-gray-100 border-t-2 border-b-2 border-gray p-3 cursor-pointer'>
                        Edit User
                    </p>
                    <div
                        className=' flex gap-4 items-center justify-between cursor-pointer px-2 rounded-md hover:bg-red-100  overflow-hidden'
                        onClick={handleDelete}
                    >
                        <MdDeleteOutline className=' text-lg text-gray-500' />
                        <p className=' text-red-400 p-3'>Delete User</p>
                    </div>
                </div>
            )}
            {showAssign && (
                <AssignProjects
                    data={user}
                    setShowAssign={setShowAssign}
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
};

export default MoreUser;
