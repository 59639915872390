export interface LoadingProps {
    text: null | string;
    background: "light" | "dark" | "transparent";
}

export const LoadingSpin = ({
    text = "Loading...",
    background,
}: LoadingProps) => {
    return (
        <div className='relative rounded-xl overflow-auto w-min'>
            <div className='flex items-center justify-center'>
                <button
                    type='button'
                    disabled
                    className={`inline-flex shadow-sm whitespace-nowrap items-center px-4 py-2 font-semibold leading-6 text-sm rounded-md 
            ${
                background === "light" &&
                "text-black bg-white border-2 border-gray-100 "
            }
            ${
                background === "dark" &&
                "text-white bg-black border-2 border-gray-100 "
            }
            ${background === "transparent" && "text-black"}
             transition ease-in-out duration-150 cursor-not-allowed`}
                >
                    <svg
                        className={`animate-spin -ml-1 ${
                            text && "mr-3"
                        } h-5 w-5 
                    ${background === "light" && "text-light "}
                    ${background === "dark" && "bg-black "}
                    ${background === "transparent" && "text-black "}
                    `}
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                    >
                        <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                        ></circle>
                        <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        ></path>
                    </svg>
                    {text}
                </button>
            </div>
        </div>
    );
};
