import { Link } from "react-router-dom";
import { authToken } from "../services/auth/authSlice";
import { useSelector } from "react-redux";

const Errors = () => {
    const token = useSelector(authToken);
    const loginType = localStorage.getItem("login-type");
    const isMasterLogin = localStorage.getItem("login-type-master");
    const currentURL = window.location.pathname;

    let navigatePath = "/";

    if (token) {
        if (isMasterLogin === "master") {
            navigatePath = currentURL.includes("/master/")
                ? "/master/dashboard"
                : "/dashboard";
        } else if (loginType === "admin" || loginType === "user") {
            navigatePath = "/dashboard";
        } else {
            navigatePath = "/login";
        }
    } else {
        if (isMasterLogin === "master" || currentURL.includes("/master/")) {
            navigatePath = "/master/login";
        } else if (loginType === "admin" || loginType === "user") {
            navigatePath = "/login";
        } else {
            navigatePath = "/login";
        }
    }

    return (
        <section className='bg-gray-200 min-h-screen flex flex-col items-center justify-center'>
            <div className='max-w-md text-center'>
                <h2 className='text-4xl font-bold text-gray-800 mb-4'>404</h2>
                <h4 className='text-xl font-semibold text-gray-800 mb-2'>
                    Oops! That page can’t be found
                </h4>
                <p className='text-lg text-gray-700 mb-8'>
                    The page you are looking for might be deleted.
                </p>
                <Link
                    to={navigatePath}
                    className='bg-iris-blue-500 text-white font-semibold py-3 px-8 rounded-lg text-lg hover:bg-iris-blue-700 transition-colors duration-300 hover:text-black'
                >
                    Go To Home
                </Link>
            </div>
        </section>
    );
};

export default Errors;
