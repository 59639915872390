import { useState } from "react";
import PropTypes from "prop-types";
import { FormikTextField } from "../../components/FormComponents/FormikTextField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useLoginMutation } from "../../services/auth/authApi";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../services/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useLocalAuth } from "../../hooks/useLocalAuth";
import toast from "react-hot-toast";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { LiaIdCardSolid } from "react-icons/lia";
import { updateWho } from "../Dashboard/Global/DashboardSlice";

const decodeJWT = (token) => {
    try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const binaryString = window.atob(base64);

        const decoder = new TextDecoder("utf-8");
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        const jsonPayload = decoder.decode(uint8Array);

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Error decoding JWT:", error.message);
        return null;
    }
};

const CustomPasscodeInput = ({ field, form, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (e) => {
        const maxLength = parseInt(e.target.getAttribute("maxlength"), 10);
        const currentInput = e.target;
        const nextInput = currentInput.nextElementSibling;
        const prevInput = currentInput.previousElementSibling;
        const value = e.target.value;

        if (!/^\d*$/.test(value)) {
            form.setFieldError(field.name, "Passcode must be a number");
            return;
        }

        if (e.key === "Backspace" && value.length === 0 && prevInput) {
            prevInput.focus();
        } else {
            form.setFieldValue(field.name, value);

            if (value.length === 0 && e.key === "Backspace" && prevInput) {
                prevInput.focus();
            } else if (value.length === 0 && e.key === "Delete" && nextInput) {
                nextInput.focus();
            } else if (value.length >= maxLength && nextInput) {
                nextInput.focus();
            }
        }
    };

    return (
        <input
            {...field}
            {...props}
            className={`w-12 h-12 bg-transparent text-white text-center outline-none ${
                isFocused
                    ? "border-b-2 border-iris-blue-500"
                    : "border-b-2 border-gray-200"
            }`}
            maxLength={1}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleInputChange}
        />
    );
};

const otpFields = ["field1", "field2", "field3", "field4", "field5", "field6"];

const UserLogin = () => {
    const dispatch = useDispatch();
    const [login, { isLoading }] = useLoginMutation();
    const navigate = useNavigate();
    const { setAuthTokenWithExpiry } = useLocalAuth();

    const handlePaste = (e, setFieldValue) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("text/plain").split("");
        if (pastedText.length === 6) {
            pastedText.forEach((element, idx) => {
                setFieldValue(`otp.${idx}`, element);
            });
        }
    };

    return (
        <Formik
            initialValues={{
                user_id: "",
                email: "",
                password: "",
                otp: Array(6).fill(""),
            }}
            validationSchema={Yup.object({
                user_id: Yup.string()
                    .matches(/^\d+$/, "Must be only numbers")
                    .matches(/\b\d{12}\b/, "ID should be 12 digits")
                    .required("Required"),
                email: Yup.string()
                    .email("Invalid email address")
                    .required("Email address is required"),
                password: Yup.string().required("Password is required"),
                otp: Yup.array()
                    .of(
                        Yup.string()
                            .matches(/^\d$/, "Passcode must be numeric")
                            .required("Passcode is required"),
                    )
                    .length(6, "Passcode must be exactly 6 digits"),
            })}
            onSubmit={async (values) => {
                try {
                    const otp = values.otp.join("");
                    const updatedValues = { ...values, otp };
                    const response = await login(updatedValues);
                    const { data, error } = response;

                    if (data) {
                        const decodedToken = decodeJWT(data.jwt);
                        localStorage.setItem("start_timer", new Date());
                        localStorage.setItem("jwt-id", decodedToken.id);
                        dispatch(setCredentials(data));
                        setAuthTokenWithExpiry(data.jwt);
                        dispatch(updateWho("user"));
                        navigate("/signupuser");
                        toast.success("You've successfully logged in");
                        localStorage.setItem("login-type", "user");
                    } else if (error) {
                        const { detail } = error.data || {};
                        toast.error(
                            detail || "An error occurred during login.",
                        );
                    } else {
                        toast.error("Something went wrong during login.");
                    }
                } catch (error) {
                    console.error("An error occurred during login:", error);
                    toast.error(
                        "An error occurred during login. Please try again.",
                    );
                }
            }}
        >
            {({ setFieldValue }) => (
                <Form className='flex flex-col'>
                    {/* Form Fields */}
                    <div className='bg-gray-200 h-0.5 w-full my-2 opacity-20 rounded-full'></div>
                    <FormikTextField
                        label='User ID'
                        name='user_id'
                        type='number'
                        autoFocus
                        placeholder='Your 12 digit User ID'
                        icon={LiaIdCardSolid}
                    />
                    <FormikTextField
                        label='Email ID'
                        name='email'
                        type='email'
                        placeholder='Johndoe@companyname.com'
                        icon={HiOutlineMail}
                    />
                    <FormikTextField
                        label='Password'
                        name='password'
                        type='password'
                        placeholder='*******'
                        icon={FiLock}
                    />
                    <Link
                        className='block text-iris-blue-500 text-xs hover:text-gray-500 text-right relative font-medium -top-3'
                        to='/reset-password'
                    >
                        Forgot Password?
                    </Link>

                    <div className=''>
                        <label
                            htmlFor='otp'
                            className='block text-sm text-white'
                        >
                            Authentication Passcode
                        </label>
                        <div
                            className='grid grid-cols-6 gap-2 text-black'
                            onPaste={(e) => handlePaste(e, setFieldValue)}
                        >
                            {otpFields.map((fieldKey) => (
                                <Field
                                    key={fieldKey}
                                    name={`otp.${otpFields.indexOf(fieldKey)}`}
                                    type='text'
                                    component={CustomPasscodeInput}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='flex justify-center transform scale-90 '>
                        <p className='block text-xs text-gray-200 text-center my-4 mb-6'>
                            *By signing in, you agree to our{" "}
                            <Link
                                className='text-iris-blue-500 hover:text-gray-500'
                                to='/termsandpolicy'
                            >
                                terms and conditions
                            </Link>{" "}
                            and our{" "}
                            <Link
                                className='text-iris-blue-500 hover:text-gray-500'
                                to='/termsandpolicy'
                            >
                                privacy policy*
                            </Link>
                        </p>
                    </div>

                    <div className='flex justify-center'>
                        <button
                            type='submit'
                            className={`text-lg transition-all shadow-2xl bg-iris-blue-500 px-5 text-white rounded h-12 w-56 font-medium
                hover:bg-iris-blue-800 hover:text-black hover:shadow-md
            } `}
                        >
                            {isLoading ? "Loading..." : "Login"}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

CustomPasscodeInput.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

export { UserLogin };
