import { useState } from "react";
import { BiReset } from "react-icons/bi";
import { TbArrowAutofitWidth } from "react-icons/tb";

const MinMax = ({ MinMaxChange, metaData }) => {
    const [minMax, setMinMax] = useState({
        min: metaData.Min,
        max: metaData.Max,
    });
    const [show, setShow] = useState(false);
    const guiChanges = (e) => {
        MinMaxChange(e);
    };

    const applyMinMax = () => {
        guiChanges([
            "min_max",
            { minDis: parseFloat(minMax.min), maxDis: parseFloat(minMax.max) },
        ]);
        setShow(false);
    };

    const handleReset = () => {
        let val = localStorage.getItem("minMax");
        if (val) val = JSON.parse(val);
        setMinMax({
            min: parseFloat(val.minDis),
            max: parseFloat(val.maxDis),
        });
    };
    return (
        <div className=' h-10 relative '>
            <TbArrowAutofitWidth
                title='set min and max distance'
                onClick={() => setShow(!show)}
                className={`${
                    show ? "bg-gray-600 text-white" : "bg-white text-black"
                }  p-2 text-4xl rounded-md cursor-pointer`}
            />
            {show ? (
                <div
                    className='absolute top-10 z-50 flex gap-4 flex-col rounded-xl p-4 text-md h-fit'
                    style={{ background: "#fffa", backdropFilter: "blur(2px)" }}
                >
                    <div className=' flex items-center gap-2'>
                        <label htmlFor='max' className=' text-xs'>
                            Max{" "}
                        </label>
                        <input
                            id='max'
                            type='number'
                            value={minMax.max}
                            onChange={(e) =>
                                setMinMax({ ...minMax, max: e.target.value })
                            }
                            className=' w-28 bg-gray-100 outline-none text-xs px-2 py-2 cursor-pointer rounded-md shadow-inner'
                        />
                    </div>
                    <div className=' flex items-center gap-2'>
                        <label htmlFor='min' className=' text-xs'>
                            Min{" "}
                        </label>
                        <input
                            id='min'
                            type='number'
                            value={minMax.min}
                            onChange={(e) =>
                                setMinMax({ ...minMax, min: e.target.value })
                            }
                            className=' w-28 bg-gray-100 outline-none text-xs px-2 py-2 cursor-pointer rounded-md shadow-inner'
                        />
                    </div>
                    <div className='flex items-center justify-between gap-2'>
                        <BiReset
                            title='Reset'
                            onClick={handleReset}
                            className=' bg-white p-1.5 w-10 text-3xl rounded-md cursor-pointer'
                        />
                        <button
                            onClick={applyMinMax}
                            className='bg-white p-2 shadow-sm text-xs rounded-md w-full'
                        >
                            Apply
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MinMax;
