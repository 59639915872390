import { useState, useEffect } from "react";
import { baseUrl } from "../routes/BaseUrl";
import axios from "axios";

export const useFetchId = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let fetchPromise = null;

        const fetchDataOnce = async () => {
            try {
                let loginProfileType = "";
                const selectedTab = localStorage.getItem("selected-tab");
                switch (selectedTab) {
                    case "client":
                        loginProfileType = "client/login";
                        break;
                    case "tenant":
                    case "admin":
                        loginProfileType = "tenant/login";
                        break;
                    case "user":
                        loginProfileType = "user/login";
                    // eslint-disable-next-line no-fallthrough
                    default:
                        break;
                }

                const response = await axios.get(baseUrl + loginProfileType, {
                    withCredentials: true,
                });
                return {
                    id: response.data.id,
                    userName:
                        response.data.company_name ||
                        response.data.first_name ||
                        response.data.name ||
                        "",
                };
            } catch (error) {
                throw error;
            }
        };

        const fetchData = async () => {
            if (!fetchPromise) {
                fetchPromise = fetchDataOnce();
            }

            try {
                const newData = await fetchPromise;
                setData(newData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            fetchPromise = null;
        };
    }, []);

    return {
        id: data?.id,
        userName: data?.userName,
        loading,
        error,
    };
};

export default useFetchId;
