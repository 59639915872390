const MiniCard=({icon, title, value, bg})=>{
    return(
        <div className={`relative flex min-h-0 rounded-xl shadow-md gap-4 px-4 py-2 items-center ${bg}`}>
            <div className='h-10 p-1 shadow-inner bg-white rounded-lg opacity-80 '>
                {icon}
            </div>
            <div className=' text-md mr-4'>
                {title}
                <div className='text-lg font-bold'>
                    {value}
                </div>
            </div>
        </div>
    )
}
export default MiniCard