import { useState } from "react";
import { BsInfo } from "react-icons/bs";
import stream from "../../../assets/images/stream.png";
const InfoBtn = () => {
    const [expand, setExpand] = useState(false);
    return (
        <div className='relative'>
            <BsInfo
                // onClick={handleClick}
                onMouseEnter={() => setExpand(true)}
                onMouseLeave={() => setExpand(false)}
                className=' text-xl rounded-full bg-black text-white cursor-pointer'
            />
            {expand && (
                <div className=' absolute bottom-11 bg-gray-100 h-80 w-96 transform -translate-x-1/2 rounded-xl p-1 shadow-xl'>
                    <div
                        style={{
                            borderLeft: "15px solid transparent",
                            borderRight: "5px solid transparent",
                            borderTopWidth: "20px",
                        }}
                        className='absolute -bottom-5 left-1/2 transform border-gray-100 -translate-x-1/2 z-10'
                    ></div>
                    <img src={stream} alt='stream' />
                </div>
            )}
        </div>
    );
};

export default InfoBtn;
