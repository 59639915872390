import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { baseUrl } from "../../../routes/BaseUrl";
import toast from "react-hot-toast";
import { FormikTextField } from "../../FormComponents/FormikTextField";
import { HiOutlineMail } from "react-icons/hi";
import { TbIdBadge2 } from "react-icons/tb";

const MasterUser = ({ onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [plans, setPlan] = useState();

    const initialValues = {
        first_name: "",
        email: "",
        planid: "",
    };

    const handleSubmit = async (values) => {
        setFlag(true);
        setIsLoading(true);
        try {
            if (!values.planid.length) {
                toast.error("Please Select Plan Id");
                setFlag(false);
                return;
            }
            await axios.post(baseUrl + "tenant/signup", values, {
                withCredentials: true,
            });
            toast.success("You've successfully added the Admin");
            onClose();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                if (error.response.data.email) {
                    toast.error("Admin with this email already exists");
                } else {
                    toast.error(error.response.data.detail);
                }
            } else {
            }
        } finally {
            setIsLoading(false);
            setFlag(false);
        }
    };
    useEffect(() => {
        const fetchPlan = async () => {
            let ids = [];
            try {
                let response = await axios.get(baseUrl + "plan", {
                    withCredentials: true,
                });
                response.data.forEach((item) => {
                    ids.push(item.id);
                });
                setPlan(ids);
            } catch (error) {
                toast.error("Unable to fetch User(s) data");
            }
        };
        fetchPlan();
    }, []);

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <div className='p-2'></div>
                <FormikTextField
                    label='Email Address'
                    name='email'
                    type='email'
                    placeholder='johndoe@company.com'
                    light={true}
                    icon={HiOutlineMail}
                />
                <FormikTextField
                    label='Name'
                    name='first_name'
                    type='text'
                    placeholder='John Doe'
                    light={true}
                    icon={TbIdBadge2}
                />
                <div className='flex flex-col mb-4'>
                    <label
                        className='block text-m mb-2 text-black-800 font-medium'
                        htmlFor='planid'
                    >
                        Plan ID
                    </label>
                    <Field
                        as='select'
                        id='planid'
                        name='planid'
                        // className="block w-full pl-10 text-sm text-gray-700"
                        className='bg-white bg-opacity-70 border text-black text-sm rounded-lg p-2'
                    >
                        <option selected>Select Plan Id</option>
                        {plans ? (
                            plans.map((val, key) => (
                                <option key={key} value={val}>
                                    {val}{" "}
                                </option>
                            ))
                        ) : (
                            <option>Loading Plans... </option>
                        )}
                    </Field>
                </div>
                <div className='flex gap-8 justify-center items-center'>
                    <button
                        type='submit'
                        className={`relative top-1 text-md transition-all shadow-2xl bg-iris-blue-500 px-4 text-white rounded h-10 w-48 font-medium ${
                            flag
                                ? "hover:bg-gray-500 disabled:opacity-100 cursor-not-allowed"
                                : "hover:bg-iris-blue-800 hover:text-black hover:shadow-md"
                        }`}
                        disabled={flag}
                    >
                        {isLoading ? "Adding ..." : "Create Admin"}
                    </button>
                    <button
                        onClick={() => onClose(false)}
                        className={`relative top-1 text-md transition-all shadow-2xl bg-gray-500 px-4 text-white rounded h-10 w-48 font-medium ${
                            flag
                                ? "hover:bg-gray-500 disabled:opacity-100 cursor-not-allowed"
                                : "hover:bg-iris-blue-800 hover:text-black hover:shadow-md"
                        }`}
                        disabled={flag}
                    >
                        Close
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export default MasterUser;
