import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AdminLogin } from "./AdminLogin";
import { UserLogin } from "./UserLogin";

const RadioButton = ({ tab, selectedTab, handleTabChange }) => (
    <label className='inline-flex items-center cursor-pointer text-md'>
        <input
            type='radio'
            className={`w-4 h-4 form-radio cursor-pointer font-normal ${
                selectedTab === tab ? "text-iris-blue-500" : ""
            }`}
            value={tab}
            checked={selectedTab === tab}
            onChange={() => handleTabChange(tab)}
            style={{ color: selectedTab === tab ? "#4F46E5" : "" }}
            id={`tab-${tab}`}
        />
        <span
            className={`ml-2 ${
                selectedTab === tab ? "text-iris-blue-500" : ""
            }`}
            htmlFor={`tab-${tab}`}
        >
            {tab === "admin" ? "Admin" : "User"}
        </span>
    </label>
);

RadioButton.propTypes = {
    tab: PropTypes.string.isRequired,
    selectedTab: PropTypes.string.isRequired,
    handleTabChange: PropTypes.func.isRequired,
};

const LoginWidget = () => {
    const [selectedTab, setSelectedTab] = useState("admin");

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        localStorage.setItem("selected-tab", tab);
    };

    useEffect(() => {
        const storedTab = localStorage.getItem("selected-tab");
        if (storedTab) {
            setSelectedTab(storedTab);
        } else {
            setSelectedTab("admin");
            localStorage.setItem("selected-tab", "admin");
        }
    }, []);

    return (
        <>
            <div className='text-white flex items-center justify-center'>
                <RadioButton
                    tab='admin'
                    selectedTab={selectedTab}
                    handleTabChange={handleTabChange}
                />
                <div className='mx-12'></div>
                <RadioButton
                    tab='user'
                    selectedTab={selectedTab}
                    handleTabChange={handleTabChange}
                />
            </div>
            {selectedTab === "admin" ? <AdminLogin /> : <UserLogin />}
        </>
    );
};

export { LoginWidget };
