import { useState } from "react";
import { SimulationSelectionCard } from "../../../components/DashboardComponents/SimulationSelectionCard";
import * as Yup from "yup";
import { Form, Formik, ErrorMessage } from "formik";
import { AiFillWarning } from "react-icons/ai";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { baseUrl } from "../../BaseUrl";
import { HiOutlineMail } from "react-icons/hi";
import { FormikTextField } from "../../../components/FormComponents/FormikTextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { resetSolverInputState } from "../../../features/simulationWorksapce/SolverInputSlice";
import inpImg from "../../../assets/inp.png";
import { LoadingSpin } from "../components/Loading";
import OptimizationConfigs from "../../../components/SimulationWorkSpaceComponents/SidebarComponents/Optimization/OptimizationConfigs";
import { getProjects, updateProjects } from "../Global/DashboardSlice";
import "../../../styles/fileupload.css";
import shape from "../../../assets/ProjectType/shape.png";
import topo from "../../../assets/ProjectType/topo.png";

const CreateProject = () => {
    let isAdmin = localStorage.getItem("selected-tab");
    localStorage.setItem("fileType", "geo");
    let projects = useSelector(getProjects).total;
    const [desc, setDesc] = useState("Create");
    const [createdProjectData, setCreatedProjectData] = useState();
    const [geoUploadProgress, setGeoUploadProgress] = useState(0);
    const [mshUploadProgress, setMshUploadProgress] = useState(0);
    const [fileData, setFileData] = useState({});
    const dispatch = useDispatch();
    const totalProjects = useSelector(getProjects).total;

    const mshUploadProgressHandler = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setMshUploadProgress(percentCompleted);
    };

    const closeProjectHandle = () => {
        document.getElementById("dashboard").click();
    };
    const reset = () => {
        setDesc("Create");
        setGeoUploadProgress(0);
        setMshUploadProgress(0);
    };

    const getUserDetails = async () => {
        try {
            const response = await axios.get(
                `${baseUrl}${
                    isAdmin === "admin" || isAdmin === null
                        ? "tenant/login"
                        : "user/login"
                }`,
                { withCredentials: true },
            );
            let data = response.data;
            return data.role ? data : data[0];
        } catch (error) {
            handleError(error, "An error occurred while checking your access.");
            throw error; // Re-throw to propagate the error
        }
    };

    const createJob = async (jobData) => {
        try {
            const response = await axios.post(
                `${baseUrl}${
                    isAdmin === "admin" || isAdmin === null
                        ? "adminjobid"
                        : "jobid"
                }`,
                jobData,
                { withCredentials: true },
            );
            return response.data;
        } catch (error) {
            handleError(
                error,
                "Unable to create a project, your changes will NOT BE SAVED!",
            );
            throw error; // Re-throw to propagate the error
        }
    };

    const uploadFile = async (formDataFileTwo) => {
        try {
            await axios.post(
                `${baseUrl}${
                    isAdmin === "admin" || isAdmin === null
                        ? "adminfile"
                        : "file"
                }`,
                formDataFileTwo,
                {
                    withCredentials: true,
                    onUploadProgress: mshUploadProgressHandler,
                },
            );
        } catch (error) {
            handleError(error, "An error occurred while uploading the file.");
            throw error; // Re-throw to propagate the error
        }
    };

    const handleError = (error, defaultMessage) => {
        if (error.response) {
            const errorCode = error.response.status;
            if (errorCode === 403) {
                toast.error("This Geometry file is not supported.");
            } else {
                toast.error(defaultMessage);
            }
        } else {
            toast.error(defaultMessage);
        }
        reset();
    };

    const createProject = async (values) => {
        try {
            setDesc("Checking Access...");
            const userDetails = await getUserDetails();

            if (userDetails.role === "internal") {
                setDesc("Checking Access ✅");
                setDesc("Creating Project...");

                const userId = userDetails.id;
                const userName = userDetails.first_name;
                Cookies.set("username", userName);

                const jobData = {
                    user: userId,
                    userName: userName,
                    simulationType: values.simulationType,
                    projectName: values.projectName,
                };

                const createdProject = await createJob(jobData);
                dispatch(resetSolverInputState());
                dispatch(updateProjects([...totalProjects, createdProject]));
                setCreatedProjectData(createdProject);
                setDesc("Project Created ✅");
                setDesc("Uploading Files");

                const formDataFileTwo = new FormData();
                formDataFileTwo.append("file", values.mshFile);
                formDataFileTwo.append("user", userId);
                formDataFileTwo.append("userName", userName);
                formDataFileTwo.append(
                    "simulation_type",
                    values.simulationType,
                );
                formDataFileTwo.append("projectName", values.projectName);

                await uploadFile(formDataFileTwo);
                setDesc("Next");
            } else {
                toast.error("You don't have permission.");
                reset();
            }
        } catch (error) {
            setDesc("Create");
            handleError(error, "An error occurred while creating the project.");
        }
    };

    function fileReader(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                const lines = fileContent.split("\n");

                if (file.size === 0) {
                    reject(new Error("File is empty"));
                    return;
                }

                if (lines.length < 10) {
                    reject(new Error("File have no enough content to proceed"));
                    return;
                }

                // To get k_in and k_out value from displacement problem.
                const targetPhrase = "*Spring, Elset=";
                let extractedValue = [];
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i].includes(targetPhrase)) {
                        if (i + 2 < lines.length) {
                            extractedValue.push(
                                parseFloat(lines[i + 2].trim()),
                            );
                        }
                        if (extractedValue.length === 2) {
                            break;
                        }
                    }
                }
                if (extractedValue.length === 0) {
                    setFileData({
                        kin: 0,
                        kout: 0,
                    });
                } else {
                    setFileData({
                        kin: extractedValue[0],
                        kout: extractedValue[1],
                    });
                }
                resolve("Reading of file completed sucessfully.");
            };

            reader.onerror = () => {
                reject(new Error("File reading failed"));
            };

            reader.readAsText(file);
        });
    }

    const submitHandler = (values) => {
        // check existing project name matches
        if (
            projects.filter((val) => val.projectName === values.projectName)
                .length
        ) {
            toast.error("Project Name already exists");
            return;
        }
        if (values.simulationType === "") {
            toast.error("Select Project Type");
            return;
        }

        const isAllowedFileExtension = (filename, extension = "inp") => {
            return filename.toLowerCase().endsWith(`.${extension}`);
        };

        const validateFile = (file) => {
            if (!isAllowedFileExtension(file.name)) {
                toast.error("Only .inp file is allowed");
                return false;
            }
            return true;
        };

        if (!validateFile(values.mshFile)) {
            return;
        }

        async function handleFile() {
            try {
                const validFile = await fileReader(values.mshFile);
                console.log(validFile);
                createProject(values);
            } catch (error) {
                toast.error(error.message);
            }
        }
        handleFile();
    };

    const renderButtonText = () => {
        if (desc === "Create" || desc === "Next") {
            return <span>Next</span>;
        }
        return <div>Creating...</div>;
    };

    return (
        <div className='flex flex-col overflow-hidden h-full'>
            {/* <Name/> */}
            <div className=' flex-row flex justify-center content-center items-center h-full'>
                <div
                    style={{ boxShadow: "0 0 20px rgba(0,0,0,0.2)" }}
                    className='uploadContainer overflow-y-auto flex flex-row gap-1 ml-2 rounded-2xl p-8 max-w-3xl w-full mx-4 md:w-4/5 bg-white'
                >
                    {desc !== "Next" && (
                        <Formik
                            initialValues={{
                                projectName: "",
                                simulationType: "",
                                mshFile: undefined,
                                // geoFile: undefined,
                            }}
                            validationSchema={Yup.object({
                                projectName: Yup.string()
                                    .matches(
                                        /^[a-zA-Z0-9-]*$/,
                                        "Input must consist of letters, numbers and hyphens only.",
                                    )
                                    .required("Required"),
                            })}
                            onSubmit={(values) => submitHandler(values)}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className='w-full'>
                                    <p className=' text-md font-bold pb-2'>
                                        Create a New Project
                                    </p>
                                    <hr />
                                    <div>
                                        <p className='text-sm py-2'>
                                            Project Type
                                        </p>
                                        <div className='flex gap-4 py-1'>
                                            <SimulationSelectionCard
                                                caption='Topology'
                                                name='simulationType'
                                                value='topology'
                                                // eslint-disable-next-line jsx-a11y/alt-text
                                                icon={
                                                    <img
                                                        src={topo}
                                                        className='h-10'
                                                        alt='topology-icon'
                                                    />
                                                }
                                                selected={
                                                    values.simulationType ===
                                                    "topology"
                                                }
                                            />
                                            <div
                                                className='group relative pointer-events-none select-none opacity-50'
                                                title='Available Soon'
                                            >
                                                <SimulationSelectionCard
                                                    caption='Shape'
                                                    name='simulationType'
                                                    value='shape'
                                                    // eslint-disable-next-line jsx-a11y/alt-text
                                                    icon={
                                                        <img
                                                            src={shape}
                                                            className='h-10'
                                                            alt='shape-icon'
                                                        />
                                                    }
                                                    selected={
                                                        values.simulationType ===
                                                        "shape"
                                                    }
                                                />
                                                <div className='absolute bg-gray-100 p-1 top-0 w-full text-center rounded-md'>
                                                    <p className='text-xs text-red-400'>
                                                        Available Soon
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className='text-sm pt-2'>
                                            Project Name
                                        </p>
                                        <div className='relative'>
                                            <FormikTextField
                                                id='projectName'
                                                type='text'
                                                name='projectName'
                                                placeholder='Enter the project name'
                                                border='true'
                                                icon={HiOutlineMail}
                                                light={true}
                                            />
                                            <ErrorMessage name='projectName'>
                                                {(msg) => (
                                                    <div className='absolute right-3 top-3 text-red-600 cursor-help'>
                                                        <AiFillWarning
                                                            onClick={() =>
                                                                toast(msg)
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                    <p className='text-sm'>Upload the file</p>
                                    <div className='fileUpload relative overflow-hidden w-full p-2 border-2 flex justify-evenly items-center border-dotted border-gray-200 rounded-xl'>
                                        <div className='relative'>
                                            <label
                                                htmlFor='msh-file-input'
                                                className=' cursor-pointer flex flex-col items-center justify-center opacity-80'
                                            >
                                                <p className=' w-60 text-center'>
                                                    {values.mshFile
                                                        ? values.mshFile.name
                                                        : "Upload .inp File"}
                                                </p>
                                                <img
                                                    className='p-4 opacity-75'
                                                    src={inpImg}
                                                    alt={"mshimg"}
                                                />
                                            </label>
                                            <input
                                                className='absolute top-0 w-full border-2 border-gray-200 h-full hidden'
                                                id='msh-file-input'
                                                name='mshFile'
                                                type='file'
                                                accept='.inp'
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "mshFile",
                                                        event.target.files[0],
                                                    );
                                                }}
                                                required
                                            />
                                        </div>
                                        <div
                                            className=' absolute left-0 bg-red-400 h-full opacity-10'
                                            style={{
                                                width: `calc(${
                                                    mshUploadProgress +
                                                    geoUploadProgress
                                                }%)`,
                                            }}
                                        ></div>
                                    </div>
                                    {/* <FileUplad /> */}
                                    <div
                                        className={`${
                                            desc === "Create"
                                                ? " invisible"
                                                : ""
                                        } flex justify-center`}
                                    >
                                        <LoadingSpin text={desc} />
                                    </div>
                                    <div className='flex flex-row-reverse justify-center gap-12'>
                                        <button
                                            className={`${
                                                desc !== "Create"
                                                    ? " opacity-50 cursor-wait"
                                                    : ""
                                            } px-12 w-40 whitespace-nowrap text-sm bg-iris-blue-500 text-white rounded-md hover:bg-iris-blue-600`}
                                            type='submit'
                                            disabled={desc !== "Create"}
                                        >
                                            {renderButtonText()}
                                        </button>
                                        <button
                                            onClick={closeProjectHandle}
                                            className='px-16 py-2 text-sm bg-gray-200 text-gray-700 w-40 rounded-md hover:bg-gray-300'
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {desc === "Next" && (
                        <div className=' w-full h-full'>
                            <OptimizationConfigs
                                project={createdProjectData}
                                initData={fileData}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateProject;
