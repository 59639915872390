import { useState, useEffect } from "react";
import { FiBell } from "react-icons/fi";

function MasterNotificationToggle({ visible, onClose, notifications }) {
    const [expandedNotification, setExpandedNotification] = useState(null);

    const toggleNotification = (notificationId) => {
        setExpandedNotification(
            expandedNotification === notificationId ? null : notificationId,
        );
    };

    useEffect(() => {
        let timeoutId;
        const handleMouseEnter = () => {
            clearTimeout(timeoutId);
        };
        const handleMouseLeave = () => {
            timeoutId = setTimeout(() => {
                onClose();
            }, 5000);
        };

        const popup = document.getElementById("notification-popup");
        if (popup) {
            popup.addEventListener("mouseenter", handleMouseEnter);
            popup.addEventListener("mouseleave", handleMouseLeave);
        }

        return () => {
            if (popup) {
                popup.removeEventListener("mouseenter", handleMouseEnter);
                popup.removeEventListener("mouseleave", handleMouseLeave);
            }
            clearTimeout(timeoutId);
        };
    }, [visible, onClose]);

    return (
        <div
            id='notification-popup'
            className={`absolute right-0 mt-4 w-52 bg-white rounded-lg shadow-lg py-1 ${
                visible ? "block" : "hidden"
            }`}
        >
            <div className='max-h-64 overflow-y-auto'>
                {notifications.map((notification) => (
                    <div key={notification.id}>
                        <div
                            className='flex items-center px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white cursor-pointer'
                            onClick={() => toggleNotification(notification.id)}
                        >
                            <FiBell className='mr-4 ml-2' />
                            {notification.title}
                        </div>
                        {expandedNotification === notification.id && (
                            <div className='px-4 py-2 text-gray-700 bg-gray-100'>
                                <p>{notification.description}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MasterNotificationToggle;
