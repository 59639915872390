import { Formik, Form } from "formik";
import { TbIdBadge2 } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import { MdFormatListNumbered } from "react-icons/md";
import { CgOrganisation } from "react-icons/cg";
import { RiPassExpiredLine } from "react-icons/ri";
import { FaUserCheck } from "react-icons/fa6";
import { FormikDoubleTextField } from "../../../components/FormComponents/FormikDoubleTextField";
import { getSelfData } from "../Global/DashboardSlice";
import { useSelector } from "react-redux";

const AccountDetails = () => {
    const userData = useSelector(getSelfData);

    if (!userData) {
        return (
            <div className='flex justify-center mt-10'>No data available</div>
        );
    }

    const formatDate = (dateString) => {
        const options = { day: "numeric", month: "long", year: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const initialUserId = userData.id ? String(userData.id) : "";
    const loginType = localStorage.getItem("login-type");
    const idLabel = "ID";
    const tenantIdLabel = loginType === "user" ? "User ID" : "Admin ID";

    return (
        <div className='flex flex-col overflow-hidden px-10 md:px-24 pt-2'>
            <h1 className='text-xl mb-6 mt-4'>Account Details</h1>
            <Formik
                initialValues={{
                    id: initialUserId,
                    email: userData.email,
                    first_name: userData.first_name,
                    expiry_time: formatDate(userData.expiry_time),
                    joined_date: formatDate(userData.joined_date),
                    tenantid: userData.tenantid
                        ? String(userData.tenantid)
                        : String(userData.tenant_id),
                }}
                onSubmit={() => {}}
            >
                {() => (
                    <Form>
                        <FormikDoubleTextField
                            label1={idLabel}
                            label2='Email'
                            name1='id'
                            name2='email'
                            placeholder1={idLabel}
                            placeholder2='Email'
                            icon1={TbIdBadge2}
                            icon2={HiOutlineMail}
                            light={true}
                            value1={initialUserId}
                            value2={userData.email}
                            disabled={true}
                            disabled2={true}
                        />
                        <FormikDoubleTextField
                            label1='First Name'
                            label2={tenantIdLabel}
                            name1='first_name'
                            name2='tenantid'
                            placeholder1='First Name'
                            placeholder2={tenantIdLabel}
                            icon1={FaUserCheck}
                            icon2={MdFormatListNumbered}
                            light={true}
                            border={true}
                            value1={userData.first_name}
                            value2={
                                userData.tenantid
                                    ? String(userData.tenantid)
                                    : ""
                            }
                            disabled={true}
                            disabled2={true}
                        />
                        <FormikDoubleTextField
                            label1=<p className=' flex justify-between'>
                                Expiry Date
                            </p>
                            name1='expiry_time'
                            placeholder1='Expiry Date'
                            label2='Joined Date'
                            name2='joined_date'
                            placeholder2='Joined Date'
                            icon1={RiPassExpiredLine}
                            icon2={CgOrganisation}
                            light={true}
                            border={true}
                            value1={formatDate(userData.expiry_time)}
                            value2={formatDate(userData.joined_date)}
                            disabled={true}
                            disabled2={true}
                        />
                        <FormikDoubleTextField
                            label1='Organization Name'
                            name1='organization_name'
                            placeholder1='Expiry Date'
                            label2='Joined Date'
                            name2='joined_date'
                            placeholder2='Joined Date'
                            icon1={CgOrganisation}
                            icon2={RiPassExpiredLine}
                            light={true}
                            border={true}
                            value1={userData.organization_name}
                            value2={formatDate(userData.joined_date)}
                            disabled={true}
                            disabled2={true}
                            hidden2={true}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AccountDetails;
