import { useField } from "formik";

const FormikTextField = ({
    label,
    icon: IconComponent,
    style,
    border = false,
    light,
    query = false,
    ...props
}) => {
    const [field, meta, helpers] = useField(props);
    const value = field.value != null ? field.value : "";

    const labelColor = light ? "text-black" : "text-gray-200";
    const borderColor = light ? "border-gray-300" : "border-transparent";
    const focusBorderColor = light ? "border-gray-500" : "border-gray-600";
    const placeholderColor = light
        ? "placeholder-gray-500"
        : "placeholder-gray-400";

    const inputStyle = {
        width: style ? style.width || "100%" : "100%",
        ...style,
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        margin: 0,
        fontSize: "normal",
        boxShadow: "none",
        border: border ? `1px solid ${borderColor}` : "none",
        height: query ? "6.5rem" : "2.5rem",
    };

    return (
        <div className='mb-7'>
            <label
                htmlFor={props.id || props.name}
                className={`block text-sm mb-2 ${labelColor}`}
            >
                {label}
            </label>
            <div className='relative'>
                {IconComponent && (
                    <IconComponent className='absolute left-3 top-2 w-6 h-6 text-black' />
                )}
                <input
                    type='text'
                    id={props.id || props.name}
                    className={`bg-white bg-opacity-70 border ${borderColor} text-black text-sm rounded-lg focus:outline-none focus:${focusBorderColor} block pl-16 pr-8 py-2.5 ${placeholderColor} dark:bg-transparent dark:${placeholderColor} dark:focus:outline-none dark:${borderColor} dark:focus:border-transparent`}
                    {...field}
                    {...props}
                    value={value}
                    onChange={(e) => helpers.setValue(e.target.value)}
                    placeholder={props.placeholder}
                    style={inputStyle}
                />
            </div>
            {meta.touched && meta.error ? (
                <div className='absolute text-red-500 text-xs italic pt-1 float-left'>
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
};

export { FormikTextField };
