import ModellingImg from "../assets/car/geometry.png";
import SetupImg from "../assets/car/geometry.png";
import MeshImg from "../assets/car/mesh.png";
import thermoImg from "../assets/car/result.png";
import thermoGreenImg from "../assets/car/result.png";

export const SimulationWorkSpaceContext = [
    {
        route: "/simulation-workspace/modelling",
        image: ModellingImg,
        sideBar: [
            {
                name: "Geometry",
                // icon: <FaSignal />,
                id: "MDSB000",
                dropdown: false,
                dropdownList: [{ name: "Solid" }, { name: "Liquid" }],
            },
            {
                name: "Co-ordinate Systems",
                // icon: <FaChess />,
                id: "MDSB001",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Connections",
                // icon: <FaDiceD20 />,
                id: "MDSB002",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Named Selections",
                // icon: <FaDiceD6 />,
                id: "MDSB003",
                dropdown: false,
                dropdownList: [],
            },
        ],
    },
    {
        route: "/simulation-workspace/setup",
        image: SetupImg,
        sideBar: [
            {
                name: "Methods",
                // icon: <FaFill />,
                id: "SESB000",
                dropdown: false,
                dropdownList: [],
            },
            // {
            //   name: "Multiphysics",
            //   // icon: <FaDiceD20 />,
            //   id: "SESB001",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            {
                name: "Material",
                // icon: <FaDna />,
                id: "SESB002",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Domain",
                // icon: <FaEquals />,
                id: "SESB003",
                dropdown: false,
                dropdownList: [],
            },
            // {
            //   name: "Initial Conditions",
            //   // icon: <FaEgg />,
            //   id: "SESB007",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            {
                name: "Boundary Conditions",
                // icon: <FaEgg />,
                id: "SESB004",
                dropdown: false,
                dropdownList: [],
            },
            // {
            //   name: "Constraints",
            //   // icon: <FaEject />,
            //   id: "SESB005",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Loads/External Source",
            //   // icon: <FaFillDrip />,
            //   id: "SESB006",
            //   dropdown: false,
            //   dropdownList: [],
            // },
        ],
    },
    {
        route: "/simulation-workspace/mesh",
        image: MeshImg,
        sideBar: [
            {
                name: "Method",
                // icon: <FaFill />,
                id: "MESB000",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Sizing",
                // icon: <FaFlagCheckered />,
                id: "MESB001",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Contact Sizing",
                // icon: <FaFireAlt />,
                id: "MESB002",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Refinement",
                // icon: <FaFingerprint />,
                id: "MESB003",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Mapped Face Meshing",
                // icon: <FaHotdog />,
                id: "MESB004",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Match Control",
                // icon: <FaLayerGroup />,
                id: "MESB005",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Pinch",
                // icon: <FaLightbulb />,
                id: "MESB006",
                dropdown: false,
                dropdownList: [],
            },
            {
                name: "Inflation",
                // icon: <FaLink />,
                id: "MESB007",
                dropdown: false,
                dropdownList: [],
            },
        ],
    },
    {
        route: "/simulation-workspace/simulation",
        image: thermoGreenImg,
        sideBar: [
            // {
            //   name: "Initialization",
            //   // icon: <FaTerminal />,
            //   id: "SMSB000",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // { name: "Method", icon: <FaFill /> },
            // {
            //   name: "Controls",
            //   // icon: <FaSignature />,
            //   id: "SMSB001",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Report Definition",
            //   // icon: <FaSink />,
            //   id: "SMSB002",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Monitors",
            //   // icon: <FaSitemap />,
            //   id: "SMSB003",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Cell Registers",
            //   // icon: <FaSwatchbook />,
            //   id: "SMSB004",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Calculation Activity",
            //   // icon: <FaSpaceShuttle />,
            //   id: "SMSB005",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Run Calculation",
            //   // icon: <FaSquareRootAlt />,
            //   id: "SMSB006",
            //   dropdown: false,
            //   dropdownList: [],
            // },
        ],
    },
    {
        route: "/simulation-workspace/optimization",
        image: thermoGreenImg,
        sideBar: [
            // {
            //   name: "Initialization",
            //   // icon: <FaTerminal />,
            //   id: "SMSB000",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // { name: "Method", icon: <FaFill /> },
            {
                name: "Configuration",
                // icon: <FaSignature />,
                id: "OPT001",
                dropdown: true,
                dropdownList: [],
            },
        ],
    },
    {
        route: "/simulation-workspace/result",
        image: thermoImg,
        sideBar: [
            // {
            //   name: "Graphics",
            //   // icon: <FaSyringe />,
            //   id: "RESB000",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Plots",
            //   // icon: <FaTabletAlt />,
            //   id: "RESB001",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Animations",
            //   // icon: <FaThermometerQuarter />,
            //   id: "RESB002",
            //   dropdown: false,
            //   dropdownList: [],
            // },
            // {
            //   name: "Reports",
            //   // icon: <FaThumbtack />,
            //   id: "RESB003",
            //   dropdown: false,
            //   dropdownList: [],
            // },
        ],
    },
];
