import { useState } from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import { FormikTextField } from "../../../components/FormComponents/FormikTextField";
import { baseUrl } from "../../BaseUrl";
import { LiaIdCardSolid } from "react-icons/lia";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { updateUsers } from "../Global/DashboardSlice";

const AddUser = ({ prev, users }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const initialValues = {
        name: "",
        email: "",
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .max(40, "Must be 40 characters or less")
            .matches(
                /^[a-zA-Z0-9_-]*$/,
                "Only hyphens and underscores allowed, no spaces",
            )
            .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
    });

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                baseUrl + "user/signup",
                { ...values, first_name: values.name },
                {
                    withCredentials: true,
                },
            );
            try {
                const responseUser = await axios.get(baseUrl + "listuser", {
                    withCredentials: true,
                });
                dispatch(updateUsers(responseUser.data));
            } catch (error) {
                toast.error("Unable to load user projects! (Server error)");
            }

            toast.success(response.data.detail, { duration: 4000 });

            prev(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                const errorMessage = Object.values(error.response.data).join(
                    ", ",
                );
                toast.error(errorMessage || "Credential Error");
            } else {
                toast.error("Connection Error!");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form className='px-4 py-6 bg-white shadow-xl rounded-2xl'>
                <h1 className='text-xl'>Add User</h1>
                <div className='my-4'>
                    <FormikTextField
                        label='Name'
                        name='name'
                        type='text'
                        placeholder='Full Name'
                        icon={HiOutlineMail}
                        light={true}
                    />
                </div>
                <div className='mb-4'>
                    <FormikTextField
                        label='Email ID'
                        name='email'
                        type='email'
                        placeholder='Johndoe@companyname.com'
                        icon={LiaIdCardSolid}
                        light={true}
                    />
                </div>
                <div className='flex gap-10 justify-center items-center'>
                    <button
                        type='submit'
                        className={`${
                            isLoading ? " pointer-events-none" : ""
                        } mt-2 text-lg transition-all shadow-xl bg-iris-blue-500 px-5 text-white rounded-2xl h-12 w-48 font-medium
                  hover:bg-iris-blue-800 hover:shadow-md
              } `}
                    >
                        {isLoading ? "Adding User..." : "Add User"}
                    </button>
                    <button
                        onClick={() => prev(false)}
                        type='close'
                        className='relative bg-gray-200 text-black top-1 text-md transition-all shadow-xl px-5 rounded-2xl h-12 w-48 font-medium 
                hover:bg-gray-300 hover:shadow-md'
                    >
                        {"Close"}
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

AddUser.propTypes = {
    prev: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AddUser;
