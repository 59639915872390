import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { TbIdBadge2 } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import { MdFormatListNumbered } from "react-icons/md";
import { CgOrganisation } from "react-icons/cg";
import { RiPassExpiredLine } from "react-icons/ri";
import { FaUserCheck } from "react-icons/fa6";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
import { FormikDoubleTextField } from "../../../components/FormComponents/FormikDoubleTextField";

const AccountDetailsMaster = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const getEndpoint = () => {
        return "client/login";
    };

    useEffect(() => {
        const fetchData = async () => {
            const endpoint = getEndpoint();
            if (endpoint) {
                try {
                    const response = await axios.get(`${baseUrl}${endpoint}`, {
                        withCredentials: true,
                    });
                    setUserData(response.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userData) {
        return <div>No data available</div>;
    }

    const formatDate = (dateString) => {
        const options = { day: "numeric", month: "long", year: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const initialUserId = userData.id ? String(userData.id) : "";

    return (
        <div className='flex flex-col overflow-hidden pl-24 pr-24 pt-2'>
            <h1 className='text-xl mb-6 mt-4'>Account Details</h1>
            <Formik
                initialValues={{
                    id: initialUserId,
                    email: userData.company_emailid,
                    client_id: userData.client_id
                        ? String(userData.client_id)
                        : "",
                    joined_date: formatDate(userData.joined_date),
                    last_login: formatDate(userData.last_login),
                }}
                onSubmit={() => {}}
            >
                {() => (
                    <Form>
                        <FormikDoubleTextField
                            label1='ID'
                            label2='Email'
                            name1='id'
                            name2='email'
                            placeholder1='User ID'
                            placeholder2='Email'
                            icon1={TbIdBadge2}
                            icon2={HiOutlineMail}
                            light={true}
                            value1={initialUserId}
                            value2={userData.company_emailid}
                            disabled={true}
                        />
                        <FormikDoubleTextField
                            label1='Client ID'
                            label2='Company Name'
                            name1='client_id'
                            name2='company_name'
                            placeholder1='Client ID'
                            placeholder2='Company Name'
                            icon1={MdFormatListNumbered}
                            icon2={CgOrganisation}
                            light={true}
                            border={true}
                            value1={String(userData.client_id)}
                            value2={userData.company_name}
                            disabled={true}
                        />
                        <FormikDoubleTextField
                            label1='Joined Date'
                            name1='joined_date'
                            placeholder1='Joined Date'
                            label2='Last Login'
                            name2='last_login'
                            placeholder2='Last Login'
                            icon1={RiPassExpiredLine}
                            icon2={FaUserCheck}
                            light={true}
                            border={true}
                            value1={formatDate(userData.joined_date)}
                            value2={formatDate(userData.last_login)}
                            disabled={true}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AccountDetailsMaster;
