import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { FormikTextField } from "../../FormComponents/FormikTextField";
import axios from "axios";
import { baseUrl } from "../../../routes/BaseUrl";
import toast from "react-hot-toast";
import { MdFormatListNumbered } from "react-icons/md";
import { FaReact } from "react-icons/fa6";
import { LiaIdCardSolid } from "react-icons/lia";
import { RiPassExpiredLine } from "react-icons/ri";
import { LoadingSpin } from "../../../routes/Dashboard/components/Loading";

const Masterplan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [allPlan, setAllPlan] = useState(null);
    useEffect(() => {
        const fetchPlan = async () => {
            // let ids = [];
            try {
                let response = await axios.get(baseUrl + "plan", {
                    withCredentials: true,
                });
                setAllPlan(response.data);
            } catch (error) {
                toast.error("Unable to fetch User(s) data");
            }
        };
        fetchPlan();
    }, []);
    const initialValues = {
        plan_name: "",
        simulations: "",
        user_no: "",
        expiry_days: "",
    };
    const handleSubmit = async (values) => {
        if (values.expiry_days < 1) {
            toast.error("Expiry days must be greater than zero.");
            return;
        }
        setIsLoading(true);
        setFlag(true);
        try {
            let response = await axios.post(baseUrl + "plan", values, {
                withCredentials: true,
            });
            toast.success("You've successfully added the plan");
            alert("Your plan id = " + response.data.id);
            setAllPlan([...allPlan, response.data]);
            localStorage.setItem("id", response.data.id);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.detail);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        document.getElementById("createAdmin").click();
    };
    return (
        <div className=' h-full overflow-x-scroll scrollbar-thin'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                    {/* <div className='mb-4 bg-white bg-opacity-30 justify-center'> */}
                    <div className=' bg-white bg-opacity-30 item-center flex flex-col justify-evenly md:mx-48 lg:mx-80'>
                        <FormikTextField
                            label='Plan Name'
                            name='plan_name'
                            type='plan_name'
                            placeholder='ABC'
                            light={true}
                            border={true}
                            icon={LiaIdCardSolid}
                        />
                        <FormikTextField
                            label='Simulations'
                            name='simulations'
                            type='simulations'
                            placeholder='ABC'
                            light={true}
                            border={true}
                            icon={FaReact}
                        />
                        <FormikTextField
                            label='Number Of Users'
                            name='user_no'
                            type='number'
                            placeholder='1'
                            light={true}
                            border={true}
                            icon={MdFormatListNumbered}
                        />
                        <FormikTextField
                            label='Expiry Days'
                            name='expiry_days'
                            type='number'
                            placeholder='1'
                            light={true}
                            border={true}
                            icon={RiPassExpiredLine}
                        />

                        <div className=' flex justify-around items-center'>
                            <button
                                type='submit'
                                className={`text-sm transition-all shadow-2xl bg-iris-blue-500 text-white rounded p-2 px-8 font-medium ${
                                    flag
                                        ? "hover:bg-gray-500 disabled:opacity-100 cursor-not-allowed"
                                        : "hover:bg-iris-blue-800 hover:opacity-80 hover:shadow-md"
                                }`}
                                disabled={flag}
                            >
                                {isLoading ? "Adding ..." : "Add Plan"}
                            </button>
                            <button
                                onClick={handleClose}
                                type='button'
                                className={`text-sm transition-all shadow-2xl bg-iris-blue-500 text-white rounded p-2 px-8 font-medium ${
                                    flag
                                        ? "hover:bg-gray-500 disabled:opacity-100 cursor-not-allowed"
                                        : "hover:bg-iris-blue-800 hover:opacity-80 hover:shadow-md"
                                }`}
                                disabled={flag}
                            >
                                {"Close"}
                            </button>
                        </div>
                    </div>
                </Form>
            </Formik>
            <hr className=' mt-4' />
            <>
                {allPlan === null ? (
                    <div className=' flex justify-center'>
                        <LoadingSpin text={"Loading Plans"} />
                    </div>
                ) : (
                    <>
                        <p className=' text-center m-4'>All Plans</p>
                        <div className=' flex flex-wrap gap-4 justify-around'>
                            {allPlan
                                .slice()
                                .reverse()
                                .map((item, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className=' w-40 text-xs bg-gray-200 p-2 rounded-md shadow-lg'
                                        >
                                            <div className=' flex justify-between flex-wrap mb-2'>
                                                <p className=' text-sm opacity-80 font-bold bg-white rounded-md p-1 w-min whitespace-nowrap '>
                                                    ID : {item.id}
                                                </p>
                                                <p className=' text-sm opacity-80 font-bold bg-red-400 text-white rounded-md p-1 px-2 w-min whitespace-nowrap '>
                                                    {item.expiry_days} Days
                                                </p>
                                            </div>
                                            <p className=' flex justify-between opacity-90'>
                                                Name :{" "}
                                                <span className=' w-24 whitespace-nowrap text-right overflow-scroll scrollbar-none'>
                                                    {item.plan_name}
                                                </span>
                                            </p>
                                            <p className=' flex justify-between opacity-90'>
                                                Simulation :{" "}
                                                <span className=' w-16 whitespace-nowrap text-right overflow-scroll scrollbar-none'>
                                                    {item.simulations}
                                                </span>
                                            </p>
                                            <p className=' flex justify-between opacity-90'>
                                                Max User :{" "}
                                                <span>{item.user_no}</span>
                                            </p>
                                            <p className=' flex justify-between opacity-90'>
                                                Expiry Days :{" "}
                                                <span>{item.expiry_days}</span>
                                            </p>
                                        </div>
                                    );
                                })}
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export default Masterplan;
