import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackgroundPage } from "../../../routes/BackgroundPage";
import bqp_logo from "../../../assets/images/bqphy-light.png";
import CarBackground from "../../../assets/car/CarBackground.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { FormikTextField } from "../../FormComponents/FormikTextField";
import toast from "react-hot-toast";
import { useClientloginMutation } from "../../../services/auth/authApi";
import { useLocalAuth } from "../../../hooks/useLocalAuth";
import { setCredentials } from "../../../services/auth/authSlice";

const decodeJWT = (token) => {
    try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(
                    (c) =>
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2),
                )
                .join(""),
        );

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Error decoding JWT:", error.message);
        return null;
    }
};

const CustomPasscodeInput = ({ field, form, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (e) => {
        const maxLength = parseInt(e.target.getAttribute("maxlength"), 10);
        const currentInput = e.target;
        const nextInput = currentInput.nextElementSibling;
        const prevInput = currentInput.previousElementSibling;
        const value = e.target.value;

        if (e.key === "Backspace" && value.length === 0 && prevInput) {
            prevInput.focus();
        } else {
            form.setFieldValue(field.name, value);

            if (value.length === 0 && e.key === "Backspace" && prevInput) {
                prevInput.focus();
            } else if (value.length === 0 && e.key === "Delete" && nextInput) {
                nextInput.focus();
            } else if (value.length >= maxLength && nextInput) {
                nextInput.focus();
            }
        }
    };

    return (
        <input
            {...field}
            {...props}
            className={`w-12 h-12 bg-transparent text-white text-center outline-none ${
                isFocused
                    ? "border-b-2 border-iris-blue-500"
                    : "border-b-2 border-gray-200"
            }`}
            maxLength={1}
            value={field.value || ""}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleInputChange}
        />
    );
};

export const MasterLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login, { isLoading }] = useClientloginMutation();
    const { setAuthTokenWithExpiry } = useLocalAuth();

    return (
        <BackgroundPage
            imgStyle={"px-6 py-10 h-full bg-no-repeat bg-left"}
            logo={bqp_logo}
            backgroundImage={CarBackground}
        >
            <div className='p-4 rounded-lg shadow-lg bg-black bg-opacity-20 backdrop-filter backdrop-blur-md'>
                <p className='text-sm text-white text-center tracking-wide'>
                    Master Login
                </p>
                <hr className='opacity-50 my-2' />
                <Formik
                    initialValues={{
                        company_emailid: "",
                        password: "",
                        client_id: "",
                        otp: ["", "", "", "", "", ""],
                    }}
                    validationSchema={Yup.object({
                        company_emailid: Yup.string()
                            .email("Invalid email address")
                            .required("Email address is required"),
                        password: Yup.string().required("Password is required"),
                        client_id: Yup.string()
                            .matches(/^\d+$/, "ID must be a number")
                            .required("Client ID is required"),
                        otp: Yup.array()
                            .of(Yup.string().required("Passcode is required"))
                            .min(6, "Passcode must be exactly 6 characters")
                            .max(6, "Passcode must be exactly 6 characters"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            const otp = values.otp.join("");
                            const updatedValues = { ...values, otp };
                            const response = await login(updatedValues);
                            const { data, error } = response;
                            if (data) {
                                const decodedToken = decodeJWT(data.jwt);
                                localStorage.setItem("jwt-id", decodedToken.id);
                                dispatch(setCredentials(data));
                                setAuthTokenWithExpiry(data.jwt);
                                toast.success("You've successfully logged in");
                                localStorage.setItem(
                                    "login-type-master",
                                    "master",
                                );
                                navigate("/master/dashboard");
                            } else if (error) {
                                const { detail } = error.data || {};
                                if (detail) {
                                    toast.error(detail);
                                } else {
                                    toast.error(
                                        "An unexpected error occurred. Please try again.",
                                    );
                                }
                            }
                        } catch (err) {
                            toast.error(
                                "An error occurred during login. Please try again.",
                            );
                            console.error("Login error:", err);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <div className='mb-4'>
                                <FormikTextField
                                    label='ID'
                                    name='client_id'
                                    type='text'
                                    placeholder='273634746'
                                    icon={FiLock}
                                    autoFocus={true}
                                    inputMode='numeric'
                                    pattern='\d*'
                                />
                            </div>
                            <div className='mb-4'>
                                <FormikTextField
                                    label='Email'
                                    name='company_emailid'
                                    type='email'
                                    placeholder='johndoe@company.com'
                                    icon={HiOutlineMail}
                                />
                            </div>
                            <div className='mb-4'>
                                <FormikTextField
                                    label='Password'
                                    name='password'
                                    type='password'
                                    placeholder='*********'
                                    icon={FiLock}
                                />
                            </div>
                            <div className='mb-4'>
                                <label
                                    htmlFor='otp'
                                    className='block mb-2 text-sm text-white'
                                >
                                    Authentication Passcode
                                </label>
                                <div
                                    className='grid grid-cols-6 gap-2 text-black'
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        const pastedText = e.clipboardData
                                            .getData("text/plain")
                                            .split("");
                                        if (pastedText.length === 6) {
                                            pastedText.forEach(
                                                (element, idx) => {
                                                    setFieldValue(
                                                        `otp.${idx}`,
                                                        element,
                                                    );
                                                },
                                            );
                                        }
                                    }}
                                >
                                    {Array.from({ length: 6 }).map(
                                        (_, index) => (
                                            <Field
                                                key={index}
                                                name={`otp.${index}`}
                                                type='text'
                                                component={CustomPasscodeInput}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <button
                                    type='submit'
                                    className={`mt-2 text-lg transition-all shadow-2xl bg-iris-blue-500 px-5 text-white rounded h-12 w-56 font-medium
                  hover:bg-iris-blue-800 hover:text-black hover:shadow-md`}
                                >
                                    {isLoading ? "Loading..." : "Login"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </BackgroundPage>
    );
};
