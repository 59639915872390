import { useState, useEffect } from "react";
import { FormikTextField } from "../../components/FormComponents/FormikTextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useLocalAuth } from "../../hooks/useLocalAuth";
import { setkey } from "../../services/auth/keySlice";
import {
    useClientConfirmPasswordMutation,
    useAdminConfirmPasswordMutation,
    useConfirmPasswordMutation,
} from "../../services/auth/authApi";
import { MainText } from "../../components/FormComponents/MainText";
import { FiLock } from "react-icons/fi";

const ConfirmPasswordWidget = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getKeyWithExpiry } = useLocalAuth();
    const [token, setToken] = useState(null);
    const [confirmationType, setConfirmationType] = useState(null);

    const key = getKeyWithExpiry("KeyBQP");
    if (key) {
        dispatch(setkey(key));
    }

    const [mutation, setMutation] = useState(() => {
        switch (confirmationType) {
            case "client-confirm":
                return useClientConfirmPasswordMutation;
            case "tenant-confirm":
                return useAdminConfirmPasswordMutation;
            default:
                return useConfirmPasswordMutation;
        }
    });

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const tokenFromURL = parts[parts.length - 2];
        const confirmationTypeFromURL = parts[parts.length - 1];

        if (tokenFromURL && confirmationTypeFromURL) {
            setToken(tokenFromURL);
            setConfirmationType(confirmationTypeFromURL);
        } else {
            toast.error(
                "Token or confirmation type is not available. Please refresh the page or try the URL again.",
            );
        }
    }, []);

    useEffect(() => {
        const mutationMap = {
            "tenant-confirm": useAdminConfirmPasswordMutation,
        };

        setMutation(
            () => mutationMap[confirmationType] || useConfirmPasswordMutation,
        );
    }, [confirmationType]);

    const [confirmPassword, { isLoading }] = mutation();

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await confirmPassword({
                password: values.new_password || values.password,
                token: token,
            });

            if (response.data) {
                dispatch(setkey(response.data));
                navigate("/login");
                toast.success("Your password has been updated successfully.");
            } else {
                handleError(response.error);
            }
        } catch (error) {
            console.error("Error during password update:", error);
            toast.error("An unexpected error occurred. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    const handleError = (error) => {
        if (error?.data) {
            const { email, detail } = error.data;
            if (email) {
                toast.error(`Error: ${email.join(", ")}`);
            } else if (detail) {
                toast.error(`Error: ${detail}`);
            } else {
                toast.error("An error occurred. Please try again.");
            }
        } else {
            toast.error("An unexpected error occurred. Please try again.");
        }
    };

    return (
        <>
            <MainText
                className='font-bold text-white -top-10'
                name='Reset your password here'
            />
            <Formik
                initialValues={{ password: "", new_password: "" }}
                validationSchema={Yup.object({
                    password: Yup.string()
                        .min(8, "Must be 8 characters or more")
                        .required("Required"),
                    new_password: Yup.string()
                        .min(8, "Must be 8 characters or more")
                        .required("Required"),
                })}
                onSubmit={handleSubmit}
            >
                <Form>
                    <FormikTextField
                        label='New Password'
                        name='password'
                        type='password'
                        placeholder='*********'
                        icon={FiLock}
                    />
                    <FormikTextField
                        label='Confirm New Password'
                        name='new_password'
                        type='password'
                        placeholder='*********'
                        icon={FiLock}
                    />
                    <div className='flex'>
                        <button
                            className='bg-iris-blue-500 text-md font-medium hover:bg-iris-blue-800 text-white px-5 rounded h-12 w-full hover:text-black'
                            type='submit'
                        >
                            {isLoading ? "Loading...." : "Submit"}
                        </button>
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export { ConfirmPasswordWidget };
