
import { UserSignup } from "./UserSignup";

const SignUpWidgetUser = () => {
    return (
        <>
            <div className='flex items-center justify-center'>
                <UserSignup />
            </div>
        </>
    );
};

export { SignUpWidgetUser };
