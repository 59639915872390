import { useState, useEffect } from "react";
import { LuUserCheck, LuUserX } from "react-icons/lu";
import { FaAngleDown } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import MiniCard from "../components/MiniCard";
import MoreUser from "./MoreUser";
import { getUsers, updateUsers, getSelfData } from "../Global/DashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import Name from "../components/Name";
import AddUser from "./AddUser";
import ExpiredPage from "../components/ExpiredPage";
let sortVar = { name: true, user_id: true, is_active: true, projects: true };

const Status = (status, lastlogin) => {
    if (status === true || status === "True") return <p>🟢 Active</p>;
    else {
        return <p>🔴 In Active</p>;
    }
};

const ManageUser = () => {
    const [showAdd, setShowAdd] = useState(false);
    const users = useSelector(getUsers);
    const selfData = useSelector(getSelfData);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const sortOn = (key) => {
        let on;
        data.sort((a, b) => {
            const nameA = a[key].toUpperCase();
            const nameB = b[key].toUpperCase();
            on = sortVar[key];
            if (nameA < nameB) {
                return on ? -1 : 1;
            }
            if (nameA > nameB) {
                return on ? 1 : -1;
            }
            return 0;
        });
        sortVar[key] = !on;
        setData([...data]);
    };

    const handleAddUser = () => {
        setShowAdd(!showAdd);
    };

    useEffect(() => {
        setData([...users.total]);
    }, [users]);

    useEffect(() => {
        if (!dispatch) {
            dispatch(updateUsers());
        }
    }, [showAdd, dispatch]);

    return (
        <>
            <div className='flex flex-col overflow-hidden p-4'>
                <div className='flex flex-wrap justify-between items-center'>
                    <Name />
                    <div className='flex flex-wrap gap-4 items-center'>
                        <MiniCard
                            icon={<LuUserCheck className='h-full w-full' />}
                            title='Active Users'
                            value={users.active.length}
                            bg='bg-green-200'
                        />
                        <MiniCard
                            icon={<LuUserX className='h-full w-full' />}
                            title='In-Active Users'
                            value={users.total.length - users.active.length}
                            bg=' bg-pink-200'
                        />
                    </div>
                </div>
                {!data.length ? (
                    <div className='flex flex-col items-center justify-center mt-10 mx-4 gap-5'>
                        <h1>
                            <i>No User</i> has been added to this account
                        </h1>
                        <div className='flex justify-center'>
                            <button
                                onClick={handleAddUser}
                                className='flex item items-center text-2xl px-4 py-2 rounded-md whitespace-nowrap justify-center w-min  gap-2 bg-black text-white cursor-pointer'
                            >
                                <IoMdAddCircleOutline />
                                <p className=' text-sm'>Add User </p>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className='border border-gray rounded-xl mt-10 h-full mx-4 overflow-y-auto'>
                        <table className='border-collapse w-full text-sm table-fixed'>
                            <thead className=' bg-white sticky top-0 shadow-sm'>
                                <tr>
                                    <th
                                        onClick={() => sortOn("name")}
                                        className=' cursor-pointer border-b-2 font-medium p-4 md:pl-8 text-slate-400 text-left '
                                    >
                                        <div className='flex item items-center gap-2'>
                                            <p>Name </p>
                                            <FaAngleDown />
                                        </div>
                                    </th>
                                    <th
                                        onClick={() => sortOn("user_id")}
                                        className=' cursor-pointer border-b-2 font-medium p-4 text-slate-400 text-left'
                                    >
                                        <div className='flex item items-center gap-2'>
                                            <p>User ID</p>
                                            <FaAngleDown />
                                        </div>
                                    </th>
                                    <th
                                        onClick={() => sortOn("is_active")}
                                        className='cursor-pointer border-b-2 font-medium p-4 text-slate-400 text-left'
                                    >
                                        <div className='flex item items-center gap-2'>
                                            <p>Status</p>
                                            <FaAngleDown />
                                        </div>
                                    </th>
                                    {/* <th onClick={()=>sortOn('projects')} className="cursor-pointer border-b-2 font-medium p-4 text-slate-400 text-left">
                        <div className='flex item items-center gap-2'>
                            <p>Projects</p>
                            <FaAngleDown />
                        </div>
                    </th> */}
                                    <th colSpan={2} className='border-b-2'>
                                        <div className='flex justify-center'>
                                            <button
                                                onClick={handleAddUser}
                                                className='flex item cursor-pointer items-center text-2xl px-4 py-2 rounded-md whitespace-nowrap justify-center w-min  gap-2 bg-black text-white'
                                            >
                                                <IoMdAddCircleOutline />
                                                <p className=' text-sm hidden md:block'>
                                                    Add User{" "}
                                                </p>
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, idx) => {
                                    return (
                                        <tr
                                            key={item.user_id}
                                            className={` transition-all user${item.user_id}`}
                                        >
                                            <td
                                                className='border-b border-slate-100  p-4 md:pl-8 text-slate-500 overflow-scroll scrollbar-none'
                                                // onClick={() => generateAndDownloadReport(item)}
                                            >
                                                {item && item.first_name}
                                            </td>
                                            <td className='border-b border-slate-100  p-4 md:pr-8 text-slate-500 overflow-scroll scrollbar-none'>
                                                {item && item.user_id}
                                            </td>
                                            <td className='border-b border-slate-100  p-4 md:pr-8 text-slate-500 overflow-scroll scrollbar-none'>
                                                {Status(
                                                    item && item.is_active,
                                                    item.last_login,
                                                )}
                                            </td>
                                            {/* <td className="border-b border-slate-100  p-4 md:pr-8 text-slate-500">{item.projects}</td> */}
                                            <td className=' invisible border-b border-slate-100  pr-2 md:pr-8 text-slate-500 underline cursor-pointer text-center overflow-scroll scrollbar-none'>
                                                User Permissions
                                            </td>
                                            <td className='border-b pr-2 border-slate-100 text-slate-500 overflow-scroll scrollbar-none'>
                                                <MoreUser user={item} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {showAdd && (
                <div
                    className='absolute h-full w-full flex items-start justify-center bg-white pt-40'
                    style={{
                        background: "#0003",
                        backdropFilter: selfData.haveAccess
                            ? "blur(1px)"
                            : "blur(10px)",
                    }}
                >
                    {selfData.haveAccess ? (
                        <AddUser prev={(e) => setShowAdd(e)} users={users} />
                    ) : (
                        <ExpiredPage />
                    )}
                </div>
            )}
        </>
    );
};

export default ManageUser;
