import { RiUserSettingsFill, RiArrowLeftCircleLine } from "react-icons/ri";
import { FaUsers, FaFileCode } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";

const AccountReport = ({
    currentProjects,
    usersAdded,
    projectsCreated,
    projectsCompleted,
    projectsFailed,
}) => {
    const user = localStorage.getItem("login-type");
    return (
        <div className=' border border-gray-300 max-w-xl mx-auto bg-white rounded-md shadow-lg'>
            <div className='bg-white-900 text-black-100 p-4 rounded-lg shadow-lg'>
                <h3 className='text-md font-semibold mb-4'>Account Report</h3>
                <div className='grid grid-cols-1 gap-4'>
                    <div
                        className='rounded-md p-4 shadow-lg flex items-center'
                        style={{ backgroundColor: "rgb(207, 250, 254)" }}
                    >
                        <RiUserSettingsFill
                            className='mr-2 opacity-75'
                            size={48}
                        />
                        <div>
                            <p className='text-sm font-semibold'>
                                Currently Processing
                            </p>
                            <div className='text-2xl font-bold text-green-400 ml-4'>
                                {currentProjects}
                            </div>
                        </div>
                    </div>
                    {user === "admin" && (
                        <div
                            className='rounded-md p-4 shadow-lg flex items-center'
                            style={{ backgroundColor: "rgb(254 205 211)" }}
                        >
                            <FaUsers
                                className='mr-2 mt-2 opacity-75'
                                size={48}
                            />
                            <div>
                                <p className='text-sm font-semibold'>
                                    Number Of Users Added
                                </p>
                                <div className='text-2xl font-bold text-blue-400 ml-4'>
                                    {usersAdded}
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className='rounded-md p-4 shadow-lg flex items-center'
                        style={{ backgroundColor: "rgb(250 232 255)" }}
                    >
                        <FaFileCode className='mr-2 opacity-75' size={48} />
                        <div>
                            <p className='text-md font-semibold'>
                                Projects Created
                            </p>
                            <div className='text-2xl font-bold text-purple-400 ml-4'>
                                {projectsCreated}
                            </div>
                        </div>
                    </div>
                    <div
                        className='rounded-md p-4 shadow-lg flex items-center'
                        style={{ backgroundColor: "rgb(236 252 203)" }}
                    >
                        <RiArrowLeftCircleLine
                            className='mr-2 opacity-75'
                            size={48}
                        />
                        <div>
                            <p className='text-md font-semibold'>
                                Completed Projects
                            </p>
                            <div className='text-2xl font-bold text-yellow-400 ml-4'>
                                {projectsCompleted}
                            </div>
                        </div>
                    </div>
                    <div
                        className='rounded-md p-4 shadow-lg flex items-center'
                        style={{ backgroundColor: "rgb(254 220 211)" }}
                    >
                        <MdErrorOutline className='mr-2 opacity-75' size={48} />
                        <div>
                            <p className='text-md font-semibold'>
                                Failed Projects
                            </p>
                            <div className='text-2xl font-bold text-red-400 ml-4'>
                                {projectsFailed}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountReport;
