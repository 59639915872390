import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useUsersignupMutation } from "../../services/auth/authApi";
import toast from "react-hot-toast";
import { FormikTextField } from "../../components/FormComponents/FormikTextField";
import { FormikDoubleTextField } from "../../components/FormComponents/FormikDoubleTextField";
import { TbIdBadge2 } from "react-icons/tb";
import { CgOrganisation } from "react-icons/cg";
import { FiLock } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../services/auth/authSlice";
import { FormikPhoneField } from "../../components/FormComponents/FormikPhoneField";

const UserSignup = () => {
    const [agree, setAgree] = useState(false);
    // const [signup, { isLoading }] = useTenantsignupMutation();
    const [signup, { isLoading }] = useUsersignupMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [countryCode, setCountryCode] = useState("IN (+91)");

    let responseData = "";
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            values = {
                ...values,
                mobile_number: countryCode + values.mobile_number,
            };
            const response = await signup(values);
            responseData = response;
            toast.success(response.data.detail);
            dispatch(setCredentials({ agreement: true }));
            navigate("/dashboard");
        } catch (error) {
            toast.error(responseData.error.data.detail);
        } finally {
            setSubmitting(false);
        }
    };

    const handlePolicy = (active) => {
        localStorage.setItem("selected-policy", active);
    };

    return (
        <Formik
            initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                organization_name: "",
                mobile_number: "",
                password: "",
            }}
            validationSchema={Yup.object({
                first_name: Yup.string()
                    .required("Required")
                    .matches(
                        /^[a-zA-Z]+$/,
                        "Only alphabetic characters are allowed",
                    ),
                last_name: Yup.string()
                    .required("Required")
                    .matches(
                        /^[a-zA-Z]+$/,
                        "Only alphabetic characters are allowed",
                    ),
                email: Yup.string()
                    .email("Invalid email address")
                    .required("Required"),
                organization_name: Yup.string()
                    .required("Required")
                    .matches(
                        /^[a-zA-Z\s]+$/,
                        "Only alphabetic characters and spaces are allowed",
                    ),
                mobile_number: Yup.string()
                    .matches(/^[0-9]+$/, "Must be only numbers")
                    .matches(/\b\d{10}\b/, "Mobile number should be 10 digits")
                    .required("Required"),
                password: Yup.string().required("Required"),
            })}
            onSubmit={handleSubmit}
        >
            <Form>
                <div className='text-center text-white text-xl font-bold mb-4'>
                    Welcome to{" "}
                    <span className='text-iris-blue-500'>BQP, USER</span>
                </div>
                <FormikDoubleTextField
                    label1='First Name'
                    label2='Last Name'
                    name1='first_name'
                    name2='last_name'
                    placeholder1='John'
                    placeholder2='Doe'
                    icon1={TbIdBadge2}
                    icon2={TbIdBadge2}
                />
                <FormikTextField
                    label='Email'
                    name='email'
                    type='email'
                    placeholder='johndoe@example.com'
                    icon={HiOutlineMail}
                />
                <div className=' flex gap-4 justify-between'>
                    <FormikTextField
                        label='Organization Name'
                        name='organization_name'
                        type='text'
                        placeholder='Company ABC'
                        icon={CgOrganisation}
                    />
                    <FormikPhoneField
                        label='Mobile Number'
                        name='mobile_number'
                        type='tel'
                        placeholder='1234567890'
                        country={(val) => setCountryCode(val)}
                    />
                </div>
                <FormikTextField
                    label='Password'
                    name='password'
                    type='password'
                    placeholder='*********'
                    icon={FiLock}
                />

                <div className='flex items-center text-sm mb-6 mt-3'>
                    <input
                        type='checkbox'
                        id='acknowledge2'
                        className='rounded-full h-4 w-4 text-iris-blue-500 mr-2'
                        checked={agree}
                        onChange={() => setAgree(!agree)}
                    />
                    <label
                        htmlFor='acknowledge2'
                        className='text-gray-200 mr-3'
                    >
                        I acknowledge the{" "}
                        <span
                            className='text-iris-blue-500 hover:text-gray-500 underline'
                            onClick={() => handlePolicy(0)}
                        >
                            {" "}
                            <Link to='/termsandpolicy'>
                                Terms and Conditions
                            </Link>{" "}
                        </span>
                        and{" "}
                        <span
                            className='text-iris-blue-500 hover:text-gray-500 underline'
                            onClick={() => handlePolicy(1)}
                        >
                            {" "}
                            <Link to='/termsandpolicy'>Privacy Policy</Link>
                            {".*"}
                        </span>
                    </label>
                </div>

                <div className='flex justify-center'>
                    <button
                        type='submit'
                        disabled={!agree}
                        className={`mt-2 text-lg transition-all shadow-2xl bg-iris-blue-500 px-5 text-white rounded h-12 w-56 font-medium
                hover:bg-iris-blue-800 hover:text-black hover:shadow-md
            } `}
                    >
                        {isLoading ? "Loading..." : "Go to Dashboard"}
                    </button>
                </div>

                {/* <Link className='flex justify-center text-medium' to='/login'>
          <p className='block text-gray-200 mt-8'>
            Already Updated the details ?{" "}
            <span className='text-iris-blue-500 hover:text-gray-500 underline'>
              Login
            </span>{" "}
          </p>
        </Link> */}
            </Form>
        </Formik>
    );
};

export { UserSignup };
